import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridToolbar
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { IpublicCenter } from "./interfaces";
import { MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const PublicCenterList: React.FC = () => {
    const { dataGridProps, setFilters, filters } = useDataGrid<IpublicCenter>({
        initialCurrent: 1,
        initialPageSize: 20,
    });

    const columns = React.useMemo<GridColDef<IpublicCenter>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", flex: 0.5,},
        { field: "name", headerName: "이름", flex: 1, 
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/publicCenters/show/${params.row.id}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        { field: "kindOf", headerName: "종류", flex: 1, 
            renderCell: (params: GridRenderCellParams) => {
                let kindText = "";
                if (params.value === "Kindergarten") {
                    kindText = "유치원";
                } else if (params.value === "ChildHouse") {
                    kindText = "어린이집";
                } else {
                    kindText = "기타";
                }
                return <span>{kindText}</span>;
        }},
        { field: "address", headerName: "주소", flex: 2 },
        { field: "tel", headerName: "전화번호", flex: 1},
        { field: "sido", headerName: "시도", flex: 1 },
        { field: "sigungu", headerName: "시군구", lex: 1 },
        { field: "estType", headerName: "유형", flex: 1 },
        { field: "status", headerName: "상태", flex: 0.5 },
        { field: "updatedDate", headerName: "수정일", flex: 1,},
    ],[]
    );

    return (
        <List breadcrumb={<></>}
            key={Date.now()}
        >
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value)) }
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-name"
                label="이름에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "name")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "name", "contains", e.target.value)) }
                }
                />

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-kindOf"
                label="종류"
                key={filters.find((filter: any) => filter.field === "kindOf")?.value ?? "kindOf"}  // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "kindOf")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "kindOf", "eq", e.target.value))}
            >
                    <MenuItem value={""}>전체</MenuItem>
                    <MenuItem value={"ChildHouse"}>어린이집</MenuItem>
                    <MenuItem value={"Kindergarten"}>유치원</MenuItem>
            </TextField>

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-address"
                label="주소에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "address")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "address", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-tel"
                label="전화번호에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "tel")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "tel", "contains", e.target.value))}
                }
                />

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-status"
                label="상태"
                key={filters.find((filter: any) => filter.field === "status")?.value ?? ""}  // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "status")?.value ?? "status"}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "status", "eq", e.target.value))}
            >
                        <MenuItem value={""}>전체</MenuItem>
                        <MenuItem value={"정상"}>정상</MenuItem>
                        <MenuItem value={"폐지"}>폐지</MenuItem>
            </TextField>

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
            />
        </List>
    );
};
