import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export const ExpoTokenShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;
console.log("UserShow record: ", record);

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    토큰값
                </Typography>
                <TextField value={record?.token ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    장치ID
                </Typography>
                <TextField value={record?.deviceId} />
                <Typography variant="body1" fontWeight="bold">
                    활성화여부
                </Typography>
                <TextField value={record?.active ? "True" : "False"} />
            </Stack>
        </Show>
    );
};