import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

export const ChildShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    이름
                </Typography>
                <TextField value={record?.name} />
                <Typography variant="body1" fontWeight="bold">
                    생일
                </Typography>
                <TextField value={record?.birthDate} />
                <Typography variant="body1" fontWeight="bold">
                    승인여부
                </Typography>
                <TextField value={record?.approval} />
                <Typography variant="body1" fontWeight="bold">
                    첨부이미지
                </Typography>
                <Link to={`/images?filters[0][field]=targetId&filters[0][operator]=eq&filters[0][value]=${record?.id}&filters[1][field]=category&filters[1][operator]=eq&filters[1][value]=CHILD`}>바로가기</Link>
                <Typography variant="body1" fontWeight="bold">
                    생성일
                </Typography>
                <TextField value={record?.createdDate} />
                <Typography variant="body1" fontWeight="bold">
                    수정일
                </Typography>
                <TextField value={record?.updatedDate} />
            </Stack>
        </Show>
    );
};