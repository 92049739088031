import "../css/Modal.css";
import styled from "styled-components";
import React, {useCallback, useEffect, useState} from "react";
import {modalReportFetchDataApi, onReportManageApi} from "../apis/reportApis";


export default function ReportModalTemplate({modalClose, reportId}) {
    /* 테스트 용 dummyData */ 
  // const dummyData = {
  //   reportType: "POST", // 신고 유형
  //   targetUserNickName: "피신고자 닉네임", // 피신고자 닉네임
  //   targetUserCount: 5, // 피신고자의 신고 횟수
  //   reportCount: 1000, // 신고 횟수
  //   postTitle: "게시글 제목", // 게시글 제목
  //   postContent: "게시글 내용", // 게시글 내용
  //   comments: [
  //     {
  //       loginId: "신고된 댓글 작성자1", // 댓글 작성자
  //       content: "댓글 내용1", // 댓글 내용
  //       answers: [
  //         {
  //           loginId: "신고된 대댓글 작성자1", // 대댓글 작성자
  //           content: "대댓글 내용1", // 대댓글 내용
  //         },
  //         {
  //           loginId: "신고된 대댓글 작성자2",
  //           content: "대댓글 내용2",
  //         },
  //       ],
  //     },
  //     {
  //       loginId: "신고된 댓글 작성자2",
  //       content: "댓글 내용2",
  //       answers: [
  //         {
  //           loginId: "신고된 대댓글 작성자3",
  //           content: "대댓글 내용3",
  //         },
  //       ],
  //     },
  //   ],
  //   reportDetails: [
  //     {
  //       nickname: "신고자1", // 신고자 닉네임
  //       reason: "욕설", // 신고 이유
  //     },
  //     {
  //       nickname: "신고자2",
  //       reason: "음란물",
  //     },
  //   ],
  // };

    const onCloseModal = (e) => {
        if (e.target === e.currentTarget) {
            modalClose()
        }

    }

    const [data, setData] = useState("");

    const error = (err) => {
        if (err.response.status === 401) {
            console.log(err);
        }
    }

    /*
    		날짜: 2023/08/18 1:56 PM
    		작성자: 임성식
    		작성내용: 해당 신고 데이터 관리
    */
    const onClick = async (e) => {
        await onReportManageApi(e, reportId, data, error)
    }

    /* 더보기 기능
        const [tagList] = useState(data);
        const [, setChoiceTagID] = useState(1);
        const [clickValue, setClickValue] = useState(false);
        const [filterTag, setFilterTag] = useState([]);
        const [closed, setClosed] = useState(false);
     */

    /*
    		날짜: 2023/08/18 1:58 PM
    		작성자: 임성식
    		작성내용: 해당 신고 데이터 가져오기
    */
    const dataFetching = useCallback(() => {
        modalReportFetchDataApi(reportId)
            .then((data) => {
                setData(data)
            }).catch((err) => {
                if (err.response.status === 401) {
                    console.log(err);
                }
            }
        );
    }, [setData, reportId]);

    useEffect(() => {
        dataFetching()
    }, [dataFetching])

    /*
    		날짜: 2023/08/18 2:06 PM
    		작성자: 임성식
    		작성내용: 신고 유형값 설정
    */
    const modalReportType = (props) => {
        switch (props.reportType) {
            case "POST":
                return "게시글";
            case "COMMENT":
                return "댓글";
            default:
                return "";
        }
    }
    const reportCommentColor = (prop1, prop2) => {
        if (prop1.reportTargetId === prop2.commentId) {
            return "black";
        } else {
            return "";
        }
    }


    const Title = ["신고대상", "피신고자", "피신고자 총신고횟수", "글 총신고횟수"];

    return (
        <div className="modal__container" onClick={onCloseModal}>
            <div className="modal">
                    <div style={{ width: '50%', height: '95%', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray'}}>
                        <HeaderTxt style={{backgroundColor: "#1976D2", color: "#ffffff"}}>
                            {Title.map((title, index) => (
                                <Span key={index}>
                                    {title}
                                </Span>
                            ))}
                        </HeaderTxt>
                        <PostTitleContainer>
                            <PostTitle>{modalReportType(data)}</PostTitle>
                            <PostTitle>{data.targetUserNickName}</PostTitle>
                            <PostTitle>{data.targetUserCount}</PostTitle>
                            <PostTitle>{data.reportCount}</PostTitle>
                        </PostTitleContainer>

                    </div>

                    <div style={{width: '45%', height: '95%', borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray', borderBottom: '1px solid lightgray'}}>
                        <HeaderTxt style={{backgroundColor: "#e9e9e9", color: "#1976D2"}}>
                            해당신고내역
                        </HeaderTxt>
                        <ReportContainer>
                          <PostContainer>
                          <PostHeader>게시글 제목</PostHeader>             
                              <PostContents>
                                  {data.postTitle}
                              </PostContents>
                              <PostHeader>게시글 내용</PostHeader>
                              <PostContents>
                                  {data.postContent}
                              </PostContents>
                          </PostContainer>
                             <PostHeader>댓글 목록</PostHeader>
                            {
                                data && data.comments.map((item, i) => {
                                    return (
                                            <div style={{width: '100%', display:'flex', flexDirection:'column', marginTop: '10px'}} key={i}>

                                              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                                                <ReportNickname>{item.loginId}</ReportNickname>
                                                <p className="text" style={{position:'relative',color: reportCommentColor(data, item)}}>
                                                    {item.content}
                                                </p>
                                              </div>

                                              <div style={{width: 'inherit'}}>
                                              {
                                                data && Object.values(data.comments[i].answers).map((item, i) => {
                                                  return (
                                                    <div style={{ display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                                                          <ReportNickname>{item.loginId}</ReportNickname>
                                                          <p className="text2" style={{position:'relative',color: reportCommentColor(data, item)}}>
                                                              {item.content}
                                                          </p>
                                                    
                                                      </div>
                                                  )
                                              })}
                                              </div>
                                              {/* <ReportLine/>  */}

                                            </div>)
                                })
                            }
                        </ReportContainer>
                    </div>

                    <div style={{width:'15%', height:'95%', borderRight: '1px solid lightgray', borderBottom: '1px solid lightgray'}}>
                      <HeaderTxt style={{backgroundColor: "#1976D2", color: "#ffffff"}} borderRadius="0px">
                            신고자 목록
                      </HeaderTxt>
                      <ReporterContainer>
                          {
                            data && data.reportDetails.map((item, i) => {
                                return (
                                    <ReporterList key={i}>
                                        {item.nickname}<br/>
                                        {item.reason} <br/>
                                    </ReporterList>
                                )
                            })
                          }
                        </ReporterContainer>
                    </div>
                <ButtonContainer id="DEL" >
                    <StyledButton id="DEL" onClick={onClick}>삭제</StyledButton>
                    <StyledButton id="KEEP" onClick={onClick}>보류</StyledButton>
                </ButtonContainer>
            </div>

        </div>


    );

}


const HeaderTxt = styled.div`
  width: 100%;
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 수평 가운데 정렬 */
  position: sticky;
  top: 0;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: black;
  padding: 10px;
  font-size: 14px;
  border-bottom: 3px solid lightgray;
  z-index: 1;
`;

const Span = styled.span`
   width: 25%;
   height: 20px;
   line-height: 20px;
`;

const PostTitleContainer = styled.div`
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 35px;
  background-color: #f1f1f1;
  border-bottom: 1px solid lightgray;
`;

const PostTitle = styled.div`
  width: 25%;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #333333;
  font-size: 13px;
`;

const PostContainer = styled.div`
  flex-direction: column;
  width: 100%;
  height: 89%;
  color: #555555;
  font-size: 12px;
`;

const PostHeader = styled.div`
  width: 100%;
  text-align: center;
  flex-direction: column;
  font-size: 16px;
  border-bottom: 1px solid lightgray;
`;

const PostContents = styled.div`
  width: 100%;
  line-height: 20px;
  padding-bottom: 10px;
  text-align: left;
  font-size: 14px;
  word-break: break-all;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: #f4f4f4;
    border-radius: 10px;
    border: 1px solid darkgrey;
    width: 0.2vw;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
    border: 1px solid #f4f4f4;
  }
`;

const ReportContainer = styled.div`
  width: 100%;
  font-size: 12px;
  padding: 20px 30px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: #f4f4f4;
    border-radius: 10px;
    border: 1px solid darkgrey;
    width: 0.2vw;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
    border: 1px solid #f4f4f4;
  }
`;

const ReportNickname = styled.div`
  height: 20px;
  line-height: 20px;
  color: #595959;
  text-align: left;
  font-weight: bold;

`;

const ReportLine = styled.div`
  width: 60%;
  margin: 20px auto;
  border-bottom: 1px solid lightgray;
`;

const ReporterContainer = styled.div`
  width: 100%; 
  height: 92.5%;
  overflow-y: auto; 

  &::-webkit-scrollbar {
    background-color: #f4f4f4;
    border-radius: 10px;
    border: 1px solid darkgrey;
    width: 0.2vw;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
    border: 1px solid #f4f4f4;
  }
`; 

const ReporterList = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 128px;
  padding: 15px;
  color: #555555;
  text-align: center;
  font-size: 12px;
  border-bottom: 1px solid #c9c9c9;
  white-space: pre-line;

`;

const ButtonContainer = styled.div`
  position: absolute;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  bottom: 10px;
  right: 10px;
`;

const StyledButton = styled.button`
    padding: 5% 10%;
    border: none; /* 테두리 없음 */
    border-radius: 5px; /* 둥근 테두리 */
    font-size: 13px; /* 폰트 크기 */
    cursor: pointer; /* 커서를 포인터로 변경하여 클릭 가능한 상태 표시 */
    background-color: ${({ id }) => id === 'DEL' ? 'rgba(219,88,78,0.85)' : '#1976D2'}; /* id 값에 따라 배경색이 달라지도록 설정 */
    color: white; /* 글자색 흰색 */
    transition: background-color 0.3s ease;
    &:hover {
        background-color: ${({ id }) => id === 'DEL' ? 'lightcoral' : '#64B5F6'}; /* 호버 시 배경색 변경 */
    }
`;

