import React, { useState, useEffect } from 'react';
import styled from "styled-components";

export const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        setIsVisible(scrollTop > 100); // 스크롤이 100px 이상 되면 버튼을 보이게 함
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // 부드러운 스크롤 효과를 위해 사용
        });
    };

    useEffect(() => {
        // 스크롤 이벤트 리스너 등록
        window.addEventListener('scroll', handleScroll);
        return () => {
            // 컴포넌트 언마운트 시 이벤트 리스너 제거
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {isVisible && (
                <Button onClick={scrollToTop}>
                    Top
                </Button>
            )}
        </div>
    );
};

const Button = styled.button`
  position: fixed;
  bottom: 50px;
  right: 30px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #d39793;
  color: #d39793;
  background-color: #fff;
  cursor: pointer;
  text-align: center;

  /* 기본 상태일 때 큰 크기 */
  width: 45px;
  height: 45px;
  font-size: 14px;

  &:hover {
    background-color: #d39793;
    color: white;
    border: none;
    transition: background-color 0.5s ease, color 0.5s ease;
  }

  @media screen and (max-width: 1500px) {
    /* 미디어 쿼리를 사용하여 max width 1500px일 때 작은 크기 */
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
`;
