import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export const AgentShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;
console.log("UserShow record: ", record);

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    이름
                </Typography>
                <TextField value={record?.name} />
                <Typography variant="body1" fontWeight="bold">
                    코드
                </Typography>
                <TextField value={record?.code} />
                <Typography variant="body1" fontWeight="bold">
                    로그인ID
                </Typography>
                <TextField value={record?.loginId} />
                <Typography variant="body1" fontWeight="bold">
                    전화번호
                </Typography>
                <TextField value={record?.phoneNumber} />
                <Typography variant="body1" fontWeight="bold">
                    주소
                </Typography>
                <TextField value={record?.a_address} />
                <Typography variant="body1" fontWeight="bold">
                    상태
                </Typography>
                <TextField value={record?.a_status} />
                <Typography variant="body1" fontWeight="bold">
                    자차여부
                </Typography>
                <TextField value={record?.a_hasCar} />
                <Typography variant="body1" fontWeight="bold">
                    장비번호
                </Typography>
                <TextField value={record?.a_equipment} />
                <Typography variant="body1" fontWeight="bold">
                    장비발송일
                </Typography>
                <TextField value={record?.a_dispatchDate} />
                <Typography variant="body1" fontWeight="bold">
                    장비수령일
                </Typography>
                <TextField value={record?.a_receiveDate} />
            </Stack>
        </Show>
    );
};