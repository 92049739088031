import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridToolbar
} from "@mui/x-data-grid";

import { Link, useNavigate } from "react-router-dom";

import { IUser } from "./interfaces";
import { Button, MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const UserList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<IUser>({
        initialCurrent: 1,
        initialPageSize: 20,
    });

    const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
    const navigate = useNavigate();

    const columns = React.useMemo<GridColDef<IUser>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 110 },
        { field: "loginId", headerName: "로그인 ID", type: "string", width: 150 },
        { field: "nickName", headerName: "닉네임", minWidth: 200, flex: 1, 
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/parents/show/${params.row.id}`} 
                  style={{ textDecoration: 'none', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        {
            field: "phoneNumber",
            headerName: "전화번호",
            minWidth: 50,
            flex: 1,
            renderCell: (params) => {
              const formattedPhoneNumber = params.value && params.value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
              return <span>{formattedPhoneNumber}</span>;
            },
          },
        { field: "updatedDate", headerName: "수정일", minWidth: 110, flex: 1, 
            renderCell: (params: GridRenderCellParams) => {
                const date = new Date(params.value);
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();

                const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
                const amPm = hours < 12 ? '오전' : '오후';
                const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
                const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

                const formattedDate = `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일 ${amPm} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
                return <span>{formattedDate}</span>;
        }},
        { 
            field: "actions", 
            headerName: "Actions", 
            width: 100, 
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div>
                        <EditButton hideText recordItemId={params.row.id} />
                    </div>
                )
            },
        }
    ],[]
    );

    return (
        <List 
            breadcrumb={<></>}
            headerButtons={({ defaultButtons }) => (
                <>
                {defaultButtons}
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => {
                        navigate("/alarms/create", { state: { ids: selectedRows }});
                    }}
                >
                    푸시알림 전송
                </Button>
                </>
            )}
            key={Date.now()}
        >
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-loginId"
                label="로그인아이디에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "loginId")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                        setFilters(updateFilters(filters, "loginId", "contains", e.target.value));
                    }
                }}
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-nickName"
                label="닉네임에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "nickName")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                        setFilters(updateFilters(filters, "nickName", "contains", e.target.value));
                    }
                }}
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-phoneNumber"
                label="전화번호에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "phoneNumber")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                        setFilters(updateFilters(filters, "phoneNumber", "contains", e.target.value)); 
                    }
                }}
                />

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
                checkboxSelection={true}
                onRowSelectionModelChange={(e: any) => setSelectedRows(e)}
            />
        </List>
    );
};

