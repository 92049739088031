import React from "react";
import styled from "styled-components";

const reportTypeTitle = (props) => {

    switch(props.contents.reason){
        case "REPORT_A":
            return "욕설";
        case "REPORT_B":
            return "음란물";
        case "REPORT_C":
            return "상업적";
        case "REPORT_D":
            return "사기";
        case "REPORT_E":
            return "개인정보";
        case "REPORT_F":
            return "불쾌";
        default:
            return "기타";
    }
}

const Lists = (props) =>{
    return (
        props.contents.reportType === "POST" ?
            <div>
            <ListElement
                key={props.keyValue}
                style={{
                    height: props.height,
                    margin: props.margin
                }}
                name={props.keyValue} onClick={(e)=>{props.onClickFunction(e,props.contents.reportId, props.contents.reportType)}
            }
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Ctn style={{ position: 'relative' }}>{props.contents.reportId}</Ctn>
                    <Ctn style={{ position: 'relative' }}>{props.contents.reportType}</Ctn>
                    <Ctn style={{ position: 'relative' }}>{reportTypeTitle(props)}</Ctn>
                    <Ctn style={{ position: 'relative' }}>{props.contents.targetUserLoginId}</Ctn>
                    <Ctn style={{ position: 'relative' }}>{props.contents.postTitle}</Ctn>
                    <Ctn style={{ position: 'relative' }}>{props.contents.reportCount}</Ctn>
                    <Ctn style={{ position: 'relative' }}>{props.contents.firstDate}</Ctn>
                </div>
            </ListElement>
            </div>
            :
            <ListElement
                key={props.keyValue}
                style={{
                    height: props.height,
                    // position: "relative",
                    margin: props.margin
                }}
                name={props.keyValue} onClick={(e)=>{props.onClickFunction(e,props.contents.reportId, props.contents.reportType)}}>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Ctn style={{ position: 'relative' }}>{reportTypeTitle(props)}</Ctn>
                    <Ctn style={{ position: 'relative' }}>{props.contents.targetUserLoginId}</Ctn>
                    <Ctn style={{ position: 'relative' }}>{props.contents.commentPostTitle}</Ctn>
                    <Ctn style={{ position: 'relative' }}>{props.contents.reportId}</Ctn>
                    <Ctn style={{ position: 'relative' }}>{props.contents.reportCount}</Ctn>
                    {/*<Ctn style={{left:"1470px"}}>{props.contents.firstDate}</Ctn>*/}
                </div>
                {/*<div>*/}
                {/*<Ctn style>{reportTypeTitle(props)}</Ctn>*/}
                {/*<Ctn style>{props.contents.targetUserLoginId}</Ctn>*/}
                {/*<Ctn style>{props.contents.commentPostTitle}</Ctn>*/}
                {/*<Ctn style>{props.contents.reportId}</Ctn>*/}
                {/*<Ctn style>{props.contents.reportCount}</Ctn>*/}
                {/*/!*<Ctn style={{left:"1470px"}}>{props.contents.firstDate}</Ctn>*!/*/}
                {/*</div>*/}
            </ListElement>
    )

}

//신고 목록 내의 실제 신고들을 감싸는 회색 박스
export const ListElement = styled.div`
  display:grid;
  align-items: center;
  

  padding-top: 0;
  padding-left: 0;
  text-align:center;
   
  border-radius: 10px;
  height:50px;
  line-height: 50px;
  background-color: #e9e9e9;
 
  
  &:hover {
    background-color: #c9c9c9;
    border-radius:11px 11px 11px 11px;
  }
   
   @media screen and (max-width: 2000px) {
        //width:auto;
        //padding-top: 3vh;
        //font-size:12px;
        flex-direction: column;
    }
`

export const Ctn = styled.div` 
    width: 18%;
    height: 19px;
    line-height:19px;
    text-align: center;
    writing-mode: horizontal-tb;
    
    @media screen and (max-width: 2000px) {  
      flex-direction: column;
    }
`;

export default Lists;
