import {SimpleSouthKoreaMapChart} from "react-simple-south-korea-map-chart";
import {useEffect, useState} from "react";
import { axiosInstance } from "functions";
import * as React from "react";
import {Title} from "./AdminDashboard";

function RegionStatistics() {
    const [join, setJoin] = useState([]);
    //지역 선택을 위한 RecoilState


    useEffect(() => {
        // Define an async function to fetch the data
        async function Region() {
            const accessToken = sessionStorage.getItem("accessToken");
            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_IP_ADDRESS}/statistics/region`);

                // Assuming your API response contains data in an array format
                // console.log(response.data)
                setJoin(response.data)

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        Region();

    },[]);
    const setColorByCount = (count) => {
        if (count === 0) return "#F1F1F1";
        if (count > 5000) return "#FF7E81";
        if (count > 3000) return "#FF9093";
        if (count > 1000) return "#FFA2A3";
        if (count > 200) return "#FFB4B5";
        if (count > 100) return "#FFC6C6";
        if (count > 50) return "#FFD8D8";
        if (count > 5) return "#FFEAE9";
        else return "#FFF4F4";
    };

    const mappedData = join.map((item) => ({
        locale: item.sido,
        count: item.regionUserCnt,
    }));




    return (
        <div style={{width: "100%"}}>
            <Title style={{color: "#d39797"}}>
                지역별 통계
            </Title>
            <SimpleSouthKoreaMapChart
                setColorByCount={setColorByCount}
                unit="명"
                data={mappedData}
            />
            <div
                style={{
                    width: "100%",
                    textAlign: "center",
                    lineHeight: "0.1em",
                    margin: "40px 0 40px",
                }}
            >
            </div>

        </div>
    );
}

export default RegionStatistics;