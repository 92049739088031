import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridToolbar
} from "@mui/x-data-grid";

import { Link, useNavigate } from "react-router-dom";

import { IBlockUser } from "./interfaces";
import { Button, MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const BlockUserList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<IBlockUser>({
        initialCurrent: 1,
        initialPageSize: 20,
    });

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const navigate = useNavigate();

    const columns = React.useMemo<GridColDef<IBlockUser>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 80},
        { field: "blockingUserId", headerName: "차단한 사용자 ID", type: "string", width: 100, flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                return <Link to={`/users/${params.value}`}>{params.value}</Link>;
            }
         },
        { field: "blockedUserId", headerName: "차단된 사용자 ID", width: 100, flex: 1, 
            renderCell: (params: GridRenderCellParams) => {
                return <Link to={`/users/${params.value}`}>{params.value}</Link>;
            }
        },
        { field: "updatedDate", headerName: "수정일", minWidth: 110, flex: 1, 
            renderCell: (params: GridRenderCellParams) => {
                const date = new Date(params.value);
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();

                const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
                const amPm = hours < 12 ? '오전' : '오후';
                const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
                const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

                const formattedDate = `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일 ${amPm} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
                return <span>{formattedDate}</span>;
        }}
    ],[]
    );

    return (
        <List 
            breadcrumb={<></>}
            key={Date.now()}
        >
            <TextField sx={{ width: '100px', marginRight: '30px', marginBottom: '20px'}}
                id="center-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value))}
                }
                />

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
                checkboxSelection={true}
                onRowSelectionModelChange={(e: any) => { setRowSelectionModel(e) }}
                rowSelectionModel={rowSelectionModel}
            />
        </List>
    );
};
