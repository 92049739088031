import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";
import { useDeleteMany } from "@refinedev/core";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridToolbar
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { ISchedule } from "./interfaces";
import { MenuItem, TextField, Button } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const ScheduleList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<ISchedule>({
        initialCurrent: 1,
        initialPageSize: 20,
        sorters: {
            initial: [{ field: "id", order: "desc" }]
        }
    });

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const { mutate, isLoading: deleteManyIsLoading } = useDeleteMany<ISchedule>();

    const deleteSelectedItems = () => {
        mutate(
            {
                resource: "schedules",
                ids: rowSelectionModel.map((id) => id.toString())
            },
            {
                onSuccess: () => {
                    setRowSelectionModel([]);
                }
            }   
        )
    }

    const columns = React.useMemo<GridColDef<ISchedule>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", flex: 0.5,
            renderCell: (params: GridRenderCellParams) =>
            <Link to={`/schedules/show/${params.value}`}
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        { field: "agentName", headerName: "현장요원이름", flex: 1,
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/agents/show/${params.row.agentId}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        { field: "agentCode", headerName: "현장요원코드", flex: 1.5, },
        { field: "centerName", headerName: "시설이름", type: "string", flex:2,
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/centers/show/${params.row.centerId}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        { field: "complete", headerName: "상태", type: "string", flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                let value = '???';
                if (params.value === "incomplete")  value = '배정';
                if (params.value === "confirm") value = '시설수락';
                if (params.value === "cancel") value = '취소';
                if (params.value === "complete") value = '완료';
                return (
                    <span>
                        {value}
                    </span>
                );
            }
        },
        { field: "accept", headerName: "현장요원수락", type: "string", flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                let value = '???';
                if (params.value === "WAITING")  value = '미확인';
                if (params.value === "REJECT") value = '거절';
                if (params.value === "ACCEPT") value = '수락';
                return (
                    <span>
                        {value}
                    </span>
                );
            }
        },
        { field: "visitDate", headerName: "예정방문날짜", type: "string", flex: 1},
        { field: "visitTime", headerName: "예정방문시간", type: "string", flex: 1,},
        { field: "visitDateFinal", headerName: "실제방문날짜", type: "string", flex: 1},
        { field: "visitTimeFinal", headerName: "실제방문시간", type: "string", flex: 1,},
        { field: "newChildrenFinal", headerName: "신규아동최종", type: "string", flex: 1,},
        { field: "modChildrenFinal", headerName: "변경아동최종", type: "string", flex: 1,},
        { field: "dementiaFinal", headerName: "치매최종", type: "string", flex: 1,},
        { field: "idisabledFinal", headerName: "지적장애최종", type: "string", flex: 1,},
        { field: "absenceFinal", headerName: "결석", type: "string", flex: 1,},
        { field: "documentSent", headerName: "서류발송여부", type: "string", flex: 1},
        { field: "memo", headerName: "관리자메모", type: "string", flex: 2,
            renderCell: (params: GridRenderCellParams) =>
            <div style={{ whiteSpace: 'pre-wrap', maxHeight: '40px', overflowY: 'auto' }}>
                {params.value}
            </div>
        },
    ],[]
    );

    const hasSelected = rowSelectionModel.length > 0;

    return (
        <List 
            breadcrumb={<></>}
            headerButtons={({ defaultButtons }) => (
                <>
                {defaultButtons}
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={deleteSelectedItems}
                    disabled={!hasSelected}
                >
                    선택 삭제하기
                </Button>
                </>
            )}
        >
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="schedule-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="schedule-search-agentName"
                label="현장요원이름에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "agentName")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "agentName", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="schedule-search-agentCode"
                label="현장요원코드에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "agentCode")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "agentCode", "contains", e.target.value))}
                }
                />

            <TextField sx={{ width: '150px', marginRight: '30px', marginBottom: '20px'}}
                id="schedule-select-complete"
                label="상태에서 검색"
                key={filters.find((filter: any) => filter.field === "complete")?.value ?? "complete"}  // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "complete")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "complete", "eq", e.target.value))}
            >
                    <MenuItem value={""}>전체</MenuItem>
                    <MenuItem value={"incomplete"}>배정</MenuItem>
                    <MenuItem value={"confirm"}>시설수락</MenuItem>
                    <MenuItem value={"cancel"}>취소</MenuItem>
                    <MenuItem value={"complete"}>완료</MenuItem>
            </TextField>


            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                type="date"
                id="schedule-search-visitDate"
                label="예정방문날짜에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "visitDate")?.value ?? ""}
                variant="outlined"
                size="small"
                onChange={(e: any) => {
                    console.log("e", e.target.value);
                    setFilters(updateFilters(filters, "visitDate", "eq", e.target.value));
                }}
                InputLabelProps={{ shrink: true }}
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                type="date"
                id="schedule-search-visitDateFinal"
                label="실제방문날짜에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "visitDateFinal")?.value ?? ""}
                variant="outlined"
                size="small"
                onChange={(e: any) => {
                    console.log("e", e.target.value);
                    setFilters(updateFilters(filters, "visitDateFinal", "eq", e.target.value));
                }}
                InputLabelProps={{ shrink: true }}
                />

            <DataGrid
                columns={columns}
                {...dataGridProps}
                //autoHeight
                rowHeight={53}
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
                checkboxSelection={true}
                onRowSelectionModelChange={(e: any) => { setRowSelectionModel(e) }}
                rowSelectionModel={rowSelectionModel}
            />
        </List>
    );
};
