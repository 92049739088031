import { IResourceComponentsProps } from "@refinedev/core";
import { MuiListInferencer } from "@refinedev/inferencer/mui";
import AdminDashboard from "./AdminDashboard";
import RegionStatistics from "./RegionStatistics";

export const Statistics: React.FC<IResourceComponentsProps> = () => {
  return (
    <>
      <AdminDashboard />
      <RegionStatistics />
    </>
  );
};
