import { AuthPage } from "../auth";

export const ForgotPassword = () => {
  return <AuthPage 
    type="forgotPassword" 
    formProps={{
      defaultValues: { loginId: "", phoneNumber: "" },
    }}
/>;
};
