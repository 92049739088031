import { axiosInstance } from "functions";

/*
		날짜: 2023/08/18 11:32 AM
		작성자: 임성식
		http method: GET
		URI: /statistics
		Content: 회원 수 데이터 api
*/
export const statisticsApi = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_IP_ADDRESS}/statistics`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        }
        throw error;
    }
};

export async function fetchDataForDates(dateArray, regionState, setData) {
    const accessToken = sessionStorage.getItem("accessToken");
    try {
        const dataArray = await Promise.all(
            dateArray && dateArray.map(async (date) => {
                try {
                    const response = await axiosInstance.get(
                        `${process.env.REACT_APP_IP_ADDRESS}/statistics/${date}/${regionState}`);
                    return response.data[0]; // Return the data fetched for the specific date
                } catch (error) {
                    console.error(`Error fetching data for ${date}:`, error);
                    return null;
                }
            })
        );

        // Combine data for each date
        const combinedData = dataArray.reduce((acc, currentDateData) => {
            if (currentDateData) {
                acc.push({
                    date: currentDateData.date,
                    teacherUserCnt: currentDateData.teacherUserCnt,
                    parentUserCnt: currentDateData.parentUserCnt,
                    totalUserCnt:
                        currentDateData.teacherUserCnt + currentDateData.parentUserCnt, // 총회원수 계산
                    joinParentCnt: currentDateData.joinParentCnt,
                    joinTeacherCnt: currentDateData.joinTeacherCnt,
                    quitParentCnt: currentDateData.quitParentCnt,
                    quitTeacherCnt: currentDateData.quitTeacherCnt,
                    quitParentUserCnt: currentDateData.quitParentUserCnt,
                    quitTeacherUserCnt: currentDateData.quitTeacherUserCnt,
                    bannedParentCnt: currentDateData.bannedParentCnt,
                    bannedTeacherCnt: currentDateData.bannedTeacherCnt,
                    bannedParentUserCnt: currentDateData.bannedParentUserCnt,
                    bannedTeacherUserCnt: currentDateData.bannedTeacherUserCnt,
                    restrictedParentCnt: currentDateData.restrictedParentCnt,
                    restrictedTeacherCnt: currentDateData.restrictedTeacherCnt,
                    restrictedParentUserCnt: currentDateData.restrictedParentUserCnt,
                    restrictedTeacherUserCnt: currentDateData.restrictedTeacherUserCnt,
                    validTeacherUserCnt: currentDateData.validTeacherUserCnt,
                    validParentUserCnt: currentDateData.validParentUserCnt,
                });
            }
            return acc;
        }, []);

        combinedData.reverse(); // 데이터를 역순으로 정렬 (최신 데이터가 마지막에 오도록)
        setData(combinedData);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}