// CustomTooltip.js
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TooltipContainer = styled.div`
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
`;

function CustomTooltip({ active, payload, label }) {
    if (active && payload && payload.length) {
        // payload[0]에 현재 마우스 위치에 대한 데이터가 포함됩니다.
        const data = payload[0].payload;
        const total = data.teacherUserCnt + data.parentUserCnt
        const joinTotal = data.joinParentCnt + data.joinTeacherCnt;
        const quitTotal = data.quitParentCnt + data.quitTeacherCnt;
        const restrictedTotal = data.restrictedParentCnt + data.restrictedTeacherCnt;
        const bannedTotal = data.bannedParentCnt + data.bannedTeacherCnt;
        const validTotal = data.validParentUserCnt+ data.validTeacherUserCnt;
        const quitUserTotal = data.quitParentUserCnt + data.quitTeacherUserCnt;
        const restrictedUserTotal = data.restrictedParentUserCnt + data.restrictedTeacherUserCnt;
        const bannedUserTotal = data.bannedParentUserCnt + data.bannedTeacherUserCnt;
        return (
            <TooltipContainer>
                <p>{`날짜: ${label}`}</p>
                <p>{`총 회원수: (전체: ${total}, 학부모: ${data.parentUserCnt}, 선생님:${data.teacherUserCnt})`}</p>
                <p>{`금일 가입자수: (전체: ${joinTotal}, 학부모: ${data.joinParentCnt}, 선생님:${data.joinTeacherCnt})`}</p>
                <p>{`금일 탈퇴자수: (전체: ${quitTotal}, 학부모: ${data.quitParentCnt}, 선생님:${data.quitTeacherCnt})`}</p>
                <p>{`금일 일시정지: (전체: ${restrictedTotal}, 학부모: ${data.restrictedParentCnt}, 선생님:${data.restrictedTeacherCnt})`}</p>
                <p>{`금일 영구정지: (전체: ${bannedTotal}, 학부모: ${data.bannedParentCnt}, 선생님:${data.bannedTeacherCnt})`}</p>
                <p>{`누적 정상회원: (전체: ${validTotal}, 학부모: ${data.validParentUserCnt}, 선생님:${data.validTeacherUserCnt})`}</p>
                <p>{`누적 탈퇴회원: (전체: ${quitUserTotal}, 학부모: ${data.quitParentUserCnt}, 선생님:${data.quitTeacherUserCnt})`}</p>
                <p>{`누적 일시정지회원: (전체: ${restrictedUserTotal}, 학부모: ${data.restrictedParentUserCnt}, 선생님:${data.restrictedTeacherUserCnt})`}</p>
                <p>{`누적 영구정지회원: (전체: ${bannedUserTotal }, 학부모: ${data.bannedParentUserCnt}, 선생님:${data.bannedTeacherUserCnt})`}</p>

                {/* 다른 정보들을 필요한 만큼 표시합니다. */}
            </TooltipContainer>
        );
    }

    return null;
}

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.string,
};

export default CustomTooltip;
