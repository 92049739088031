import { HttpError, useShow, useParsed } from "@refinedev/core";
import { Show, Edit, useAutocomplete } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";

import { ISchedule, Nullable } from "./interfaces";
import React from "react";
import { Box, TextField, Button, Typography, LinearProgress, withStyles, CircularProgress } from "@mui/material";

import { schedulePatchApi } from "./apis/schedulesApi";
import { removeFileFromS3, uploadBase64ToS3 } from "functions/S3";
import { set } from "react-hook-form";

export const ScheduleFiles: React.FC = () => {
    const [base64Url, setBase64Url] = React.useState<string | null>(null);
    const [isUploading, setIsUploading] = React.useState(false);

    const imageBoxStyle = {
        display: "flex",
        marginBottom: "20px",
        alignItems: "flex-start",
    };

    const imageStyle = {
        border: "1px solid #606060",
        width: "400px"
    };

    const imageDeleteButtonStyle = {
        marginLeft: "10px",
    };

    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
    } = useForm<ISchedule, HttpError, Nullable<ISchedule>>();

    const { id } = useParsed();
    const { queryResult: showQueryResult } = useShow({
        resource: "schedules",
        id: id,
    });

    const { data, isLoading } = showQueryResult;
    const record = data?.data;
    const amazonS3url = "https://" + process.env.REACT_APP_AWS_S3_BUCKET_NAME + ".s3." + process.env.REACT_APP_AWS_REGION + ".amazonaws.com/";

    const handleFileDelete = async (attached: string, path: string) => {

        if (window.confirm("정말로 삭제하시겠습니까?") === false) return;

        var variables: any = {};
        variables[attached] = null;

        try {
            await removeFileFromS3(path);
            await schedulePatchApi(id, variables);
            window.location.reload();
        } catch (e) {
            console.error(e);
        }

    }

    const handleFileUpload = async () => {
        if (!base64Url) {
            alert("파일을 선택해주세요.");
            return;
        }

        var attachNumber = 0;

        if (record?.attached1 === null || record?.attached1 === "") {
            attachNumber = 1;
        } else if (record?.attached2 === null || record?.attached2 === "") {
            attachNumber = 2;
        } else if (record?.attached3 === null || record?.attached3 === "") {
            attachNumber = 3;
        } else if (record?.attached4 === null || record?.attached4 === "") {
            attachNumber = 4;
        } else if (record?.attached5 === null || record?.attached5 === "") {
            attachNumber = 5;
        } else if (record?.attached6 === null || record?.attached6 === "") {
            attachNumber = 6;
        } else if (record?.attached7 === null || record?.attached7 === "") {
            attachNumber = 7;
        } else {
            alert("더 이상 파일을 추가할 수 없습니다.");
            return;
        }

        try {
            setIsUploading(true);
            const res = await uploadBase64ToS3(base64Url, `schedules/${id}/`);

            var variables: any = {};
            variables["attached" + attachNumber] = res; // keyName

            await schedulePatchApi(id, variables, {
                headers: {
                }
            });
            setBase64Url(null);
            setIsUploading(false);
            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }

    const convertBlobToBase64 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (event) => {
            setBase64Url(event.target?.result as string);
        };
        reader.readAsDataURL(file);
    }

    return (
        <Show
            isLoading={isLoading}
            title={<Typography variant="h5">첨부파일 수정: {record?.id}, {record?.agent.name}, {record?.center.name}, {record?.visitDateFinal+" "+record?.visitTimeFinal} </Typography>}
            goBack={<></>}
        >

            {isUploading && (
                <Box>
                    <CircularProgress /> <Box>파일을 업로드하는 중입니다.</Box>
                </Box>
            )}
            {!isUploading && (

                <Box sx={{ marginBottom: "30px", paddingBottom: "30px", borderBottom: "1px solid #606060" }}>
                    <form>
                        <input
                            type="file"
                            id="newFile"
                            name="newFile"
                            accept="image/*"
                            onChange={e => { convertBlobToBase64(e); }}
                        />
                        <Button variant="contained" color="primary" onClick={handleFileUpload}>파일 업로드하기</Button>
                    </form>
                </Box>
            )}
            {record?.attached1 && (
                <div style={imageBoxStyle}>
                    <img
                        src={amazonS3url + record?.attached1}
                        style={imageStyle}
                        alt=""
                    />

                    <Button
                        sx={imageDeleteButtonStyle}
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleFileDelete("attached1", record?.attached1);
                        }}
                    >삭제</Button>
                </div>
            )}
            {record?.attached2 && (
                <div style={imageBoxStyle}>
                    <img
                        src={amazonS3url + record?.attached2}
                        style={imageStyle}
                        alt=""
                    />

                    <Button
                        sx={imageDeleteButtonStyle}
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleFileDelete("attached2", record?.attached2);
                        }}
                    >삭제</Button>
                </div>
            )}
            {record?.attached3 && (
                <div style={imageBoxStyle}>
                    <img
                        src={amazonS3url + record?.attached3}
                        style={imageStyle}
                        alt=""
                    />

                    <Button
                        sx={imageDeleteButtonStyle}
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleFileDelete("attached3", record?.attached3);
                        }}
                    >삭제</Button>
                </div>
            )}
            {record?.attached4 && (
                <div style={imageBoxStyle}>
                    <img
                        src={amazonS3url + record?.attached4}
                        style={imageStyle}
                        alt=""
                    />

                    <Button
                        sx={imageDeleteButtonStyle}
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleFileDelete("attached4", record?.attached4);
                        }}
                    >삭제</Button>
                </div>
            )}
            {record?.attached5 && (
                <div style={imageBoxStyle}>
                    <img
                        src={amazonS3url + record?.attached5}
                        style={imageStyle}
                        alt=""
                    />

                    <Button
                        sx={imageDeleteButtonStyle}
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleFileDelete("attached5", record?.attached5);
                        }}
                    >삭제</Button>
                </div>
            )}
            {record?.attached6 && (
                <div style={imageBoxStyle}>
                    <img
                        src={amazonS3url + record?.attached6}
                        style={imageStyle}
                        alt=""
                    />

                    <Button
                        sx={imageDeleteButtonStyle}
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleFileDelete("attached6", record?.attached6);
                        }}
                    >삭제</Button>
                </div>
            )}
            {record?.attached7 && (
                <div style={imageBoxStyle}>
                    <img
                        src={amazonS3url + record?.attached7}
                        style={imageStyle}
                        alt=""
                    />

                    <Button
                        sx={imageDeleteButtonStyle}
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleFileDelete("attached7", record?.attached7);
                        }}
                    >삭제</Button>
                </div>
            )}
        </Show>
    );
};