import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

export const CallShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    시설ID
                </Typography>
                <Link to={`/centers/show/${record?.centerId}`}>{record?.centerId}</Link>
                <Typography variant="body1" fontWeight="bold">
                    IN/OUT
                </Typography>
                <TextField value={record?.in_out} />
                <Typography variant="body1" fontWeight="bold">
                    시설 관계자
                </Typography>
                <TextField value={record?.c_manager+" "+record?.m_email+" "+record?.m_ph } />
                <Typography variant="body1" fontWeight="bold">
                    연락일
                </Typography>
                <TextField value={record?.date+" "+record?.time} />
                <Typography variant="body1" fontWeight="bold">
                    참여여부
                </Typography>
                <TextField value={record?.participation} />
                <Typography variant="body1" fontWeight="bold">
                    최초 연락 콜요원
                </Typography>
                <Link to={`/fisUsers/show/${record?.userId}`}>{record?.userId}</Link>
                <Typography variant="body1" fontWeight="bold">
                    최종 수정 콜요원
                </Typography>
                <Link to={`/fisUsers/show/${record?.modifiedStaffId}`}>{record?.modifiedStaffId}</Link>
                <Typography variant="body1" fontWeight="bold">
                    시설관련 메모
                </Typography>
                <TextField value={record?.center_etc} />
                <Typography variant="body1" fontWeight="bold">
                    현장요원관련 메모
                </Typography>
                <TextField value={record?.agent_etc} />
                <Typography variant="body1" fontWeight="bold">
                    생성일
                </Typography>
                <TextField value={record?.createdAt} />
                <Typography variant="body1" fontWeight="bold">
                    수정일
                </Typography>
                <TextField value={record?.updatedAt} />
            </Stack>
        </Show>
    );
};