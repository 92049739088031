import axios from "axios";


/*
		날짜: 2023/08/18 01:22 PM
		작성자: 임성식
		http method: GET
		URI: /director
		Content: 원장 승인 데이터 목록 api
*/
export const approvalFetchDataApi = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    try {
        const response = await axios.get(`${process.env.REACT_APP_IP_ADDRESS}/teachers/search/findByApproval?approval=WAITING`, {
            headers: {
                Authorization: `${accessToken}`
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
};

/*
		날짜: 2023/08/18 01:22 PM
		작성자: 임성식
		http method: PATCH
		URI: /director/${teacherId}/accept
		Content: 원장 승인 api
*/
export const acceptApi = async (e, dataFetching, setIsAdmin, setIsLogin) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const check = window.confirm("승인하시겠습니까?");
    let teacherId = e.currentTarget.value;
    if (check) {
        await axios
            .patch(
                `${process.env.REACT_APP_IP_ADDRESS}/director/${teacherId}/accept`,
                null,
                {
                    headers: {
                        Authorization: `${accessToken}`
                    }
                }
            )
            .then((res) => {
                dataFetching();
                alert("승인 되었습니다.");
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setIsAdmin(false);
                    setIsLogin(false);
                    console.log(err);
                }
            });
    }
};

/*
		날짜: 2023/08/18 01:22 PM
		작성자: 임성식
		http method: PATCH
		URI: /director/${teacherId}/reject
		Content: 원장 거절 api
*/
export const rejectApi = async (e, dataFetching, setIsAdmin, setIsLogin) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const check = window.confirm("거절하시겠습니까?");
    let teacherId = e.currentTarget.value;
    if (check) {
        await axios
            .patch(
                `${process.env.REACT_APP_IP_ADDRESS}/director/${teacherId}/reject`,
                null,
                {
                    headers: {
                        Authorization: `${accessToken}`
                    }
                }
            )
            .then((res) => {
                dataFetching();
                alert("거절 되었습니다.");
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setIsAdmin(false);
                    setIsLogin(false);
                    console.log(err);
                }
            });
    }
};