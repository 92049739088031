import { IResourceComponentsProps } from "@refinedev/core";
import ReportManageTemplate from "./ReportManageTemplate";


export const ReportManageList: React.FC<IResourceComponentsProps> = () => {

  return (
    <>
      <ReportManageTemplate/>
    </>
  );
};
