import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export const BookmarkShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;
console.log("UserShow record: ", record);

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    유형
                </Typography>
                <TextField value={record?.dtype ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    메시지
                </Typography>
                <TextField value={record?.message} />
                <Typography variant="body1" fontWeight="bold">
                    발송일
                </Typography>
                <TextField value={record?.createdDate} />
            </Stack>
        </Show>
    );
};