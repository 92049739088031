import { HttpError, useShow } from "@refinedev/core";
import { Edit, useAutocomplete } from "@refinedev/mui";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm } from "@refinedev/react-hook-form";

import { Controller } from "react-hook-form";

import { IExpense, Nullable } from "./interfaces";
import React from "react";

export const ExpenseEdit: React.FC = () => {
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
    } = useForm<IExpense, HttpError, Nullable<IExpense>>();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    type="date"
                    id="date"
                    {...register("date", {
                        required: "This field is required",
                    })}
                    error={!!errors.date}
                    helperText={errors.date?.message}
                    margin="normal"
                    fullWidth
                    label="실제방문날짜"
                    name="date"
                    required
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    type="number"
                    id="post"
                    {...register("post", {
                        required: "This field is required",
                    })}
                    error={!!errors.post}
                    helperText={errors.post?.message}
                    margin="normal"
                    fullWidth
                    label="우편료"
                    name="post"
                    required
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    type="number"
                    id="gas"
                    {...register("gas", {
                        required: "This field is required",
                    })}
                    error={!!errors.gas}
                    helperText={errors.gas?.message}
                    margin="normal"
                    fullWidth
                    label="연료비"
                    name="gas"
                    required
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    type="number"
                    id="parking"
                    {...register("parking", {
                        required: "This field is required",
                    })}
                    error={!!errors.parking}
                    helperText={errors.parking?.message}
                    margin="normal"
                    fullWidth
                    label="주차비"
                    name="parking"
                    required
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    type="number"
                    id="toll"
                    {...register("toll", {
                        required: "This field is required",
                    })}
                    error={!!errors.toll}
                    helperText={errors.toll?.message}
                    margin="normal"
                    fullWidth
                    label="톨비"
                    name="toll"
                    required
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    type="number"
                    id="etc"
                    {...register("etc", {
                        required: "This field is required",
                    })}
                    error={!!errors.etc}
                    helperText={errors.etc?.message}
                    margin="normal"
                    fullWidth
                    label="기타 비용"
                    name="etc"
                    required
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
        </Edit>
    );
};