import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridToolbar
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { IExpoToken } from "./interfaces";
import { MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const ExpoTokenList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<IExpoToken>({
        initialCurrent: 1,
        initialPageSize: 20,
        sorters: {
            initial: [{ field: "id", order: "desc" }]
        }
    });

    const columns = React.useMemo<GridColDef<IExpoToken>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 70 },
        { field: "token", headerName: "토큰", minWidth: 400, flex: 1,
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/expoTokens/show/${params.row.id}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        { field: "userId", headerName: "수신자ID", type: "string", minWidth: 30, flex:1,
            renderCell: (params: GridRenderCellParams) =>
            <Link to={`/users/show/${params.row.userId}`}
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}  
            </Link>
        },
        { field: "deviceId", headerName: "장치ID", type: "string", minWidth:150, flex: 1},
        { field: "accept", headerName: "승락", type: "string", minWidth: 80, flex: 1,},
        { field: "active", headerName: "활성여부", type: "string", minWidth: 80, flex: 1,},
        { 
            field: "actions", 
            headerName: "Actions", 
            width: 70, 
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div>
                        <EditButton hideText recordItemId={params.row.id} />
                    </div>
                )
            },
        }
    ],[]
    );

    return (
        <List breadcrumb={<></>}>
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-token"
                label="토큰에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "token")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "token", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-userId"
                label="수신자ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "userId")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "userId", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-deviceId"
                label="장치ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "deviceId")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "deviceId", "contains", e.target.value))}
                }
                />

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
            />
        </List>
    );
};
