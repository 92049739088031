import styled from "styled-components";
import React, {useEffect, useState} from "react";
import ListBox from "./components/ListBox";
import ReportModalTemplate from "./components/ReportModalTemplate";
import {reportFetchDataApi} from "./apis/reportApis";
import {ScrollToTopButton} from "./components/ScrollToTopButton";


export default function ReportManageTemplate() {
    /* 테스트 용 dummyData */ 
  // const data = {
  //   data: Array.from({ length: 30 }, (_, index) => ({
  //     reportType: index % 2 === 0 ? "POST" : "COMMENT", // "POST" 또는 "COMMENT"로 번갈아가며 할당
  //     targetUserLoginId: `피신고자 ${index + 1}`, // 피신고자
  //     postTitle: index % 2 === 0 ? `게시물 제목 ${index + 1}` : "", // "POST"인 경우에만 게시물 제목 할당
  //     commentPostTitle: index % 2 === 1 ? `댓글 제목 ${index + 1}` : "", // "COMMENT"인 경우에만 댓글 제목 할당
  //     reportId: index + 1, // 신고 ID
  //     reportCount: index + 1, // 신고 횟수
  //     reason: `REPORT_${String.fromCharCode(65 + index % 6)}`, // 신고 사유 설정
  //   })),
  // };

    /* 모달 창 상태관리 */
    const [modalOpen, setModalOpen] = useState(false);
    /* 해당 신고 데이터  */
    const [reportId, setReportId] = useState("");
    /* 해당 신고 유형  */
    const [reportType, setReportType] = useState("");
    /* 해당 신고 데이터 목록 가져오는 용도  */
    const [contents, setContents] = useState({ data: []}); // 더미 데이터 할당

    /*
    		날짜: 2023/08/18 1:49 PM
    		작성자: 임성식
    		작성내용: 해당 신고 데이터 모달 창 기능 여부
    */
    const onReportClick = (e, id, reportType) => {
        setModalOpen(true);
        setReportId(id)
        setReportType(reportType)
    }

    const modalClose = () => {
        setModalOpen(!modalOpen)
    }


    /*
    		날짜: 2023/08/18 1:46 PM
    		작성자: 임성식
    		작성내용: 신고 데이터 목록 가져오기
    */
    const dataFetching = async () => {
        await reportFetchDataApi(setContents)
    }

    useEffect(() => {
        dataFetching();
        sessionStorage.setItem('url', '/report');
        console.log("report useEffect", contents);
    }, [])

    const header = ["ID", "구분", "신고사유", "글작성자", "글제목", "신고횟수", "최초신고일"];
console.log("contents", contents)
    return (
        //가장 바깥 div(전체 페이지)
        <div
            style={{width: "100%", height: "100%",display: "flex"}}
        >        
        
                <div style={{width: "90%", height: "90%", margin: "0 auto"}}>
                    <StyledTitle>신고 목록</StyledTitle>

                    {/*흰색 배경 안의 신고 헤더와 신고 목록이 들어갈 div입니다*/}
                    {contents && contents.data.length !== 0 ? (
                        <StyledList>
                            <StySubTitle>
                                {
                                  header.map((item, index) => 
                                    <div style={{
                                          width: '18%',
                                          textAlign: 'center',
                                          writingMode: 'horizontal-tb'
                                        }} 
                                      key={index}>
                                        {item}
                                    </div>)
                                }
                            </StySubTitle>
                            {
                                    // contents에는 /report에서 GET요청을 통해 받아온 쿼리가 들어있음.
                                    <ListBox width="100%" contents={contents} position="absolute"
                                             justifyContent="center" alignItems="center" textAlign="center"
                                             height="100%" display="flex"
                                             borderRadius="5px"
                                             onClickFunction={onReportClick}
                                    />
                            }
                            {modalOpen && <ReportModalTemplate modalClose={modalClose} reportId={reportId}
                                                               reportType={reportType}></ReportModalTemplate>}
                        </StyledList>
                    ) : (
                        <NoneContainer><p style={{textAlign:'center'}}>현재 신고 내역이 없습니다</p></NoneContainer>
                    )}
                </div>
                <ScrollToTopButton/>
        
        </div>

    );
}

const StySubTitle = styled.div`
  width: 99%;
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  margin: 0 auto;
  height: 50px;
  line-height: 50px;
  color: #1976D2;
  font-size: 18px;
  overflow: hidden;
  border-bottom: 2px solid #1976D2;

  @media screen and (max-width: 2000px) {
    font-size: 18px;
  }

  @media screen and (max-width: 1500px) {
    font-size: 16px;
  }
`;


const StyledList = styled.div`
  //background-color: #f4f4f4;
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 2000px) {
    //overflow-x:hidden;
  }
`;


const StyledTitle = styled.div`
  width: 100%;
  color: #1976D2;
  font-size: 20px;
  text-align: left;
  margin-top: 2vh;
  padding-bottom: 3vh;
  overflow: hidden;

`;

export const NoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #1976D2;
  background-color: #f9f9f9;
  border-radius: 10px;
  font-size: 20px;

  @media screen and (max-width: 1500px) {
    font-size: 16px;
  }
`;