import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridToolbar
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { IImage } from "./interfaces";
import { MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const ImageList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<IImage>({
        initialCurrent: 1,
        initialPageSize: 20,
        sorters: {
            initial: [{ field: "id", order: "desc" }]
        }
    });

    const columns = React.useMemo<GridColDef<IImage>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 70 },
        { field: "name", headerName: "이름", minWidth: 400, flex: 1,
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/images/show/${params.row.id}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        { field: "extension", headerName: "확장자", type: "string", minWidth: 80, flex:1 },
        { field: "category", headerName: "연관Entity", type: "string", minWidth:100, flex: 1 },
        { field: "targetId", headerName: "연관Entity ID", type: "string", minWidth:100, flex: 1, 
            renderCell: (params: GridRenderCellParams) => { 
                console.log("params: ", params); 
                const category = params.row.category === "USER" ? "users" : 
                                 params.row.category === "CHILD" ? "children" :
                                 params.row.category === "CENTER" ? "centers" : 
                                 params.row.category === "POST" ? "posts":
                                 params.row.category === "BOARD" ? "boards" :
                                 params.row.category === "PRESENTATION" ? "presentations" :
                                 "images";
                return (
                    <Link to={`/${category}/show/${params.value}`} 
                          style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                            {params.value}
                    </Link>
                ) 
            }
        },
        { field: "uploadStatus", headerName: "업로드상태", type: "string", minWidth:100, flex: 1},
        { field: "createdDate", headerName: "생성일", type: "string", minWidth: 180, flex: 1,},
        { 
            field: "actions", 
            headerName: "Actions", 
            width: 70, 
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div>
                        <EditButton hideText recordItemId={params.row.id} />
                    </div>
                )
            },
        }
    ],[]
    );

    return (
        <List breadcrumb={<></>}>
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="image-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="image-search-name"
                label="이름에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "name")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "name", "eq", e.target.value))}
                }
                />

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-extension"
                label="확장자"
                key={filters.find((filter: any) => filter.field === "extension")?.value ?? "extension"}  // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "extension")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "extension", "eq", e.target.value))}
            >
                    <MenuItem value={""}>전체</MenuItem>
                    <MenuItem value={"JPG"}>JPG</MenuItem>
                    <MenuItem value={"JPEG"}>JPEG</MenuItem>
                    <MenuItem value={"PNG"}>PNG</MenuItem>
                    <MenuItem value={"WEBP"}>WEBP</MenuItem>
            </TextField>

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-category"
                label="종류"
                key={filters.find((filter: any) => filter.field === "category")?.value ?? "category"}  // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "category")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "category", "eq", e.target.value))}
            >
                    <MenuItem value={""}>전체</MenuItem>
                    <MenuItem value={"USER"}>USER</MenuItem>
                    <MenuItem value={"CHILD"}>CHILD</MenuItem>
                    <MenuItem value={"CENTER"}>CENTER</MenuItem>
                    <MenuItem value={"BOARD"}>BOARD</MenuItem>
                    <MenuItem value={"POST"}>POST</MenuItem>
                    <MenuItem value={"PRESENTATION"}>PRESENTATION</MenuItem>
            </TextField>

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="image-search-targetId"
                label="연관Entity ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "targetId")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "targetId", "contains", e.target.value))}
                }
                />

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-uploadStatus"
                label="업로드상태"
                key={filters.find((filter: any) => filter.field === "uploadStatus")?.value ?? "uploadStatus"}  // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "uploadStatus")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "uploadStatus", "eq", e.target.value))}
            >
                    <MenuItem value={""}>전체</MenuItem>
                    <MenuItem value={"NONE"}>NONE</MenuItem>
                    <MenuItem value={"PENDING"}>PENDING</MenuItem>
                    <MenuItem value={"COMPLETE"}>COMPLETE</MenuItem>
            </TextField>

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
            />
        </List>
    );
};
