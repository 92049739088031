import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

import CustomButton from "./components/CustomButton";

import {isLoginState} from "./recoils/LoginStatus";
import {isAdminState} from "./recoils/AdminStatus";

import styled from "styled-components";
import {acceptApi, approvalFetchDataApi, rejectApi} from "./apis/approvalApis";
import {DataGrid} from "@mui/x-data-grid";
import "./css/styles.css";
import axios from "axios";

export default function TeacherAcceptTemplate() {
  /* 승인 목록 데이터 */
  const [approvalList, setApprovalList] = useState([]);
  const setIsLogin = useSetRecoilState(isLoginState);
  const setIsAdmin = useSetRecoilState(isAdminState);
  /* 체크 상태를 관리할 객체 */
  const [checkedItems, setCheckedItems] = useState({});

  /*
      날짜: 2023/08/18 2:14 PM
      작성자: 임성식
      작성내용: 체크박스 기능
  */
  // const handleCheckboxChange = (id) => {
  //   setCheckedItems((prevChecked) => ({
  //     ...prevChecked,
  //     [id]: !prevChecked[id], // 해당 teacher_id의 체크 상태 토글
  //   }));

  //   // 로컬 스토리지에 체크 상태 저장
  //   localStorage.setItem("checkedItems", JSON.stringify({
  //     ...checkedItems,
  //     [id]: !checkedItems[id],
  //   }));
  // };

  /*
      작성시간: 2022/07/15 12:15 PM
      이름: 박수민
      작성내용: 원장 승인 버튼
  */
  const acceptOnClick = async (e) => {
      await acceptApi(e, dataFetching, setIsAdmin, setIsLogin)
  };

  /*
      작성시간: 2022/07/15 12:15 PM
      이름: 박수민
      작성내용: 원장 거절 버튼
  */
  const rejectOnClick = async (e) => {
      await rejectApi(e, dataFetching, setIsAdmin, setIsLogin)
  };

  /*
      작성시간: 2022/07/15 12:15 PM
      이름: 박수민
      작성내용: 원장 승인 데이터 가져오기
  */
      const dataFetching = useCallback(async () => {
        const accessToken = sessionStorage.getItem("accessToken");
    
        try {
            const data = await approvalFetchDataApi();
            console.log(data);
    
            const filteredApprovalList = data.data._embedded.teachers.filter(item => item.nickName !== '알 수 없음');
    
            // 각 객체의 센터 정보를 가져오기
            await Promise.all(filteredApprovalList.map(async (teacher) => {
                const combinedQuery = {
                    sorters: [],
                    page: 0,
                    size: 20,
                    filters: [{ field: "id", operator: "eq", value: teacher.id }]
                };
    
                try {
                    const response = await axios.get(`${process.env.REACT_APP_IP_ADDRESS}/teachers/q?params=${encodeURIComponent(JSON.stringify(combinedQuery))}`, {
                        headers: {
                            Authorization: `${accessToken}`
                        }
                    });

                    teacher.centerName = response.data.content[0].centerName;
                } catch (err) {
                    if (err.response.status === 401) {
                        setIsAdmin(false);
                        setIsLogin(false);
                        console.log(err);
                    }
                }
            }));
    
            setApprovalList(filteredApprovalList);
        } catch (err) {
            if (err.response.status === 401) {
                setIsAdmin(false);
                setIsLogin(false);
                console.log(err);
            }
        }
    }, [setIsAdmin, setIsLogin]);
    

  useEffect(() => {
    dataFetching();
    sessionStorage.setItem('url', '/accept');
    console.log("accept useeffect");

    // const storedCheckedItems = JSON.parse(localStorage.getItem("checkedItems")) || {};
    // setCheckedItems(storedCheckedItems);
  }, [dataFetching]);

  // //체크 상태여부 유지
  // useEffect(() => {
  //   localStorage.setItem("checkedItems", JSON.stringify(checkedItems));
  // }, [checkedItems]);

  const phoneNumberFormatter = (phoneNumber) => {
    const regex = /^(\d{3})(\d{3,4})(\d{4})$/;
    const formattedNumber = phoneNumber.replace(regex, '$1-$2-$3');
    return formattedNumber;
};

  const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'super-app-theme--header', width: 100},
    { field: 'name', headerName: '교사이름', headerClassName: 'super-app-theme--header', type:"string", width: 150 },
    { field: 'nickName', headerName: '닉네임', headerClassName: 'super-app-theme--header', minWidth: 100, flex: 1},
    { 
      field: 'phoneNumber', 
      headerName: '핸드폰번호', 
      headerClassName: 'super-app-theme--header', 
      minWidth: 150, 
      flex: 1,
      valueFormatter: (params) => phoneNumberFormatter(params.value)
    },
    { field: 'centerName', headerName: '센터이름', headerClassName: 'super-app-theme--header', minWidth: 200, flex: 1 },
    { field: 'address', headerName: '센터주소', headerClassName: 'super-app-theme--header', minWidth: 250, flex: 1 },
    { field: 'createdDate',
      headerName: '생성일',
      headerClassName: 'super-app-theme--header',
      minWidth: 110,
      flex: 1,
      valueGetter: params => {
        const dateString = params.value;

        // 날짜 문자열을 JavaScript Date 객체로 변환
        const date = new Date(dateString);

        // 원하는 포맷으로 변환
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      }
    },
    {
      field: 'updatedDate',
      headerName: '수정일',
      headerClassName: 'super-app-theme--header',
      minWidth: 120,
      flex: 1,
      valueGetter: params => {
        const dateString = params.value;

        // 날짜 문자열을 JavaScript Date 객체로 변환
        const date = new Date(dateString);

        // 원하는 포맷으로 변환
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      }
    },
    {
      field: 'actions',
      headerName: '승인 여부',
      headerClassName: 'super-app-theme--header',
      minWidth: 180,
      renderCell: (params) => (
        <div>
          {/* <CheckBox
            type="checkbox"
            checked={checkedItems[params.row.id]}
            onChange={() => handleCheckboxChange(params.row.id)}
          /> */}
          <StyledAcceptRejectButton
            borderRadius={"5px 0 0 5px"}
            value={params.row.id}
            onClick={acceptOnClick}
            text={"승인"}
          />
          <StyledAcceptRejectButton
            borderRadius={"0 5px 5px 0"}
            value={params.row.id}
            onClick={rejectOnClick}
            text={"거절"}
          />
        </div>
      ),
    },
  ];

  return (
    <div style={{ width:"100%", height:"auto", display:"flex"}}>
          {approvalList && approvalList.length === 0 ?
            <NoneContainer><p>현재 승인 목록이 없습니다</p></NoneContainer>
            :
            <StyledList>
              <DataGrid
                rows={approvalList}
                columns={columns}
                disableColumnMenu={true}
                hideFooter
              />
            </StyledList>
          }
    </div>
  );
}

const StyledTitle = styled.div`
  color: #1976D2;
  font-size: 20px;
  text-align: left;
  margin-top: 5vh;
  padding-bottom: 3vh;
`;

const StyleNotice = styled.div`
`;

const StyledList = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
`;

const StyledAcceptRejectButton = styled(CustomButton)`
  position: relative;
  border: none;
  border-radius: ${props => props.borderRadius};
  color: #f4f4f4;
  background-color: #1976D2;
  width: 3vw;
  height: 3vh;
  cursor: pointer;
  &:hover {
    color: #1976D2;
    background-color: #f4f4f4;
    border: 1px solid #1976D2;
    transition: color 0.3s ease, background-color 0.3s ease;
  }
`;

const CheckBox = styled.input`
  /* 체크박스의 크기 및 위치 조정 */
  width: 25px;
  height: 25px;
  margin-right: 20px;

  /* 체크되었을 때의 색상 변경 */
  &:checked {
    background-color: #007bff;
  }
`;

const NoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
  background-color: #fff;
  color: #d39793;
  border-radius: 10px;
  font-size: 20px;

  @media screen and (max-width: 1500px) {
    font-size: 16px;
  }
`;

