import { HttpError, useShow } from "@refinedev/core";
import { Edit, useAutocomplete } from "@refinedev/mui";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm } from "@refinedev/react-hook-form";

import { Controller } from "react-hook-form";

import { ICall, Nullable } from "./interfaces";
import React from "react";

export const CallEdit: React.FC = () => {
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
    } = useForm<ICall, HttpError, Nullable<ICall>>();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    id="m_email"
                    {...register("m_email", {
                        required: "This field is required",
                    })}
                    error={!!errors.m_email}
                    helperText={errors.m_email?.message}
                    margin="normal"
                    fullWidth
                    label="이메일주소"
                    name="m_email"
                    autoFocus
                    required
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
        </Edit>
    );
};