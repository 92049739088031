import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import { Typography, Stack, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

export const ExpenseShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const navigate = useNavigate();

    const record = data?.data;
    const amazonS3url = "https://" + process.env.REACT_APP_AWS_S3_BUCKET_NAME + ".s3." + process.env.REACT_APP_AWS_REGION + ".amazonaws.com/";

    const imageStyle = {
        border: "1px solid #606060",
        width: "400px"
    };

    return (
        <Show isLoading={isLoading}
        headerButtons={({ defaultButtons }) => (
            <>
            {defaultButtons}
            <Button 
                variant="contained" 
                color="primary" 
                onClick={() => {
                    navigate("/expenses/files/"+record?.id);
                }}
            >
                첨부파일 수정
            </Button>
            </>
        )}
    
        >
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    현장요원
                </Typography>
                <TextField value={record?.agent.name+"-"+record?.agent.code} />
                <Typography variant="body1" fontWeight="bold">
                    방문일
                </Typography>
                <TextField value={record?.date} />
                <Typography variant="body1" fontWeight="bold">
                    자차여부
                </Typography>
                <TextField value={record?.hasCar} />
                <Typography variant="body1" fontWeight="bold">
                    우편료
                </Typography>
                <TextField value={record?.post} />
                <Typography variant="body1" fontWeight="bold">
                    유류비
                </Typography>
                <TextField value={record?.gas} />
                <Typography variant="body1" fontWeight="bold">
                    주차비
                </Typography>
                <TextField value={record?.parking} />
                <Typography variant="body1" fontWeight="bold">
                    톨비
                </Typography>
                <TextField value={record?.toll} />
                <Typography variant="body1" fontWeight="bold">
                    기타
                </Typography>
                <TextField value={record?.etc} />
                <Typography variant="body1" fontWeight="bold">
                    비고
                </Typography>
                <TextField value={record?.comment} />
                <Typography variant="body1" fontWeight="bold">
                    수정일
                </Typography>
                <TextField value={record?.updatedAt} />
                {record?.attached1 &&
                    <a href={amazonS3url + record?.attached1} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached1} width={500} style={imageStyle} alt="image1" />
                    </a>
                }
                {record?.attached2 &&
                    <a href={amazonS3url + record?.attached2} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached2} width={500} style={imageStyle} alt="image2" />
                    </a>
                }
                {record?.attached3 &&
                    <a href={amazonS3url + record?.attached3} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached3} width={500} style={imageStyle} alt="image3" />
                    </a>
                }
                {record?.attached4 &&
                    <a href={amazonS3url + record?.attached4} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached4} width={500} style={imageStyle} alt="image4" />
                    </a>
                }
                {record?.attached5 &&
                    <a href={amazonS3url + record?.attached5} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached5} width={500} style={imageStyle} alt="image5" />
                    </a>
                }
                {record?.attached6 &&
                    <a href={amazonS3url + record?.attached6} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached6} width={500} style={imageStyle} alt="image6" />
                    </a>
                }
                {record?.attached7 &&
                    <a href={amazonS3url + record?.attached7} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached7} width={500} style={imageStyle} alt="image7" />
                    </a>
                }
            </Stack>
        </Show>
    );
};