import { S3Client, PutObjectCommand, DeleteObjectCommand } from "@aws-sdk/client-s3";
import {Buffer} from 'buffer';

const client = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    }
});

// This function assumes that the selectedFile is a base64 encoded string url.
export const uploadBase64ToS3 = async (selectedFile, prefixPath = "") => {
    let base64Data = new Buffer.from(selectedFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    let type = selectedFile.split(';')[0].split('/')[1];
    var fileName = prefixPath + Math.random().toString(16).substr(2, 8) + "." + type; //임의 문자열 생성

    const command = new PutObjectCommand({
        Body: base64Data,
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Key: fileName,
        ACL: 'public-read',
        ContentType: `image/${type}`,
        ContentEncoding: 'base64'
    })        

    try {
        const response = await client.send(command);
        console.log("Successfully uploaded file.", response);
        return fileName;
    } catch (err) {
        throw err;
    }
}

export const removeFileFromS3 = async (fileName) => { console.log("removeFileFromS3 fileName: ", fileName)
    const command = new DeleteObjectCommand({
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Key: `${fileName}`,
    });

    try {
        const response = await client.send(command);
        console.log("Successfully removed file.", response);
        return true;
    } catch (err) {
        throw err;
    }
}

