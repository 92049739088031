import { HttpError } from "@refinedev/core";
import { Create, useAutocomplete } from "@refinedev/mui";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm } from "@refinedev/react-hook-form";

import { Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { IAlarm, Nullable } from "./interfaces";
import React from "react";

export const AlarmCreate: React.FC = () => {
    const {
        saveButtonProps,
        register,
        control,
        formState: { errors },
    } = useForm<IAlarm, HttpError, Nullable<IAlarm>>();

    const { state } = useLocation();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >

                <TextField
                    id="userGroup"
                    {...register("userGroup", {
                        required: state === undefined || state === null || state?.ids.length === 0,
                    })}
                    margin="normal"
                    fullWidth
                    label="사용자그룹"
                    key={"userGroup"}  // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                    defaultValue={""}
                    required
                    select
                    disabled={!(state === undefined || state === null || state?.ids.length === 0)}
                    variant="outlined"
                >
                    <MenuItem value={""}>없음</MenuItem>
                    <MenuItem value={"DIRECTOR"}>시설관리자</MenuItem>
                    <MenuItem value={"TEACHER"}>교사</MenuItem>
                    <MenuItem value={"PARENT"}>학부모</MenuItem>
                    <MenuItem value={"ALL"}>전체</MenuItem>
                </TextField>

                <TextField
                    id="userIds"
                    {...register("userIds", {
                        required: !(state === undefined || state === null || state?.ids.length === 0),
                    })}
                    margin="normal"
                    fullWidth
                    label="수신자ID"
                    name="userIds"
                    required
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                    value={state?.ids.join(", ")}
                    disabled={state === undefined || state === null || state?.ids.length === 0}
                />

                <TextField
                    id="title"
                    {...register("title", {
                        required: "This field is required",
                    })}
                    error={!!errors.title}
                    helperText={errors.title?.message}
                    margin="normal"
                    fullWidth
                    label="제목"
                    name="title"
                    autoFocus
                    required
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    id="message"
                    {...register("message", {
                        required: "This field is required",
                    })}
                    error={!!errors.message}
                    helperText={errors.message?.message}
                    margin="normal"
                    fullWidth
                    multiline
                    label="메시지"
                    name="message"
                    required
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    id="badgeCount"
                    {...register("badgeCount", {
                        required: false,
                    })}
                    error={!!errors.badgeCount}
                    helperText={errors.badgeCount?.message}
                    margin="normal"
                    fullWidth
                    label="배지숫자"
                    name="badgeCount"
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
        </Create>
    );
};