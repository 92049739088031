import { HttpError } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import Box from "@mui/material/Box";
import { MenuItem, Grid, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";

import { ICenter, Nullable } from "./interfaces";
import React from "react";

import { useDaumPostcodePopup } from "react-daum-postcode";
import { axiosInstance } from "functions";

export const CenterEdit: React.FC = () => {
    const {
        saveButtonProps,
        register,
        control,
        formState: { errors },
        setValue,
        getValues,
    } = useForm<ICenter, HttpError, Nullable<ICenter>>();

    const open = useDaumPostcodePopup();

    const handleCompleteDaum = (data: any) => {
        // console.log("daum", data)
        let fullAddress = data.address;
        let extraAddress = '';
        let sigunguCode = data.bcode.substring(0, 5);

        setValue("address", data.address)
        setValue("zipcode", data.zonecode);

        // 시군구 코드 조회
        // DB에 저장된 시군구 코드는 어린이집의 경우, 최신을 반영하고 있지 않다. 따라서 어린이집을 위한 시군구 코드는 제외시킨다.
        axiosInstance.get(`${process.env.REACT_APP_IP_ADDRESS}/regions/search/findBySigunguCodeAndKindOfNot?sigunguCode=${sigunguCode}&kindOf=ChildHouse`)
            .then((response) => {
                console.log("response", response);
                if (response.data._embedded.regions.length !== 1) {
                    alert("시군구 코드를 조회하는데 실패했습니다.1");
                    return;
                }
                setValue("sido", response.data._embedded.regions[0].sidoName);
                setValue("sigungu", response.data._embedded.regions[0].sigunguName);
            })
            .catch((error) => {
                alert("시군구 코드를 조회하는데 실패했습니다.2")
                console.log("error", error);
            });

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    };

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <Grid container direction="row" justifyContent="space-between">
                    <TextField sx={{ width: '30%' }}
                        id="kindOf"
                        {...register("kindOf", {
                            required: "This field is required",
                            onChange: (e) => { setValue("kindOf", e.target.value, { shouldValidate: true }) }
                        })}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        label="구분"
                        name="kindOf"
                        select
                        variant="outlined"
                        required
                        InputLabelProps={{ shrink: true }}
                        value={getValues("kindOf") || "기타"}
                    >
                        <MenuItem value={"기타"}>기타</MenuItem>
                        <MenuItem value={"ChildHouse"}>어린이집</MenuItem>
                        <MenuItem value={"Kindergarten"}>유치원</MenuItem>
                    </TextField>

                    <TextField sx={{ width: '30%' }}
                        id="estType"
                        {...register("estType", {
                            required: "This field is required",
                            onChange: (e) => { setValue("estType", e.target.value, { shouldValidate: true }) }
                        })}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        label="설립유형"
                        name="estType"
                        select
                        variant="outlined"
                        required
                        InputLabelProps={{ shrink: true }}
                        value={getValues("estType") || "기타"}
                    >
                        {getValues("kindOf") === "ChildHouse" && <MenuItem value={"NATIONAL_PUBLIC"}>국공립</MenuItem>}
                        {getValues("kindOf") === "ChildHouse" && <MenuItem value={"WORKPLACE"}>직장</MenuItem>}
                        {getValues("kindOf") === "ChildHouse" && <MenuItem value={"CORP_ORGS"}>법인·단체등</MenuItem>}
                        {getValues("kindOf") === "ChildHouse" && <MenuItem value={"HOME"}>가정</MenuItem>}
                        {getValues("kindOf") === "ChildHouse" && <MenuItem value={"CIVIL"}>민간</MenuItem>}
                        {getValues("kindOf") === "ChildHouse" && <MenuItem value={"SOCIAL_WELFARE"}>사회복지법인</MenuItem>}
                        {getValues("kindOf") === "ChildHouse" && <MenuItem value={"COOPERATIVE"}>협동</MenuItem>}
                        {getValues("kindOf") === "Kindergarten" && <MenuItem value={"NATIONAL"}>국립</MenuItem>}
                        {getValues("kindOf") === "Kindergarten" && <MenuItem value={"PUBLIC_ATTACHED"}>공립(병설)</MenuItem>}
                        {getValues("kindOf") === "Kindergarten" && <MenuItem value={"PUBLIC_STANDALONE"}>공립(단설)</MenuItem>}
                        {getValues("kindOf") === "Kindergarten" && <MenuItem value={"PRIVATE_CORP"}>사립(법인)</MenuItem>}
                        {getValues("kindOf") === "Kindergarten" && <MenuItem value={"PRIVATE_INDIVIDUAL"}>사립(사인)</MenuItem>}
                        {(getValues("kindOf") === "기타" || getValues("kindOf") === null) && <MenuItem value={"기타"}>기타</MenuItem>}
                    </TextField>

                    <TextField sx={{ width: '30%' }}
                        id="status"
                        {...register("status", {
                            required: "This field is required",
                            onChange: (e) => { setValue("status", e.target.value, { shouldValidate: true }) }
                        })}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        label="상태"
                        name="status"
                        select
                        variant="outlined"
                        required
                        InputLabelProps={{ shrink: true }}
                        value={getValues("status") || ""}
                    >
                        <MenuItem value={"OPENED"}>정상</MenuItem>
                        <MenuItem value={"CLOSED"}>폐지</MenuItem>
                        <MenuItem value={"PAUSED"}>휴지</MenuItem>
                        <MenuItem value={"REOPENED"}>재개</MenuItem>
                    </TextField>

                </Grid>
                <TextField
                    id="name"
                    {...register("name", {
                        required: "This field is required",
                        onChange: (e) => {
                            e.target.value = e.target.value.replace(/[ ]/g, '');
                        }
                    })}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    margin="normal"
                    fullWidth
                    label="이름"
                    name="name"
                    autoFocus
                    required
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    id="address"
                    {...register("address", {
                        required: "This field is required",
                    })}
                    error={!!errors.address}
                    helperText={errors.address?.message}
                    margin="normal"
                    label="주소"
                    name="address"
                    required
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => { open({ onComplete: handleCompleteDaum }) }}
                />
                <Grid container direction="row" justifyContent="space-between">
                    <TextField
                        id="addressDetail"
                        {...register("addressDetail", {
                            required: false,
                        })}
                        error={!!errors.addressDetail}
                        helperText={errors.addressDetail?.message}
                        margin="normal"
                        sx={{ width: "50%" }}
                        label="상세주소"
                        name="addressDetail"
                        InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                        id="sido"
                        {...register("sido", {
                            required: false,
                        })}
                        error={!!errors.sido}
                        helperText={errors.sido?.message}
                        margin="normal"
                        sx={{ width: "15%" }}
                        label="시도"
                        name="sido"
                        InputLabelProps={{ shrink: true }}
                        disabled={true}
                    />

                    <TextField
                        id="sigungu"
                        {...register("sigungu", {
                            required: false,
                        })}
                        error={!!errors.sigungu}
                        helperText={errors.sigungu?.message}
                        margin="normal"
                        sx={{ width: "15%" }}
                        label="시군구"
                        name="sigungu"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        disabled={true}
                    />

                    <TextField
                        id="zipcode"
                        {...register("zipcode", {
                            required: false,
                        })}
                        error={!!errors.zipcode}
                        helperText={errors.zipcode?.message}
                        margin="normal"
                        sx={{ width: "15%" }}
                        label="우편번호"
                        name="zipcode"
                        InputLabelProps={{ shrink: true }}
                        disabled={true}
                    />
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                    <TextField
                        id="tel"
                        {...register("tel", {
                            required: "This field is required",
                            onChange: (e) => {
                                const value = e.target.value.replace(/-/g, '');
                                let formattedPhone = ''

                                if (value.length < 4) {
                                    formattedPhone = value
                                } else if (value.startsWith('02')) {
                                    if (value.length < 7) {
                                        formattedPhone = `${value.slice(0, 2)}-${value.slice(2)}`
                                    } else if (value.length < 10) {
                                        formattedPhone = `${value.slice(0, 2)}-${value.slice(2, 5)}-${value.slice(5)}`
                                    } else {
                                        formattedPhone = `${value.slice(0, 2)}-${value.slice(2, 6)}-${value.slice(6, 10)}`
                                    }
                                } else {
                                    if (value.length < 8) {
                                        formattedPhone = `${value.slice(0, 3)}-${value.slice(3)}`
                                    } else if (value.length < 11) {
                                        formattedPhone = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`
                                    } else {
                                        formattedPhone = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7, 11)}`
                                    }
                                }

                                const displayPhone = formattedPhone.length > 0 ? formattedPhone : ''
                                e.target.value = displayPhone;
                            }
                        })}
                        error={!!errors.tel}
                        helperText={errors.tel?.message}
                        margin="normal"
                        sx={{ width: "50%" }}
                        label="전화번호"
                        name="tel"
                        required
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        id="homepage"
                        {...register("homepage", {
                            required: false,
                        })}
                        error={!!errors.homepage}
                        helperText={errors.homepage?.message}
                        margin="normal"
                        sx={{ width: "48%" }}
                        label="웹페이지"
                        name="homepage"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Box>
        </Edit>
    );
};