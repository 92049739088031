import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridToolbar
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { IBookmark } from "./interfaces";
import { MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const BookmarkList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<IBookmark>({
        initialCurrent: 1,
        initialPageSize: 20,
        sorters: {
            initial: [{ field: "id", order: "desc" }]
        }
    });

    const columns = React.useMemo<GridColDef<IBookmark>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 70 },
        { field: "userId", headerName: "사용자ID", type: "string", minWidth: 30, flex:1,
            renderCell: (params: GridRenderCellParams) =>
            <Link to={`/users/show/${params.value}`}
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}  
            </Link>
        },
        { field: "boardId", headerName: "이야기게시판ID", type: "string", minWidth:150, flex: 1},
        { 
            field: "actions", 
            headerName: "Actions", 
            width: 70, 
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div>
                        <EditButton hideText recordItemId={params.row.id} />
                    </div>
                )
            },
        }
    ],[]
    );

    return (
        <List breadcrumb={<></>}>
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-userId"
                label="사용자ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "userId")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "userId", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-boardId"
                label="이야기게시판ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "boardId")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "boardId", "contains", e.target.value))}
                }
                />

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
            />
        </List>
    );
};
