import React,{useState,useEffect} from 'react';
import styled from 'styled-components'
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    LineChart,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label
} from 'recharts';
import axios from "axios";
import {useRecoilState} from "recoil";
import {isRegionState} from "./recoils/RegionStatus";
import CustomTooltip from "./components/CustomTooltip";
import ExcelJS from "exceljs";
import {fetchDataForDates} from "./apis/statisticsApi";

export const Container = styled.div`
  margin: 0 0 auto;
  width: 100%;
`;

export const ButtonContainer = styled.div`
`;
export const DwButton = styled.button`
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid #e0b4b0;
  background-color: #fff;
  color: #e0b4b0;
  height: 34px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: bold;
  
  cursor: pointer;
  &:hover {
    border: none;
    background-color: #e0b4b0;
    color: white;
  }
`;


export default function DailychartRegion({startDate, endDate}) {
    const [data, setData] = useState([]); // State to store the fetched data
    //지역 선택을 위한 RecoilState
    const [regionState, setRegionState] = useRecoilState(isRegionState)


    // 현재 날짜와 시간
    const today = new Date();

    // 현재 일자를 기준으로 일주일 이전까지의 날짜들을 배열에 넣기
    const dateArray = [];
    const currentDate = new Date(startDate);
    const endDateObj = new Date(endDate); // Convert endDate to a Date object

    while (currentDate <= endDateObj) {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        dateArray.unshift(formattedDate);
        currentDate.setDate(currentDate.getDate() + 1); // 다음 날짜로 이동
    }

    const generateExcelData = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        // 열 헤더 추가
        worksheet.addRow(['날짜', '총회원수', '누적 총 학부모', '누적 총 교사', '누적 정상 수', '누적 정상 학부모', '누적 정상 교사', '금일 가입자 수', '금일 가입 학부모', '금일 가입 교사', '누적 탈퇴 수', '누적 탈퇴 학부모', '누적 탈퇴 교사', '금일 탈퇴 수', '금일 탈퇴 학부모', '금일 탈퇴 교사', '누적 영구정지 수', '누적 영구정지 학부모', '누적 영구정지 교사', '금일 영구정지 수', '금일 영구정지 학부모', '금일 영구정지 교사', '누적 7일 정지 수', '누적 7일 정지 학부모', '누적 7일 정지 교사', '금일 7일정지 수', '금일 7일정지 학부모', '금일 7일정지 교사']);

        // 데이터 행 추가
        data.forEach((item) => {
            const {
                date,
                parentUserCnt,
                teacherUserCnt,
                validParentUserCnt,
                validTeacherUserCnt,

                joinParentCnt,
                joinTeacherCnt,

                quitParentUserCnt,
                quitTeacherUserCnt,
                quitParentCnt,
                quitTeacherCnt,

                bannedParentUserCnt,
                bannedTeacherUserCnt,
                bannedParentCnt,
                bannedTeacherCnt,

                restrictedParentUserCnt,
                restrictedTeacherUserCnt,
                restrictedParentCnt,
                restrictedTeacherCnt,
            } = item;

            const totalUserCnt= parentUserCnt + teacherUserCnt;
            const validUserCnt= validParentUserCnt + validTeacherUserCnt;
            const joinUserCnt = joinParentCnt+joinTeacherCnt;
            const quitUserCnt = quitParentUserCnt+quitTeacherUserCnt;
            const quitCnt = quitParentCnt+quitTeacherCnt;
            const bannedUserCnt = bannedParentUserCnt+bannedTeacherUserCnt;
            const bannedCnt = bannedParentCnt+bannedTeacherCnt;
            const restrictedUserCnt = restrictedParentUserCnt+restrictedTeacherUserCnt;
            const restrictedCnt = restrictedParentCnt+restrictedTeacherCnt;

            worksheet.addRow([
                date,
                totalUserCnt,
                parentUserCnt,
                teacherUserCnt,
                validUserCnt,
                validParentUserCnt,
                validTeacherUserCnt,
                joinUserCnt,
                joinParentCnt,
                joinTeacherCnt,
                quitUserCnt,
                quitParentUserCnt,
                quitTeacherUserCnt,
                quitCnt,
                quitParentCnt,
                quitTeacherCnt,
                bannedUserCnt,
                bannedParentUserCnt,
                bannedTeacherUserCnt,
                bannedCnt,
                bannedParentCnt,
                bannedTeacherCnt,
                restrictedUserCnt,
                restrictedParentUserCnt,
                restrictedTeacherUserCnt,
                restrictedCnt,
                restrictedParentCnt,
                restrictedTeacherCnt,
                /* 나머지 데이터 열에 대한 속성을 추가하세요 */
            ]);
        });

        return workbook.xlsx.writeBuffer()
            .then((buffer) => {
                return buffer;
            });
    };

    const downloadExcel = () => {
        generateExcelData().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = '지역별통계.xlsx'; // 파일 확장자를 .xlsx로 변경
            a.click();

            URL.revokeObjectURL(url);
        });
    };


    useEffect(() => {
        // Fetch data for dates
        fetchDataForDates(dateArray, regionState, setData);
    }, [regionState, startDate, endDate]);

    console.log("데이터", data);

    return (

        <Container className='contentWrap'>
            <div className='contentInner'>


                {/*<h2>데일리 통계</h2>*/}
                <ResponsiveContainer width='100%' height={300}>
                    <ComposedChart data={data}>
                        {/*<Label*/}
                        {/*    content={`누적 정상회원수: ${data[data.length - 1]?.validParentUserCnt}`}*/}
                        {/*    position="top" // 라벨을 그래프 위쪽에 표시*/}
                        {/*    fill="green" // 텍스트 색상 설정*/}
                        {/*/>*/}


                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='date' />
                        <YAxis yAxisId='left' type="number" domain={['dataMin ', 'dataMax']} />
                        <YAxis yAxisId='right' orientation='right' type="number" domain={['dataMin ', 'dataMax ']}/>
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Line
                            name='총회원수'
                            yAxisId='left'
                            type='monotone'
                            dataKey='totalUserCnt'
                            stroke='#8884d8'
                            activeDot={{ r: 8 }}

                        />


                        <Bar name='당일 가입 학부모' yAxisId='right' dataKey='joinParentCnt' barSize={10} fill='#00BFFF'/>
                        <Bar name='당일 가입 교사' yAxisId='right' dataKey='joinTeacherCnt' barSize={10} fill='#87CEEB'/>

                        <Bar name='당일 탈퇴 학부모' yAxisId='right' dataKey='quitParentCnt' barSize={10} fill='#FF0000'/>
                        <Bar name='당일 탈퇴 교사' yAxisId='right' dataKey='quitTeacherCnt' barSize={10} fill='#FF3333'/>

                        <Bar name='당일 영구정지 학부모' yAxisId='right' dataKey='bannedParentCnt' barSize={10} fill='#FF00BF'/>
                        <Bar name='당일 영구정지 교사' yAxisId='right' dataKey='bannedTeacherCnt' barSize={10} fill='#FE2EF7'/>

                        <Bar name='당일 7일정지 학부모' yAxisId='right' dataKey='restrictedParentCnt' barSize={10} fill='#FFBF00'/>
                        <Bar name='당일 7일정지 교사' yAxisId='right' dataKey='restrictedTeacherCnt' barSize={10} fill='#FACC2E'/>

                        {/*<Bar name='누적 정상 학부모 회원 수' yAxisId='right' dataKey='validParentUserCnt' barSize={0} fill='#00CC33'/>*/}
                        {/*<Bar name='누적 정상 교사 회원 수' yAxisId='right' dataKey='validTeacherUserCnt' barSize={0} fill='#00CC66'/>*/}

                        {/* <Bar name='누적 탈퇴한 학부모 회원 수' yAxisId='right' dataKey='quitParentUserCnt' barSize={10} fill='#CC0303'/>
                        <Bar name='누적 탈퇴한 교사 회원 수' yAxisId='right' dataKey='quitTeacherUserCnt' barSize={10} fill='#CC3300'/> */}

                        {/*<Bar name='누적 영구정지당한 학부모 회원 수' yAxisId='right' dataKey='bannedParentUserCnt' barSize={0} fill='#FF6600'/>*/}
                        {/*<Bar name='누적 영구정지당한 교사 회원 수' yAxisId='right' dataKey='bannedTeacherUserCnt' barSize={0} fill='#FF6633'/>*/}

                        {/*<Bar name='누적 7일정지당한 학부모 회원 수' yAxisId='right' dataKey='restrictedParentUserCnt' barSize={0} fill='#FFD700'/>*/}
                        {/*<Bar name='누적 7일정지당한 교사 회원 수' yAxisId='right' dataKey='restrictedTeacherUserCnt' barSize={0} fill='#FFD75F'/>*/}

                    </ComposedChart>

                </ResponsiveContainer>
            </div>
            <ButtonContainer>
                <DwButton onClick={downloadExcel}>지역별통계 다운</DwButton>
            </ButtonContainer>
        </Container>
    );
}
