import { HttpError } from "@refinedev/core";
import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
      config.headers['Authorization'] = `${sessionStorage.getItem('accessToken')}`;
      return config;
  },
  (error) => {
      return Promise.reject(error);
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

const blockUserApi = async (userId: any, reqBody: any) => {

  await axiosInstance
      .post(`${process.env.REACT_APP_IP_ADDRESS}/admin-block/${userId}`, reqBody )
      .then((res) => {
          console.log("중단 완료");
          alert("중단되었습니다.");
          window.history.back();
      })
      .catch((err) => {
          alert("중단에 실패했습니다.")
          if (err.response.status === 401) {
              console.log(err);
          }
      });
};


export { axiosInstance, blockUserApi };
