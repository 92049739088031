import { DataProvider, HttpError } from "@refinedev/core";
import { axiosInstance, generateSort, generateFilter } from "functions";
import axios from "axios";
import { AxiosInstance } from "axios";
import { stringify } from "query-string";
import { IParent } from "../interfaces";

type MethodTypes = "get" | "delete" | "head" | "options";
type MethodTypesWithBody = "post" | "put" | "patch";

export const parentDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): Omit<
  Required<DataProvider>,
  "createMany" | "updateMany" | "deleteMany"
> => ({
  getList: async ({ resource, pagination, filters, sorters, meta }) => {
    console.log("getParents", resource, pagination, filters, sorters, meta);
    let url = `${apiUrl}/${resource}`;

    const { current = 1, pageSize = 10, mode = "server" } = pagination ?? {};

    const { headers: headersFromMeta, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";

    const queryFilters = generateFilter(filters);

    const query: {
      page?: number;
      size?: number;
    } = {};

    if (mode === "server") {
      query.page = current - 1;
      query.size = pageSize;
    }

    const combinedQuery = { sorters: sorters, filters: filters, ...query };

    const urlWithQuery = `${url}/q?params=${encodeURIComponent(JSON.stringify(combinedQuery))}`;

    var { data, headers } = await httpClient[requestMethod](urlWithQuery, {
      headers: headersFromMeta,
    });

    const total = +data.totalElements;
    data = data.content;

    return {
      data,
      total: total || data.length,
    };
  },

  getMany: async ({ resource, ids, meta }) => {
    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";

    const { data } = await httpClient[requestMethod](
      `${apiUrl}/${resource}?${stringify({ id: ids })}`,
      { headers }
    );

    return {
      data,
    };
  },

  create: async ({ resource, variables, meta }) => {
    console.log("create", resource, variables, meta);
    const url = `${apiUrl}/${resource}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "post";

    const { data } = await httpClient[requestMethod](url, variables, {
      headers,
    });

    return {
      data,
    };
  },

  update: async ({ resource, id, variables, meta }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "patch";

    const parentVariable = variables as IParent;

    if (parentVariable.newPassword !== "******" && parentVariable.newPassword !== parentVariable.passwordCheck) {
      const error: HttpError = {
        message: "비밀번호가 일치하지 않습니다.",
        statusCode: 400,
      };
      return Promise.reject(error);
    }

    const address = parentVariable.address;

    const response: any = await axios.get("https://dapi.kakao.com/v2/local/search/address.json?query=" + address, {
      headers: {
        Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`,
      },
    });

    if (response.data.documents.length === 0) {
      const error: HttpError = {
        message: "주소로부터 위경도를 찾을 수 없습니다.",
        statusCode: 400,
      };
      return Promise.reject(error);
    }

    const longitude = response.data.documents[0].x;
    const latitude = response.data.documents[0].y;

    const requestBody = {
      id: id,
      loginId: parentVariable.loginId,
      name: parentVariable.name,
      nickName: parentVariable.nickName,
      auth: parentVariable.auth,
      phoneNumber: parentVariable.phoneNumber,
      emailAddress: parentVariable.emailAddress,
      address: parentVariable.address,
      detailAddress: parentVariable.detailAddress,
      location: {
        sido: parentVariable.location.sido,
        sigungu: parentVariable.location.sigungu,
        longitude: longitude,
        latitude: latitude,
      }
    };

    const { data } = await httpClient[requestMethod](url, requestBody, {
      headers,
    });

    if (parentVariable.newPassword !== "******") {
      const { data: data2 } = await httpClient["put"](
        `${apiUrl}/user/${id}/reset-password`,
        {
          newPwd: parentVariable.newPassword,
          newPwdCheck: parentVariable.passwordCheck,
        },
        {
          headers,
        }
      );
    }

    return {
      data,
    };
  },

  getOne: async ({ resource, id, meta }) => {
    console.log("getOne", resource, id, meta);
    const url = `${apiUrl}/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";

    const { data } = await httpClient[requestMethod](url, { headers });

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables, meta }) => {
    console.log("deleteOne", resource, id, variables, meta);
    const url = `${apiUrl}/${resource}/${id}/withdraw`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "delete";

    const { data } = await httpClient[requestMethod](url, {
      data: variables,
      headers,
    });

    return {
      data,
    };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({
    url,
    method,
    filters,
    sorters,
    payload,
    query,
    headers,
  }) => {
    let requestUrl = `${url}?`;

    if (sorters) {
      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(","),
          _order: _order.join(","),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload, {
          headers,
        });
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url, {
          data: payload,
          headers: headers,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl, {
          headers,
        });
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
