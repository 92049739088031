import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridToolbar
} from "@mui/x-data-grid";

import { Link, useNavigate } from "react-router-dom";

import { IChild } from "./interfaces";
import { Button, MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const ChildList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<IChild>({
        initialCurrent: 1,
        initialPageSize: 20,
    });
console.log("childList filters: ", filters)
    const columns = React.useMemo<GridColDef<IChild>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 110 },
        { field: "name", headerName: "이름", minWidth: 200, flex: 1, 
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/children/show/${params.row.id}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        {
            field: "parentId",
            headerName: "학부모ID",
            minWidth: 50,
            flex: 1,
            renderCell: (params) => 
            <Link to={`/parents/show/${params.value}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
            ,
          },
          {
            field: "centerId",
            headerName: "센터ID",
            minWidth: 50,
            flex: 1,
            renderCell: (params) => 
            <Link to={`/centers/show/${params.value}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
            ,
          },
        { field: "updatedDate", headerName: "수정일", minWidth: 110, flex: 1, 
            renderCell: (params: GridRenderCellParams) => {
                const date = new Date(params.value);
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();

                const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
                const amPm = hours < 12 ? '오전' : '오후';
                const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
                const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

                const formattedDate = `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일 ${amPm} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
                return <span>{formattedDate}</span>;
        }},
        { 
            field: "actions", 
            headerName: "Actions", 
            width: 100, 
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div>
                        <EditButton hideText recordItemId={params.row.id} />
                    </div>
                )
            },
        }
    ],[]
    );

    return (
        <List 
            breadcrumb={<></>}
            key={Date.now()}
        >
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                        setFilters(updateFilters(filters, "id", "contains", e.target.value));
                    }
                }}
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-name"
                label="이름에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "name")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                        setFilters(updateFilters(filters, "name", "contains", e.target.value));
                    }
                }}
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-parentId"
                label="학부모ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "parentId")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                        setFilters(updateFilters(filters, "parentId", "contains", e.target.value));
                    }
                }}
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-centerId"
                label="centerId에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "centerId")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                        setFilters(updateFilters(filters, "centerId", "contains", e.target.value));
                    }
                }}
                />

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
            />
        </List>
    );
};

