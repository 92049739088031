import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";
import { useUpdateMany, useModal } from "@refinedev/core";
import { useNavigate } from "react-router-dom";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridToolbar
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { ICenter } from "./interfaces";
import { MenuItem, TextField, Button, Modal, Box } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const CenterList: React.FC = () => {
    const { dataGridProps, setFilters, filters } = useDataGrid<ICenter>({
        initialCurrent: 1,
        initialPageSize: 20,
        sorters: {
            initial: [{ field: "id", order: "desc" }]
        }
    });

    const navigate = useNavigate();
    const { visible, show, close } = useModal();

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [kindOfForMultipleUpdate, setKindOfForMultipleUpdate] = React.useState<string>("");

    const { mutate } = useUpdateMany<ICenter>();

    const updateSelectedItems = (value: any) => { 
        if (value === "") {
            alert("종류를 선택해주세요.");
            return;
        }
        mutate(
            {
                resource: `centers`,
                values: { 
                    kindOf: value,
                },
                ids: rowSelectionModel.map((id) => id.toString())
            },
            {
                onSuccess: () => {
                    setRowSelectionModel([]);
                    navigate(0);
                }
            }   
        )
    }

    const columns = React.useMemo<GridColDef<ICenter>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", flex: 0.5, filterable: false },
        { field: "name", headerName: "이름", flex: 1, 
            renderCell: (params: GridRenderCellParams) => <Link to={`/centers/show/${params.row.id}`}>{params.value}</Link> 
        },
        { field: "kindOf", headerName: "종류", flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                let kindText = "";
                if (params.value === "Kindergarten") {
                    kindText = "유치원";
                } else if (params.value === "ChildHouse") {
                    kindText = "어린이집";
                } else if (params.value === "Dementia") {
                    kindText = "치매";
                } else if (params.value === "IDisabled") {
                    kindText = "지적장애";
                } else {
                    kindText = "기타";
                }
                return <span>{kindText}</span>;
        }},
        { field: "address", headerName: "주소", flex: 2 },
        { field: "tel", headerName: "전화번호", flex: 1},
        { field: "sido", headerName: "시도", flex: 1 },
        { field: "sigungu", headerName: "시군구", flex: 1 },
        { field: "estType", headerName: "유형", flex: 0.5,
            renderCell: (params: GridRenderCellParams) => {
                let kindText = "";
                if (params.value === "NATIONAL") {
                    kindText = "국립";
                } else if (params.value === "PUBLIC_ATTACHED") {
                    kindText = "공립(병설)";
                } else if (params.value === "PUBLIC_STANDALONE") {
                    kindText = "공립(단설)";
                } else if (params.value === "PRIVATE_CORP") {
                    kindText = "사립(법인)";
                } else if (params.value === "PRIVATE_INDIVIDUAL") {
                    kindText = "사립(사인)";
                } else if (params.value === "NATIONAL_PUBLIC") {
                    kindText = "국공립";
                } else if (params.value === "SOCIAL_WELFARE") {
                    kindText = "사회복지법인";
                } else if (params.value === "CORP_ORGS") {
                    kindText = "법인·단체등";
                } else if (params.value === "CIVIL") {
                    kindText = "민간";
                } else if (params.value === "HOME") {
                    kindText = "가정";
                } else if (params.value === "COOPERATIVE") {
                    kindText = "협동";
                } else if (params.value === "WORKPLACE") {
                    kindText = "직장";
                } else {
                    kindText = "???";
                }
                return <span>{kindText}</span>;
            }
        },
        { field: "status", headerName: "상태", flex: 0.5,
            renderCell: (params: GridRenderCellParams) => {
                let kindText = "";
                if (params.value === "OPENED") {
                    kindText = "정상";
                } else if (params.value === "PAUSED") {
                    kindText = "휴지";
                } else if (params.value === "CLOSED") {
                    kindText = "폐지";
                } else if (params.value === "REOPENED") {
                    kindText = "재개";
                } else {
                    kindText = "???";
                }
                return <span>{kindText}</span>;
            }
        },
        { field: "updatedDate", headerName: "수정일", flex: 1 },
    ],[]
    );

    const hasSelected = rowSelectionModel.length > 0;
    
    return (
        <List 
            breadcrumb={<></>}
            headerButtons={({ defaultButtons }) => (
                <>
                {defaultButtons}
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => show()}
                    disabled={!hasSelected}
                >
                    한번에 수정하기
                </Button>
                </>
            )}
            key={Date.now()}
        >

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value)) }
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-name"
                label="이름에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "name")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "name", "contains", e.target.value)) }
                }
                />

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-kindOf"
                label="종류"
                key={filters.find((filter: any) => filter.field === "kindOf")?.value ?? "kindOf"}  // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "kindOf")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "kindOf", "eq", e.target.value))}
            >
                    <MenuItem value={""}>전체</MenuItem>
                    <MenuItem value={"ChildHouse"}>어린이집</MenuItem>
                    <MenuItem value={"Kindergarten"}>유치원</MenuItem>
                    <MenuItem value={"Dementia"}>치매</MenuItem>
                    <MenuItem value={"IDisabled"}>지적장애</MenuItem>
            </TextField>

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-address"
                label="주소에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "address")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "address", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-tel"
                label="전화번호에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "tel")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "tel", "contains", e.target.value))}
                }
                />

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-estType"
                label="유형"
                key={filters.find((filter: any) => filter.field === "estType")?.value ?? "estType"}   // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "estType")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "estType", "eq", e.target.value))}
            >
                        <MenuItem value={""}>전체</MenuItem>
                        <MenuItem value={"NATIONAL"}>국립</MenuItem>
                        <MenuItem value={"PUBLIC_ATTACHED"}>공립(병설)</MenuItem>
                        <MenuItem value={"PUBLIC_STANDALONE"}>공립(단설)</MenuItem>
                        <MenuItem value={"PRIVATE_CORP"}>사립(법인)</MenuItem>
                        <MenuItem value={"PRIVATE_INDIVIDUAL"}>사립(사인)</MenuItem>
                        <MenuItem value={"NATIONAL_PUBLIC"}>국공립</MenuItem>
                        <MenuItem value={"SOCIAL_WELFARE"}>사회복지법인</MenuItem>
                        <MenuItem value={"CORP_ORGS"}>법인·단체등</MenuItem>
                        <MenuItem value={"CIVIL"}>민간</MenuItem>
                        <MenuItem value={"HOME"}>가정</MenuItem>
                        <MenuItem value={"COOPERATIVE"}>협동</MenuItem>
                        <MenuItem value={"WORKPLACE"}>직장</MenuItem>
            </TextField>

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-status"
                label="상태"
                key={filters.find((filter: any) => filter.field === "status")?.value ?? "status"}   // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "status")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "status", "eq", e.target.value))}
            >
                        <MenuItem value={""}>전체</MenuItem>
                        <MenuItem value={"OPENED"}>정상</MenuItem>
                        <MenuItem value={"CLOSED"}>폐지</MenuItem>
                        <MenuItem value={"PAUSED"}>휴지</MenuItem>
                        <MenuItem value={"REOPENED"}>재개</MenuItem>
            </TextField>

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
                checkboxSelection={true}
                onRowSelectionModelChange={(e: any) => { setRowSelectionModel(e) }}
                rowSelectionModel={rowSelectionModel}
            />

            <Modal open={visible} onClose={() => { setKindOfForMultipleUpdate(""); close(); }}>
                <Box sx={modalStyle}>
                    {/* <form onSubmit={() => updateSelectedItems()}> */}
                        <TextField sx={{ width: '100%', marginBottom: '20px' }}
                            id="kindOf"
                            label="종류"
                            name="kindOf"
                            select
                            variant="outlined"
                            required
                            InputLabelProps={{ shrink: true }}
                            value={kindOfForMultipleUpdate}
                            onChange={e => setKindOfForMultipleUpdate(e.target.value)}
                        >
                            <MenuItem value={"ChildHouse"}>어린이집</MenuItem>
                            <MenuItem value={"Kindergarten"}>유치원</MenuItem>
                            <MenuItem value={"Dementia"}>치매</MenuItem>
                            <MenuItem value={"IDisabled"}>지적장애</MenuItem>
                            <MenuItem value={"기타"}>기타</MenuItem>
                        </TextField>
                        <Button variant="contained" onClick={() => updateSelectedItems(kindOfForMultipleUpdate)}>확인</Button>
                    {/* </form> */}
                </Box>
            </Modal>
        </List>
    );
};

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  