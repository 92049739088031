import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from "styled-components";

function DateRangePicker({ onDateChange, handleTabClick }) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const formatDate = (date) => {
        if(date !== null) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        }
    }

    const handleDateChange = () => {
        // 날짜 변경 시 상태 업데이트
        handleTabClick("settingDate");
        onDateChange(formatDate(startDate), formatDate(endDate));
    };

    return (
        <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <StyledDatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="시작일"
                dateFormat="yyyy-MM-dd" // 원하는 날짜 형식으로 설정
            />
            <StyledSpacer>~</StyledSpacer>
            <StyledDatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="종료일"
                dateFormat="yyyy-MM-dd" // 원하는 날짜 형식으로 설정
                disabled={!startDate} // 시작일이 없으면 종료일을 비활성화
            />
            <SettingButton onClick={handleDateChange}>적용</SettingButton>
        </div>
    );
}

const StyledDatePicker = styled(DatePicker)`
  // DatePicker에 스타일을 추가합니다.
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 5px;
`;

const StyledSpacer = styled.div`
  height: 30px;
  line-height: 30px;
  margin: 0 10px;
  font-size: 30px;
  color: #555;
`;

const SettingButton = styled.button`
  margin-left: 20px;
  padding: 8px 16px;
  background-color: #d39793;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #c58e8a;
    color: rgba(255, 255, 255, 0.4);
  }
`;

export default DateRangePicker;
