import { HttpError } from "@refinedev/core";
import { Create, useAutocomplete } from "@refinedev/mui";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm } from "@refinedev/react-hook-form";

import { Controller } from "react-hook-form";

import { IpublicCenter, Nullable } from "./interfaces";
import React from "react";

export const PublicCenterCreate: React.FC = () => {
    const {
        saveButtonProps,
        register,
        control,
        formState: { errors },
    } = useForm<IpublicCenter, HttpError, Nullable<IpublicCenter>>();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    id="name"
                    {...register("name", {
                        required: "This field is required",
                    })}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    margin="normal"
                    fullWidth
                    label="이름"
                    name="name"
                    autoFocus
                    required
                    InputLabelProps={{ shrink: true }}
                />
                <Controller
                    control={control}
                    name="status"
                    rules={{ required: "This field is required" }}
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <Autocomplete
                            id="status"
                            options={["정상", "폐지"]}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="상태"
                                    margin="normal"
                                    variant="outlined"
                                    error={!!errors.status}
                                    helperText={errors.status?.message}
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    />
                            )}
                        />
                    )}
                />
                <TextField
                    id="address"
                    {...register("address", {
                        required: "This field is required",
                    })}
                    error={!!errors.address}
                    helperText={errors.address?.message}
                    margin="normal"
                    label="주소"
                    required
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
        </Create>
    );
};