import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridToolbar
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { ICall } from "./interfaces";
import { MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const CallList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<ICall>({
        initialCurrent: 1,
        initialPageSize: 20,
        sorters: {
            initial: [{ field: "id", order: "desc" }]
        }
    });

    const columns = React.useMemo<GridColDef<ICall>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 80,
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/calls/show/${params.row.id}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
         },
         { field: "in_out", headerName: "IN/OUT", type: "string", minWidth: 40, flex:1,},
         { field: "centerId", headerName: "시설ID", type: "string", minWidth: 40, flex:1,
            renderCell: (params: GridRenderCellParams) =>
            <Link to={`/centers/show/${params.row.centerId}`}
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}  
            </Link>
        },
        { field: "c_mamanger", headerName: "시설 관계자", type: "string", minWidth: 40, flex:1,},
        { field: "m_ph", headerName: "전화번호", type: "string", minWidth: 50, flex:1,},
        { field: "m_email", headerName: "이메일", type: "string", minWidth: 50, flex:1,},
        { field: "participation", headerName: "상태", type: "string", minWidth: 40, flex:1,},
        { field: "userId", headerName: "콜요원ID", type: "string", minWidth: 50, flex:1,
            renderCell: (params: GridRenderCellParams) =>
            <Link to={`/fisUsers/show/${params.row.userId}`}
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}  
            </Link>
        },
        { field: "date", headerName: "연락날짜", type: "string", minWidth: 80, flex: 1,},
        { field: "time", headerName: "연락시간", type: "string", minWidth: 80, flex: 1,},
        { field: "center_etc", headerName: "시설 메모", type: "string", minWidth:100, flex: 1},
        { field: "agent_etc", headerName: "현장요원 메모", type: "string", minWidth:100, flex: 1},
        { field: "updatedAt", headerName: "수정일", type: "string", minWidth: 80, flex: 1,},
    ],[]
    );

    return (
        <List breadcrumb={<></>}>
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value))}
                }
                />

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-in_out"
                label="IN/OUT"
                key={filters.find((filter: any) => filter.field === "in_out")?.value ?? "in_out"}   // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "in_out")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "in_out", "eq", e.target.value))}
            >
                        <MenuItem value={""}>전체</MenuItem>
                        <MenuItem value={"IN"}>IN</MenuItem>
                        <MenuItem value={"OUT"}>OUT</MenuItem>
                        <MenuItem value={"ILUVIT"}>ILUVIT</MenuItem>
            </TextField>

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-centerId"
                label="시설ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "centerId")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "centerId", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-m_email"
                label="이메일에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "m_email")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "m_email", "contains", e.target.value))}
                }
                />

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-participation"
                label="참여상태"
                key={filters.find((filter: any) => filter.field === "participation")?.value ?? "participation"}   // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "participation")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "participation", "eq", e.target.value))}
            >
                        <MenuItem value={""}>전체</MenuItem>
                        <MenuItem value={"PARTICIPATION"}>참여</MenuItem>
                        <MenuItem value={"COMPLETED"}>완료</MenuItem>
                        <MenuItem value={"HOLD"}>보류</MenuItem>
                        <MenuItem value={"ABSENCE"}>부재</MenuItem>
                        <MenuItem value={"REJECT"}>거절</MenuItem>
            </TextField>

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
            />
        </List>
    );
};
