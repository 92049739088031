import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import { Typography, Stack, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

export const ScheduleShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const navigate = useNavigate();

    const record = data?.data;
    const amazonS3url = "https://" + process.env.REACT_APP_AWS_S3_BUCKET_NAME + ".s3." + process.env.REACT_APP_AWS_REGION + ".amazonaws.com/";

    const imageStyle = {
        border: "1px solid #606060",
        width: "400px"
    };

    return (
        <Show 
            isLoading={isLoading}
            headerButtons={({ defaultButtons }) => (
                <>
                {defaultButtons}
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => {
                        navigate("/schedules/files/"+record?.id);
                    }}
                >
                    첨부파일 수정
                </Button>
                </>
            )}
            >
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    현장요원, 시설명
                </Typography>
                <TextField value={record?.agent?.name+", "+record?.center?.name} />
                <Typography variant="body1" fontWeight="bold">
                    접수일
                </Typography>
                <TextField value={record?.receipt_date} />
                <Typography variant="body1" fontWeight="bold">
                    현장방문 일정 상태
                </Typography>
                <TextField value={record?.complete === "incomplete" ? "배정" : record?.complete === "complete" ? "완료" : record?.complete === "confirm" ? "시설 수락" : record?.complete === "cancel" ? "취소" : "???"} />
                <Typography variant="body1" fontWeight="bold">
                    현장요원 일정 수락 여부
                </Typography>
                <TextField value={record?.accept === "WAITING" ? "수락대기" : record?.accept === "ACCEPT" ? "수락완료" : record?.accept === "REJECT" ? "거절" : "???"} />
                <Typography variant="body1" fontWeight="bold">
                    최근 통화 상태
                </Typography>
                <TextField value={record?.call_check+" "+record?.call_check_info} />
                <Typography variant="body1" fontWeight="bold">
                    변경사항
                </Typography>
                <TextField value={record?.modified_info} />
                <Typography variant="body1" fontWeight="bold">
                    예정방문날짜
                </Typography>
                <TextField value={record?.visitDate+" "+record?.visitTime} />
                <Typography variant="body1" fontWeight="bold">
                    실제방문날짜
                </Typography>
                <TextField value={record?.visitDateFinal+" "+record?.visitTimeFinal} />
                <Typography variant="body1" fontWeight="bold">
                    신규아동(예상, 최종)
                </Typography>
                <TextField value={record?.newChildrenEstimated+", "+record?.newChildrenFinal} />
                <Typography variant="body1" fontWeight="bold">
                    변경아동(예상, 최종)
                </Typography>
                <TextField value={record?.modChildrenEstimated+", "+record?.modChildrenFinal} />
                <Typography variant="body1" fontWeight="bold">
                    치매(예상, 최종)
                </Typography>
                <TextField value={record?.dementiaEstimated+", "+record?.dementiaFinal} />
                <Typography variant="body1" fontWeight="bold">
                    지적장애(예상, 최종)
                </Typography>
                <TextField value={record?.idisabledEstimated+", "+record?.idisabledFinal} />
                <Typography variant="body1" fontWeight="bold">
                    결석 최종
                </Typography>
                <TextField value={record?.absenceFinal} />
                <Typography variant="body1" fontWeight="bold">
                    결석관련 메모
                </Typography>
                <TextField value={record?.absenceComment} />
                <Typography variant="body1" fontWeight="bold">
                    시설 특이사항
                </Typography>
                <TextField value={record?.center_etc} />
                <Typography variant="body1" fontWeight="bold">
                    현장요원 특이사항
                </Typography>
                <TextField value={record?.agent_etc} />
                <Typography variant="body1" fontWeight="bold">
                    스케쥴 특이사항
                </Typography>
                <TextField value={record?.total_etc} />
                <Typography variant="body1" fontWeight="bold">
                    관리자 메모
                </Typography>
                <TextField value={record?.memo} />
                <Typography variant="body1" fontWeight="bold">
                    일정 취소 사유
                </Typography>
                <TextField value={record?.cancel_comment} />
                <Typography variant="body1" fontWeight="bold">
                    지각 사유
                </Typography>
                <TextField value={record?.late_comment} />
                <Typography variant="body1" fontWeight="bold">
                    서류발송여부
                </Typography>
                <TextField value={record?.documentSent} />
                {record?.attached1 &&
                    <a href={amazonS3url + record?.attached1} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached1} width={500} style={imageStyle} alt="image1" />
                    </a>
                }
                {record?.attached2 &&
                    <a href={amazonS3url + record?.attached2} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached2} width={500} style={imageStyle} alt="image2" />
                    </a>
                }
                {record?.attached3 &&
                    <a href={amazonS3url + record?.attached3} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached3} width={500} style={imageStyle} alt="image3" />
                    </a>
                }
                {record?.attached4 &&
                    <a href={amazonS3url + record?.attached4} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached4} width={500} style={imageStyle} alt="image4" />
                    </a>
                }
                {record?.attached5 &&
                    <a href={amazonS3url + record?.attached5} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached5} width={500} style={imageStyle} alt="image5" />
                    </a>
                }
                {record?.attached6 &&
                    <a href={amazonS3url + record?.attached6} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached6} width={500} style={imageStyle} alt="image6" />
                    </a>
                }
                {record?.attached7 &&
                    <a href={amazonS3url + record?.attached7} target="_blank" rel="noreferrer">
                        <img src={amazonS3url + record?.attached7} width={500} style={imageStyle} alt="image7" />
                    </a>
                }
            </Stack>
        </Show>
    );
};