import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

export const PtDateShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    설명회 날짜 시간
                </Typography>
                <TextField value={record?.date + "  " + record?.time} />
                <Typography variant="body1" fontWeight="bold">
                    허용인원
                </Typography>
                <TextField value={record?.ablePersonNum} />
                <Typography variant="body1" fontWeight="bold">
                    신청인원
                </Typography>
                <TextField value={record?.participantCnt} />
                <Typography variant="body1" fontWeight="bold">
                    대기인원
                </Typography>
                <TextField value={record?.waitingCnt} />
                <Typography variant="body1" fontWeight="bold">
                    버전
                </Typography>
                <TextField value={record?.version} />
                <Typography variant="body1" fontWeight="bold">
                    생성일
                </Typography>
                <TextField value={record?.createdDate} />
                <Typography variant="body1" fontWeight="bold">
                    수정일
                </Typography>
                <TextField value={record?.updatedDate} />
            </Stack>
        </Show>
    );
};