import React, { useState, useEffect } from "react";
import axios from "axios";

export const Footer: React.FC = () => {
  const [backendVer, setBackendVer] = useState("");
  const [DBStatus, setDBStatus] = useState("");

  useEffect(() => {
      const fetchExternalVersion = async () => {
          try {
              const response = await axios.get(`${process.env.REACT_APP_IP_ADDRESS}/actuator/info`);
              const response1 = await axios.get(`${process.env.REACT_APP_IP_ADDRESS}/actuator/env`);
              const backVersion = response.data.git.tags; // 예시, API 응답에 따라 수정 필요
              const dbProfile = response1.data.activeProfiles.pop();
              setBackendVer(backVersion);
              setDBStatus(dbProfile)
          } catch (error) {
              console.error('Error fetching external version:', error);
          }
      };

      fetchExternalVersion();
  }, []);

  const Version = process.env.REACT_APP_VERSION + ` / ${backendVer} / DB: ${DBStatus} `

  const appVersion = process.env.REACT_APP_VERSION && backendVer && DBStatus === undefined ? 'ver0.9.6' : Version; // 환경 변수가 없을 경우 기본값 'dev' 사용

  return (
      <div style={{ position: 'fixed', bottom: '10px', right: '10px', color: 'gray' }}>
          {appVersion}
      </div>
  );
};
