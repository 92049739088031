import { HttpError, useShow } from "@refinedev/core";
import { Edit, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Autocomplete, Grid } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";

import { Controller } from "react-hook-form";

import { IFisUser, Nullable } from "./interfaces";
import React from "react";

export const FisUserEdit: React.FC = () => {
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
    } = useForm<IFisUser, HttpError, Nullable<IFisUser>>();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField sx={{ width: '45%' }}
                    id="loginId"
                    {...register("loginId", {
                        required: false,
                    })}
                    error={!!errors.loginId}
                    helperText={errors.loginId?.message}
                    margin="normal"
                    fullWidth
                    label="로그인ID"
                    name="loginId"
                    disabled
                    InputLabelProps={{ shrink: true }}
                />

                <Grid container direction="row" justifyContent="space-between">
                <TextField sx={{ width: '45%' }}
                    id="name"
                    {...register("name", {
                        required: false,
                    })}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    margin="normal"
                    fullWidth
                    label="이름"
                    name="name"
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                />
                <Controller
                    control={control}
                    name="auth"
                    rules={{ required: false }}
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <Autocomplete sx={{ width: '45%' }}
                            id="권한"
                            options={["ADMIN", "MANAGER", "USER", "AGENT", "FIRED"]}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="상태"
                                    margin="normal"
                                    variant="outlined"
                                    error={!!errors.auth}
                                    helperText={errors.auth?.message}
                                    InputLabelProps={{ shrink: true }}
                                    />
                            )}
                        />
                    )}
                />
                </Grid>

                <Grid container direction="row" justifyContent="space-between">
                <TextField sx={{ width: '45%' }}
                    id="newPassword"
                    {...register("newPassword", {
                        required: false,
                    })}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    margin="normal"
                    fullWidth
                    label="비밀번호"
                    name="newPassword"
                    type="password"
                    defaultValue="******"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField sx={{ width: '45%' }}
                    id="passwordCheck"
                    {...register("passwordCheck", {
                        required: false,
                    })}
                    error={!!errors.passwordCheck}
                    helperText={errors.passwordCheck?.message}
                    margin="normal"
                    fullWidth
                    label="비밀번호 확인"
                    name="passwordCheck"
                    type="password"
                    InputLabelProps={{ shrink: true }}
                />
                </Grid>
                <TextField
                    id="phoneNumber"
                    {...register("phoneNumber", {
                        required: false,
                        onChange: (e) => {
                            const value = e.target.value.replace(/-/g, '');
                            let formattedPhone = ''

                            if (value.length < 4) {
                                formattedPhone = value
                            } else if (value.startsWith('02')) {
                                if (value.length < 7) {
                                    formattedPhone = `${value.slice(0, 2)}-${value.slice(2)}`
                                } else if (value.length < 10) {
                                    formattedPhone = `${value.slice(0, 2)}-${value.slice(2, 5)}-${value.slice(5)}`
                                } else {
                                    formattedPhone = `${value.slice(0, 2)}-${value.slice(2, 6)}-${value.slice(6, 10)}`
                                }
                            } else {
                                if (value.length < 8) {
                                    formattedPhone = `${value.slice(0, 3)}-${value.slice(3)}`
                                } else if (value.length < 11) {
                                    formattedPhone = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`
                                } else {
                                    formattedPhone = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7, 11)}`
                                }
                            }

                            const displayPhone = formattedPhone.length > 0 ? formattedPhone : ''
                            e.target.value = displayPhone;
                        }
                    })}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber?.message}
                    margin="normal"
                    label="전화번호"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    id="address"
                    {...register("address", {
                        required: false,
                    })}
                    error={!!errors.address}
                    helperText={errors.address?.message}
                    margin="normal"
                    label="주소"
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
        </Edit>
    );
};