import { DataProvider, HttpError } from "@refinedev/core";
import { axiosInstance, generateSort, generateFilter } from "functions";
import { AxiosInstance } from "axios";
import { stringify } from "query-string";
import { IAlarm } from "../interfaces";

type MethodTypes = "get" | "delete" | "head" | "options";
type MethodTypesWithBody = "post" | "put" | "patch";

export const alarmDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): Omit<
  Required<DataProvider>,
  "createMany" | "updateMany" | "deleteMany"
> => ({
  getList: async ({ resource, pagination, filters, sorters, meta }) => {
  
    var url = `${apiUrl}/${resource}`;

    const { current = 1, pageSize = 10, mode = "server" } = pagination ?? {};

    const { headers: headersFromMeta, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";

    const query: {
      page?: number;
      size?: number;
    } = {};

    if (mode === "server") {
      query.page = current - 1;
      query.size = pageSize;
    }

    const combinedQuery = { sorters: sorters, filters: filters, ...query };

    const urlWithQuery = `${url}/q?params=${encodeURIComponent(JSON.stringify(combinedQuery))}`;
    // console.log("urlWithQuery", urlWithQuery);

    var { data, headers } = await httpClient[requestMethod](urlWithQuery, {
      headers: headersFromMeta,
    });

    const total = +data.totalElements;
    data = data.content;

    return {
      data,
      total: total || data.length,
    };
  },

  getMany: async ({ resource, ids, meta }) => {
    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";

    const { data } = await httpClient[requestMethod](
      `${apiUrl}/${resource}?${stringify({ id: ids })}`,
      { headers }
    );

    return {
      data,
    };
  },

  create: async ({ resource, variables, meta }) => { console.log("create", resource, variables, meta);
    const url = `${apiUrl}/${resource}/send`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "post";

    const requestBody = variables as IAlarm;
    var userIdArray = [];
    var userGroup = requestBody.userGroup;
    
    if (requestBody.userIds)  userIdArray = requestBody.userIds.split(",").map((id) => id.trim());

    if (userGroup === null && userIdArray.length === 0) {
      const error: HttpError = {
        message: "At least one user must be selected",
        statusCode: 400,
      };
      return Promise.reject(error);
    }

    if (userGroup === null && userIdArray.length > 50) {
      const error: HttpError = {
        message: "Maximum 50 users can be selected",
        statusCode: 400,
      };
      return Promise.reject(error);
    }

    const { data } = await httpClient[requestMethod](url, variables, {
      headers,
    });

    var noTokens: string[] = [];
    var unknownUsers: string[] = [];

    data.forEach((element: any) => {
      if (element.status === "NO_TOKEN") {
        noTokens.push(element.userId);
      } else if (element.status === "UNKNOWN") {
        unknownUsers.push(element.userId);
      }
    });

    if (noTokens.length !== 0 || unknownUsers.length !== 0)  { console.log("noTokens", noTokens, "unknownUsers", unknownUsers);
      const error: HttpError = {
        message: "Some users have no tokens or are unknown:" + JSON.stringify(data),
        statusCode: 400,
      };
      return Promise.reject(error);
    }

    return {
      data,
    };
  },

  update: async ({ resource, id, variables, meta }) => { console.log("update", resource, id, variables, meta);
    const url = `${apiUrl}/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "patch";

    const { data } = await httpClient[requestMethod](url, variables, {
      headers,
    });

    return {
      data,
    };
  },

  getOne: async ({ resource, id, meta }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";

    const { data } = await httpClient[requestMethod](url, { headers });

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables, meta }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "delete";

    const { data } = await httpClient[requestMethod](url, {
      data: variables,
      headers,
    });

    return {
      data,
    };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({
    url,
    method,
    filters,
    sorters,
    payload,
    query,
    headers,
  }) => {
    let requestUrl = `${url}?`;

    if (sorters) {
      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(","),
          _order: _order.join(","),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload, {
          headers,
        });
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url, {
          data: payload,
          headers: headers,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl, {
          headers,
        });
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
