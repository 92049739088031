import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridToolbar
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { IParticipation } from "./interfaces";
import { MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const ParticipationList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<IParticipation>({
        initialCurrent: 1,
        initialPageSize: 20,
        sorters: {
            initial: [{ field: "id", order: "desc" }]
        }
    });

    const columns = React.useMemo<GridColDef<IParticipation>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 90 },
        { field: "status", headerName: "상태", minWidth: 100, flex: 1 },
        { field: "parentId", headerName: "학부모 ID", type: "string", minWidth: 30, flex:1,
            renderCell: (params: GridRenderCellParams) =>
            <Link to={`/parents/show/${params.row.parentId}`}
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}  
            </Link>
        },
        { field: "ptDateId", headerName: "신청한 설명회 ID", type: "string", minWidth:250, flex: 1,
            renderCell: (params: GridRenderCellParams) =>
            <Link to={`/ptDates/show/${params.row.ptDateId}`}
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}  
            </Link>
        },
        { field: "updatedDate", headerName: "수정일", type: "string", minWidth: 80, flex: 1,},
    ],[]
    );

    return (
        <List breadcrumb={<></>}>
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-place"
                label="장소에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "place")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "place", "contains", e.target.value))}
                }
                />

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
            />
        </List>
    );
};
