import React, {useEffect, useState} from "react";
import DailychartRegion from "./DailychartRegion";
import DailychartRegion2 from "./DailychartRegion2"
import styled from "styled-components";
import {useRecoilState} from "recoil";
import {isRegionState} from "./recoils/RegionStatus";
import DateRangePicker from "./components/DateRangePicker";
import DailychartRegion1 from "./DailychartRegion1";

//TODO
//지역별 데일리 통계를 위한 페이지
const StatusSelect = styled.select`
  
  padding: 5px 10px;
  font-size: 17px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #DB584E;

  &:hover {
    background-color: lightcoral;
  }
  
`;

export const Title = styled.h1`
    color: #d39797;
    font-size: 28px;
    text-align: center;
`;

const ButtonContainer = styled.div`
  width: inherit;
`;

const Box = styled.div`
    width: inherit;
    margin: 0 auto;
`;

function AdminDashboard() {
    const [activeTab, setActiveTab] = useState("lastweek");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [regionState, setRegionState] = useRecoilState(isRegionState)
    const [data, setData] = useState([]);

    const regionOptions = [
        "전국",
        "강원도",
        "경기도",
        "경상남도",
        "경상북도",
        "광주광역시",
        "대구광역시",
        "대전광역시",
        "부산광역시",
        "서울특별시",
        "세종특별자치시",
        "울산광역시",
        "인천광역시",
        "전라남도",
        "전라북도",
        "제주특별자치시",
        "충청남도",
        "충청북도",
    ];

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const handleStatusChange = (event) => {
        setRegionState(event.target.value);
    };

    const onDateChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
    }, [startDate, endDate]);


    const renderPage = () => {
        switch (activeTab) {
            case "lastweek":
                return <DailychartRegion1 />;
            case "month":
                return <DailychartRegion2  />;
            case "settingDate":
                return <DailychartRegion startDate={startDate} endDate={endDate} />;
            default:
                return <DailychartRegion1 />;
        }
    };

    return (
        <div className="flex flex-col wrap w-2/3" style={{width:"100%"}}>
            <Box>
                <StatusSelect value={regionState.region} onChange={handleStatusChange}>
                    {regionOptions.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </StatusSelect>
            </Box>

            <Title className="text-2xl font-bold">{regionState} 데일리통계</Title>
            <ButtonContainer className="tab container flex" style={{ paddingBottom:"30px"}}>
                <div className="flex justify-evenly w-96 mt-8">
                    <button
                        className={`tab-button ${
                            activeTab === "lastweek"
                                ? "active border-2 border-blue"
                                : "border border-black"
                        } w-28 p-1`}
                        style={{
                            alignItems: "center",
                            outline: "none",
                            border: "none",
                            borderRadius: "4px",
                            // color: "white",
                            fontWeight: "bold",
                            cursor: 'pointer',
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            height: "2.25rem",
                            fontSize: "1rem",
                            marginRight:"10px",
                            backgroundColor: activeTab === "lastweek" ? "#d39797" : "white",
                            color: activeTab === "lastweek" ? "white" : "black",
                        }}
                        onClick={() => handleTabClick("lastweek")}
                    >
                        지난 7일
                    </button>
                    <button
                        className={`tab-button ${
                            activeTab === "month"
                                ? "active border-2 border-blue"
                                : "border border-black"
                        } w-28 p-1`}
                        style={{
                            alignItems: "center",
                            outline: "none",
                            border: "none",
                            borderRadius: "4px",
                            fontWeight: "bold",
                            cursor: 'pointer',
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            height: "2.25rem",
                            fontSize: "1rem",
                            backgroundColor: activeTab === "month" ? "#d39797" : "white",
                            color: activeTab === "month" ? "white" : "black",
                        }}
                        onClick={() => handleTabClick("month")}
                    >
                        지난 30일
                    </button>
                    <div style={{marginTop:"20px"}}>
                        <DateRangePicker onDateChange={onDateChange} handleTabClick={handleTabClick}/>
                    </div>
                </div>
            </ButtonContainer>
            <div className="mt-8 h-[60vh] border-2 p-5">{renderPage()}</div>
        </div>
    );
}



export default AdminDashboard;