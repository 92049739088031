import React from "react";

export default function CustomButton(props) {
  const { value, className, onClick, text } = props;
  return (
    <button value={value} className={className} onClick={onClick}>
      {text}
    </button>
  );
}
