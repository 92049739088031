import { HttpError, useShow } from "@refinedev/core";
import { Edit, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Autocomplete, Grid, MenuItem } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";

import { Controller } from "react-hook-form";

import { IAgent, Nullable } from "./interfaces";
import React from "react";

export const AgentEdit: React.FC = () => {
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
        getValues,
        setValue,
    } = useForm<IAgent, HttpError, Nullable<IAgent>>();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <TextField sx={{ width: '45%' }}
                        id="loginId"
                        {...register("loginId", {
                            required: false,
                        })}
                        error={!!errors.loginId}
                        helperText={errors.loginId?.message}
                        margin="normal"
                        fullWidth
                        label="로그인ID"
                        name="loginId"
                        disabled
                        InputLabelProps={{ shrink: true }}
                    />

                    <TextField sx={{ width: '45%' }}
                        id="code"
                        {...register("code", {
                            required: false,
                        })}
                        error={!!errors.code}
                        helperText={errors.code?.message}
                        margin="normal"
                        fullWidth
                        label="코드"
                        name="code"
                        disabled
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <TextField sx={{ width: '45%' }}
                        id="name"
                        {...register("name", {
                            required: false,
                        })}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        margin="normal"
                        fullWidth
                        label="이름"
                        name="name"
                        autoFocus
                        InputLabelProps={{ shrink: true }}
                    />

                    <TextField sx={{ width: '45%' }}
                        id="status"
                        {...register("a_status", {
                            required: "This field is required",
                            onChange: (e) => { setValue("a_status", e.target.value, { shouldValidate: true }) }
                        })}
                        error={!!errors.a_status}
                        helperText={errors.a_status?.message}
                        label="상태"
                        name="a_status"
                        select
                        variant="outlined"
                        required
                        InputLabelProps={{ shrink: true }}
                        value={getValues("a_status") || "기타"}
                    >
                        <MenuItem value={"WORK"}>재직</MenuItem>
                        <MenuItem value={"FIRED"}>퇴사</MenuItem>
                    </TextField>

                </Grid>

                <Grid container direction="row" justifyContent="space-between">
                    <TextField sx={{ width: '45%' }}
                        id="newPassword"
                        {...register("newPassword", {
                            required: false,
                        })}
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        margin="normal"
                        fullWidth
                        label="비밀번호"
                        name="newPassword"
                        type="password"
                        defaultValue="******"
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField sx={{ width: '45%' }}
                        id="passwordCheck"
                        {...register("passwordCheck", {
                            required: false,
                        })}
                        error={!!errors.passwordCheck}
                        helperText={errors.passwordCheck?.message}
                        margin="normal"
                        fullWidth
                        label="비밀번호 확인"
                        name="passwordCheck"
                        type="password"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <TextField
                    id="phoneNumber"
                    {...register("phoneNumber", {
                        required: false,
                        onChange: (e) => {
                            const value = e.target.value.replace(/-/g, '');
                            let formattedPhone = ''

                            if (value.length < 4) {
                                formattedPhone = value
                            } else if (value.startsWith('02')) {
                                if (value.length < 7) {
                                    formattedPhone = `${value.slice(0, 2)}-${value.slice(2)}`
                                } else if (value.length < 10) {
                                    formattedPhone = `${value.slice(0, 2)}-${value.slice(2, 5)}-${value.slice(5)}`
                                } else {
                                    formattedPhone = `${value.slice(0, 2)}-${value.slice(2, 6)}-${value.slice(6, 10)}`
                                }
                            } else {
                                if (value.length < 8) {
                                    formattedPhone = `${value.slice(0, 3)}-${value.slice(3)}`
                                } else if (value.length < 11) {
                                    formattedPhone = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`
                                } else {
                                    formattedPhone = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7, 11)}`
                                }
                            }

                            const displayPhone = formattedPhone.length > 0 ? formattedPhone : ''
                            e.target.value = displayPhone;
                        }
                    })}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber?.message}
                    margin="normal"
                    label="전화번호"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    id="a_address"
                    {...register("a_address", {
                        required: false,
                    })}
                    error={!!errors.a_address}
                    helperText={errors.a_address?.message}
                    margin="normal"
                    label="주소"
                    InputLabelProps={{ shrink: true }}
                />

                <TextField sx={{ width: '45%' }}
                    id="a_comment"
                    {...register("a_comment", {
                        required: false,
                    })}
                    error={!!errors.a_comment}
                    helperText={errors.a_comment?.message}
                    margin="normal"
                    fullWidth
                    label="비고"
                    name="a_comment"
                    disabled
                    InputLabelProps={{ shrink: true }}
                />

                <TextField sx={{ width: '45%' }}
                    id="a_equipment"
                    {...register("a_equipment", {
                        required: false,
                    })}
                    error={!!errors.a_equipment}
                    helperText={errors.a_equipment?.message}
                    margin="normal"
                    fullWidth
                    label="장비번호"
                    name="a_equipment"
                    disabled
                    InputLabelProps={{ shrink: true }}
                />

                <TextField sx={{ width: '45%' }}
                    id="a_dispatchDate"
                    {...register("a_dispatchDate", {
                        required: false,
                    })}
                    error={!!errors.a_dispatchDate}
                    helperText={errors.a_dispatchDate?.message}
                    margin="normal"
                    fullWidth
                    label="장비발송일"
                    name="a_dispatchDate"
                    disabled
                    InputLabelProps={{ shrink: true }}
                />

                <TextField sx={{ width: '45%' }}
                    id="a_receiveDate"
                    {...register("a_receiveDate", {
                        required: false,
                    })}
                    error={!!errors.a_receiveDate}
                    helperText={errors.a_receiveDate?.message}
                    margin="normal"
                    fullWidth
                    label="장비수령일"
                    name="a_receiveDate"
                    disabled
                    InputLabelProps={{ shrink: true }}
                />

                <TextField sx={{ width: '45%' }}
                    id="a_picture"
                    {...register("a_picture", {
                        required: false,
                    })}
                    error={!!errors.a_picture}
                    helperText={errors.a_picture?.message}
                    margin="normal"
                    fullWidth
                    label="사진파일"
                    name="a_picture"
                    disabled
                    InputLabelProps={{ shrink: true }}
                />

                <TextField sx={{ width: '45%' }}
                    id="resume"
                    {...register("resume", {
                        required: false,
                    })}
                    error={!!errors.resume}
                    helperText={errors.resume?.message}
                    margin="normal"
                    fullWidth
                    label="이력서파일"
                    name="resume"
                    disabled
                    InputLabelProps={{ shrink: true }}
                />

                <TextField sx={{ width: '45%' }}
                    id="a_hasDocument1"
                    {...register("a_hasDocument1", {
                        required: false,
                    })}
                    error={!!errors.a_hasDocument1}
                    helperText={errors.a_hasDocument1?.message}
                    margin="normal"
                    fullWidth
                    label="첨부파일1"
                    name="a_hasDocument1"
                    disabled
                    InputLabelProps={{ shrink: true }}
                />

                <TextField sx={{ width: '45%' }}
                    id="a_hasDocument2"
                    {...register("a_hasDocument2", {
                        required: false,
                    })}
                    error={!!errors.a_hasDocument2}
                    helperText={errors.a_hasDocument2?.message}
                    margin="normal"
                    fullWidth
                    label="첨부파일2"
                    name="a_hasDocument2"
                    disabled
                    InputLabelProps={{ shrink: true }}
                />

                <TextField sx={{ width: '45%' }}
                    id="a_hasDocument3"
                    {...register("a_hasDocument3", {
                        required: false,
                    })}
                    error={!!errors.a_hasDocument3}
                    helperText={errors.a_hasDocument3?.message}
                    margin="normal"
                    fullWidth
                    label="첨부파일3"
                    name="a_hasDocument3"
                    disabled
                    InputLabelProps={{ shrink: true }}
                />

                <TextField sx={{ width: '45%' }}
                    id="a_hasDocument4"
                    {...register("a_hasDocument4", {
                        required: false,
                    })}
                    error={!!errors.a_hasDocument4}
                    helperText={errors.a_hasDocument4?.message}
                    margin="normal"
                    fullWidth
                    label="첨부파일4"
                    name="a_hasDocument4"
                    disabled
                    InputLabelProps={{ shrink: true }}
                />

                <TextField sx={{ width: '45%' }}
                    id="a_hasDocument5"
                    {...register("a_hasDocument5", {
                        required: false,
                    })}
                    error={!!errors.a_hasDocument5}
                    helperText={errors.a_hasDocument5?.message}
                    margin="normal"
                    fullWidth
                    label="첨부파일5"
                    name="a_hasDocument5"
                    disabled
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
        </Edit>
    );
};