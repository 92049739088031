import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridToolbar
} from "@mui/x-data-grid";

import { Link, useNavigate } from "react-router-dom";

import { ITeacher } from "./interfaces";
import { Button, MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const TeacherList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<ITeacher>({
        initialCurrent: 1,
        initialPageSize: 20,
    });

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const navigate = useNavigate();

    const columns = React.useMemo<GridColDef<ITeacher>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 80,
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/teachers/show/${params.row.id}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
         },
        { field: "loginId", headerName: "로그인ID", type: "string", width: 120 },
        { field: "name", headerName: "이름", minWidth: 100, flex: 1},
        { field: "nickName", headerName: "닉네임", minWidth: 150 },
        { 
            field: "auth", 
            headerName: "권한", 
            type: "string", 
            width: 100,
            renderCell: (params) => {
                const authValue = params.value;
                let auth = '';
        
                switch(authValue) {
                    case 'DIRECTOR':
                        auth = '시설 관리자';
                        break;
                    case 'TEACHER':
                        auth = '교사';
                        break;
                    default:
                        auth = authValue; // 만약 다른 값이라면 그대로 출력합니다.
                        break;
                }
        
                return <span>{auth}</span>;
            }
        },
        { field: "address", headerName: "주소", minWidth: 300, flex: 1,},
        { field: "location.sido", headerName: "시도", minWidth: 100, flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                return <span>{params.row.location?.sido}</span>;
            },
        },
        { field: "location.sigungu", headerName: "시군구", minWidth: 120, flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                return <span>{params.row.location?.sigungu}</span>;
            },
        },
        {
            field: "phoneNumber",
            headerName: "전화번호",
            minWidth: 120,
            flex: 1,
            renderCell: (params) => {
              const formattedPhoneNumber = params.value && params.value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
              return <span>{formattedPhoneNumber}</span>;
            },
        },
        { field: "centerName", headerName: "시설이름", minWidth: 140, flex: 1, 
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/centers/show/${params.row.centerId}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        { field: "approval", headerName: "승인상태", type: "string", minWidth: 70, flex: 1,
          renderCell: (params) => {
                const approvalValue = params.value;
                let approval = '';
        
                switch(approvalValue) {
                    case 'ACCEPT':
                        approval = '완료';
                        break;
                    case 'WAITING':
                        approval = '대기 중';
                        break;
                    case 'REJECT':
                        approval = '거절';
                        break;    
                    default:
                        approval = approvalValue; // 만약 다른 값이라면 그대로 출력합니다.
                        break;
                }
        
            return <span>{approval}</span>;
          }
        },
        { field: "updatedDate", headerName: "수정일", minWidth: 110, flex: 1, 
            renderCell: (params: GridRenderCellParams) => {
                const date = new Date(params.value);
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();

                const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
                const amPm = hours < 12 ? '오전' : '오후';
                const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
                const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

                const formattedDate = `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일 ${amPm} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
                return <span>{formattedDate}</span>;
        }}
    ],[]
    );
   
    return (
        <List 
            breadcrumb={<></>}
            headerButtons={({ defaultButtons }) => (
                <>
                {defaultButtons}
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => {
                        navigate("/alarms/create", { state: { ids: rowSelectionModel }});
                    }}
                >
                    푸시알림 전송
                </Button>
                </>
            )}
            key={Date.now()}
        >
            <TextField sx={{ width: '100px', marginRight: '30px', marginBottom: '20px'}}
                id="center-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-loginId"
                label="로그인ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "loginId")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "loginId", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-nickName"
                label="닉네임에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "nickName")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "nickName", "contains", e.target.value))}
                }
                />

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-auth"
                label="권한"
                key={filters.find((filter: any) => filter.field === "auth")?.value ?? ""} // unconditional select에서 defaultValue가 바뀌면 생기는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "auth")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "auth", "eq", e.target.value))}
            >
                    <MenuItem value={""}>전체</MenuItem>
                    <MenuItem value={"TEACHER"}>교사</MenuItem>
                    <MenuItem value={"DIRECTOR"}>시설관리자</MenuItem>
            </TextField>

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-address"
                label="주소에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "address")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "address", "contains", e.target.value))}
                }
                />

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-sido"
                label="시도 선택"
                key={filters.find((filter: any) => filter.field === "sido")?.value ?? "sido"}   // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "sido")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "sido", "eq", e.target.value))}
            >
                        <MenuItem value={""}>전체</MenuItem>
                        <MenuItem value={"서울특별시"}>서울특별시</MenuItem>
                        <MenuItem value={"부산광역시"}>부산광역시</MenuItem>
                        <MenuItem value={"대구광역시"}>대구광역시</MenuItem>
                        <MenuItem value={"인천광역시"}>인천광역시</MenuItem>
                        <MenuItem value={"광주광역시"}>광주광역시</MenuItem>
                        <MenuItem value={"대전광역시"}>대전광역시</MenuItem>
                        <MenuItem value={"울산광역시"}>울산광역시</MenuItem>
                        <MenuItem value={"세종특별자치시"}>세종특별자치시</MenuItem>
                        <MenuItem value={"경기도"}>경기도</MenuItem>
                        <MenuItem value={"충청북도"}>충청북도</MenuItem>
                        <MenuItem value={"충청남도"}>충청남도</MenuItem>
                        <MenuItem value={"전북특별자치도"}>전북특별자치도</MenuItem>
                        <MenuItem value={"전라남도"}>전라남도</MenuItem>
                        <MenuItem value={"경상북도"}>경상북도</MenuItem>
                        <MenuItem value={"경상남도"}>경상남도</MenuItem>
                        <MenuItem value={"제주특별자치도"}>제주특별자치도</MenuItem>
                        <MenuItem value={"강원특별자치도"}>강원특별자치도</MenuItem>
            </TextField>

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-phoneNumber"
                label="전화번호에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "phoneNumber")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "phoneNumber", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-centerName"
                label="시설이름으로 검색"
                defaultValue={filters.find((filter: any) => filter.field === "centerName")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "centerName", "contains", e.target.value))}
                }
                />

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
                checkboxSelection={true}
                onRowSelectionModelChange={(e: any) => { setRowSelectionModel(e) }}
                rowSelectionModel={rowSelectionModel}
            />
        </List>
    );
};
