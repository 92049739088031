import { HttpError } from "@refinedev/core";
import { Create, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Autocomplete, Grid, MenuItem } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";

import { Controller } from "react-hook-form";

import { IAgent, Nullable } from "./interfaces";
import React from "react";

export const AgentCreate: React.FC = () => {
    const {
        saveButtonProps,
        register,
        control,
        formState: { errors },
        getValues,
        setValue,
    } = useForm<IAgent, HttpError, Nullable<IAgent>>();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <Grid container direction="row" justifyContent="space-between">
                    <TextField sx={{ width: '45%' }}
                        id="loginId"
                        {...register("loginId", {
                            required: "This field is required",
                        })}
                        error={!!errors.loginId}
                        helperText={errors.loginId?.message}
                        margin="normal"
                        fullWidth
                        label="로그인ID"
                        name="loginId"
                        autoFocus
                        required
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField sx={{ width: '45%' }}
                        id="code"
                        {...register("code", {
                            required: "This field is required",
                        })}
                        error={!!errors.code}
                        helperText={errors.code?.message}
                        margin="normal"
                        fullWidth
                        label="코드"
                        name="code"
                        required
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <TextField sx={{ width: '45%' }}
                        id="name"
                        {...register("name", {
                            required: "This field is required",
                        })}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        margin="normal"
                        fullWidth
                        label="이름"
                        name="name"
                        required
                        InputLabelProps={{ shrink: true }}
                    />

                    <TextField sx={{ width: '45%' }}
                        id="a_hasCar"
                        {...register("a_hasCar", {
                            required: "This field is required",
                            onChange: (e) => { setValue("a_hasCar", e.target.value, { shouldValidate: true }) }
                        })}
                        error={!!errors.a_hasCar}
                        helperText={errors.a_hasCar?.message}
                        label="자차여부"
                        name="a_hasCar"
                        select
                        variant="outlined"
                        required
                        InputLabelProps={{ shrink: true }}
                        value={getValues("a_hasCar") || ""}
                    >
                        <MenuItem value={"WALK"}>도보</MenuItem>
                        <MenuItem value={"CAR"}>자차</MenuItem>
                    </TextField>

                </Grid>

                <Grid container direction="row" justifyContent="space-between">
                    <TextField sx={{ width: '45%' }}
                        id="newPassword"
                        {...register("newPassword", {
                            required: "This field is required",
                        })}
                        error={!!errors.newPassword}
                        helperText={errors.newPassword?.message}
                        margin="normal"
                        fullWidth
                        label="비밀번호"
                        name="newPassword"
                        type="password"
                        required
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField sx={{ width: '45%' }}
                        id="passwordCheck"
                        {...register("passwordCheck", {
                            required: "This field is required",
                        })}
                        error={!!errors.passwordCheck}
                        helperText={errors.passwordCheck?.message}
                        margin="normal"
                        fullWidth
                        label="비밀번호 확인"
                        name="passwordCheck"
                        type="password"
                        required
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                <Grid container direction="row" justifyContent="space-between">
                    <TextField sx={{ width: '45%' }}
                        id="phoneNumber"
                        {...register("phoneNumber", {
                            required: "This field is required",
                            onChange: (e) => {
                                const value = e.target.value.replace(/-/g, '');
                                let formattedPhone = ''

                                if (value.length < 4) {
                                    formattedPhone = value
                                } else if (value.startsWith('02')) {
                                    if (value.length < 7) {
                                        formattedPhone = `${value.slice(0, 2)}-${value.slice(2)}`
                                    } else if (value.length < 10) {
                                        formattedPhone = `${value.slice(0, 2)}-${value.slice(2, 5)}-${value.slice(5)}`
                                    } else {
                                        formattedPhone = `${value.slice(0, 2)}-${value.slice(2, 6)}-${value.slice(6, 10)}`
                                    }
                                } else {
                                    if (value.length < 8) {
                                        formattedPhone = `${value.slice(0, 3)}-${value.slice(3)}`
                                    } else if (value.length < 11) {
                                        formattedPhone = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`
                                    } else {
                                        formattedPhone = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7, 11)}`
                                    }
                                }

                                const displayPhone = formattedPhone.length > 0 ? formattedPhone : ''
                                e.target.value = displayPhone;
                            }
                        })}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber?.message}
                        margin="normal"
                        fullWidth
                        label="전화번호"
                        name="phoneNumber"
                        required
                        InputLabelProps={{ shrink: true }}
                    />

                    <TextField sx={{ width: '45%' }}
                        id="sDate"
                        {...register("sDate", {
                            required: false,
                            onChange: (e) => {
                                const value = e.target.value.replace(/-/g, '');
                                let formattedPhone = ''

                                if (value.length < 5) {
                                    formattedPhone = value
                                } else {
                                    if (value.length < 8) {
                                        formattedPhone = `${value.slice(0, 4)}-${value.slice(4)}`
                                    } else {
                                        formattedPhone = `${value.slice(0, 4)}-${value.slice(4, 6)}-${value.slice(6, 8)}`
                                    }
                                }

                                const displayPhone = formattedPhone.length > 0 ? formattedPhone : ''
                                e.target.value = displayPhone;
                            }
                        })}
                        error={!!errors.sDate}
                        helperText={errors.sDate?.message}
                        margin="normal"
                        fullWidth
                        label="입사일"
                        name="sDate"
                        placeholder="YYYY-MM-DD"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <TextField
                    id="a_address"
                    {...register("a_address", {
                        required: true,
                    })}
                    error={!!errors.a_address}
                    helperText={errors.a_address?.message}
                    margin="normal"
                    fullWidth
                    label="주소"
                    name="a_address"
                    required
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
        </Create>
    );
};