import axios from "axios";
import { axiosInstance } from "functions";

/*
		날짜: 2023/08/18 01:22 PM
		작성자: 임성식
		http method: GET
		URI: /report
		Content: 신고 데이터 목록 api
*/
export const reportFetchDataApi = async (setContents) => {
    const accessToken = sessionStorage.getItem("accessToken");
    await axiosInstance.get(`${process.env.REACT_APP_IP_ADDRESS}/report`,{
        headers: {
            Authorization: `${accessToken}`
        }
    }).then((res) => {
            setContents(res.data)
        })
        .catch((err) => {
            if (err.response.status === 401) {
                console.log(err);
            }
        });
}

/*
		날짜: 2023/08/18 01:22 PM
		작성자: 임성식
		http method: get
		URI: /report/${reportId}
		Content: (모달 창) 해당 신고 데이터 api
*/
export const modalReportFetchDataApi = async (reportId) => {
    const accessToken = sessionStorage.getItem("accessToken");
    try {
        const response = await axios.get(`${process.env.REACT_APP_IP_ADDRESS}/report/${reportId}`, {
            headers: {
                Authorization: `${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

/*
		날짜: 2023/08/18 01:22 PM
		작성자: 임성식
		http method: PATCH
		URI: /report/${reportId}
		Content: (모달 창) 해당 신고 데이터 관리 api
*/
export const onReportManageApi = async (e, reportId, data, error) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const clickId = e.target.id;
    if(clickId === "DEL") {
        const delAlert = window.confirm("삭제하시겠습니까?");
        if (delAlert === true) {
            window.alert("삭제되었습니다.")
            window.location.href = "/report"
            await axios.patch(`${process.env.REACT_APP_IP_ADDRESS}/report/${reportId}`, {
                status: "DELETE",
                type: data.reportType,
            }, {
                headers: {
                    Authorization: `${accessToken}`
                }
            })
                .catch(
                    error()
                )

        } else {
            window.alert("취소되었습니다.")
        }
    }else if (clickId === "KEEP") {
        const keepAlert = window.confirm("반려하시겠습니까?");
        if (keepAlert === true) {
            window.alert("반려되었습니다.")
            window.location.href = "/report"
            await axios.patch(`${process.env.REACT_APP_IP_ADDRESS}/report/${reportId}`, {
                status: "NOACTION",
                type: data.reportType,
            }, {
                headers: {
                    Authorization: `${accessToken}`
                }
            })
                .catch(
                    error()
                )
        } else {
            window.alert("취소되었습니다.")
        }
    }
}