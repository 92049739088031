import { HttpError, useShow } from "@refinedev/core";
import { Edit, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Autocomplete, Grid } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";

import { Controller } from "react-hook-form";

import { ITeacher, Nullable } from "./interfaces";
import React from "react";

import { useDaumPostcodePopup } from "react-daum-postcode";
import { axiosInstance } from "functions";

export const TeacherEdit: React.FC = () => {
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
        setValue
    } = useForm<ITeacher, HttpError, Nullable<ITeacher>>();

    const open = useDaumPostcodePopup();

    const handleCompleteDaum = (data: any) => {
        // console.log("daum", data)
        let fullAddress = data.address;
        let extraAddress = '';
        let sigunguCode = data.bcode.substring(0, 5);

        setValue("address", data.address)

        // 시군구 코드 조회
        // DB에 저장된 시군구 코드는 어린이집의 경우, 최신을 반영하고 있지 않다. 따라서 어린이집을 위한 시군구 코드는 제외시킨다.
        axiosInstance.get(`${process.env.REACT_APP_IP_ADDRESS}/regions/search/findBySigunguCodeAndKindOfNot?sigunguCode=${sigunguCode}&kindOf=ChildHouse`)
            .then((response) => {
                console.log("response", response);
                if (response.data._embedded.regions.length !== 1) {
                    alert("시군구 코드를 조회하는데 실패했습니다.1");
                    return;
                }
                setValue("location.sido", response.data._embedded.regions[0].sidoName);
                setValue("location.sigungu", response.data._embedded.regions[0].sigunguName);
            })
            .catch((error) => {
                alert("시군구 코드를 조회하는데 실패했습니다.2")
                console.log("error", error);
            });

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    };

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <Grid container direction="row" justifyContent="space-between">
                 <TextField sx={{ width: '45%' }}
                    id="loginId"
                    {...register("loginId", {
                        required: false,
                    })}
                    error={!!errors.loginId}
                    helperText={errors.loginId?.message}
                    margin="normal"
                    fullWidth
                    label="ID"
                    name="loginId"
                    autoFocus
                    disabled
                    InputLabelProps={{ shrink: true }}
                />
                 <TextField sx={{ width: '45%' }}
                    id="auth"
                    {...register("auth", {
                        required: false,
                    })}
                    error={!!errors.auth}
                    helperText={errors.auth?.message}
                    margin="normal"
                    fullWidth
                    label="권한"
                    name="auth"
                    autoFocus
                    disabled
                    InputLabelProps={{ shrink: true }}
                />
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                    <TextField sx={{ width: '45%' }}
                        id="name"
                        {...register("name", {
                            required: "This field is required",
                        })}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        margin="normal"
                        fullWidth
                        label="이름"
                        name="name"
                        autoFocus
                        required
                        InputLabelProps={{ shrink: true }}
                    />

                    <TextField sx={{ width: '45%' }}
                        id="nickName"
                        {...register("nickName", {
                            required: "This field is required",
                        })}
                        error={!!errors.nickName}
                        helperText={errors.nickName?.message}
                        margin="normal"
                        fullWidth
                        label="별명(닉네임)"
                        name="nickName"
                        autoFocus
                        required
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                <Grid container direction="row" justifyContent="space-between">
                    <TextField sx={{ width: '45%' }}
                        id="newPassword"
                        {...register("newPassword", {
                            required: false,
                        })}
                        error={!!errors.newPassword}
                        helperText={errors.newPassword?.message}
                        margin="normal"
                        fullWidth
                        label="비밀번호"
                        name="newPassword"
                        type="password"
                        defaultValue="******"
                        InputLabelProps={{ shrink: true }}
                    />

                    <TextField sx={{ width: '45%' }}
                        id="passwordCheck"
                        {...register("passwordCheck", {
                            required: false,
                        })}
                        error={!!errors.passwordCheck}
                        helperText={errors.passwordCheck?.message}
                        margin="normal"
                        fullWidth
                        label="비밀번호 확인"
                        name="passwordCheck"
                        type="password"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                <Grid container direction="row" justifyContent="space-between">
                    <TextField sx={{ width: '45%' }}
                        id="phoneNumber"
                        {...register("phoneNumber", {
                            required: "This field is required",
                            onChange: (e) => {
                                const value = e.target.value.replace(/-/g, '');
                                let formattedPhone = ''

                                if (value.length < 4) {
                                    formattedPhone = value
                                } else if (value.startsWith('02')) {
                                    if (value.length < 7) {
                                        formattedPhone = `${value.slice(0, 2)}-${value.slice(2)}`
                                    } else if (value.length < 10) {
                                        formattedPhone = `${value.slice(0, 2)}-${value.slice(2, 5)}-${value.slice(5)}`
                                    } else {
                                        formattedPhone = `${value.slice(0, 2)}-${value.slice(2, 6)}-${value.slice(6, 10)}`
                                    }
                                } else {
                                    if (value.length < 8) {
                                        formattedPhone = `${value.slice(0, 3)}-${value.slice(3)}`
                                    } else if (value.length < 11) {
                                        formattedPhone = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`
                                    } else {
                                        formattedPhone = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7, 11)}`
                                    }
                                }

                                const displayPhone = formattedPhone.length > 0 ? formattedPhone : ''
                                e.target.value = displayPhone;
                            }}
                        )}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber?.message}
                        margin="normal"
                        label="전화번호"
                        required
                        InputLabelProps={{ shrink: true }}
                    />

                    <TextField sx={{ width: '45%' }}
                        id="emailAddress"
                        {...register("emailAddress", {
                            required: "This field is required",
                        })}
                        error={!!errors.emailAddress}
                        helperText={errors.emailAddress?.message}
                        margin="normal"
                        label="이메일주소"
                        required
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                <TextField
                    id="address"
                    {...register("address", {
                        required: "This field is required",
                    })}
                    error={!!errors.address}
                    helperText={errors.address?.message}
                    margin="normal"
                    label="주소"
                    required
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => { open({ onComplete: handleCompleteDaum }) }}
                />
                <Grid container direction="row" justifyContent="space-between">
                    <TextField
                        id="detailAddress"
                        {...register("detailAddress", {
                            required: false,
                        })}
                        error={!!errors.detailAddress}
                        helperText={errors.detailAddress?.message}
                        margin="normal"
                        label="상세주소"
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        id="sido"
                        {...register("location.sido", {
                            required: false,
                        })}
                        margin="normal"
                        sx={{ width: "15%" }}
                        label="시도"
                        name="sido"
                        InputLabelProps={{ shrink: true }}
                        disabled={true}
                    />

                    <TextField
                        id="sigungu"
                        {...register("location.sigungu", {
                            required: false,
                        })}
                        margin="normal"
                        sx={{ width: "15%" }}
                        label="시군구"
                        name="sigungu"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        disabled={true}
                    />

                </Grid>

                <Controller
                    control={control}
                    name="approval"
                    rules={{ required: "This field is required" }}
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <Autocomplete
                            id="approval"
                            options={["ACCEPT", "WAITING"]}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="상태"
                                    margin="normal"
                                    variant="outlined"
                                    error={!!errors.approval}
                                    helperText={errors.approval?.message}
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    />
                            )}
                        />
                    )}
                />
            </Box>
        </Edit>
    );
};