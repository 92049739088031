import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridToolbar
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { IAgent } from "./interfaces";
import { MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const AgentList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<IAgent>({
        initialCurrent: 1,
        initialPageSize: 20,
    });

    const columns = React.useMemo<GridColDef<IAgent>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 80 },
        { field: "name", headerName: "이름", minWidth: 100, flex: 1,
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/agents/show/${params.row.id}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        { field: "loginId", headerName: "로그인ID", type: "string", width: 120 },
        {
            field: "phoneNumber",
            headerName: "전화번호",
            minWidth: 120,
            flex: 1,
            renderCell: (params) => {
                const phoneNumber = params.value;
                if (!phoneNumber) {
                    return null; // 값이 없을 경우 빈 셀을 반환합니다.
                }
                const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
                return <span>{formattedPhoneNumber}</span>;
            },
        },
        { field: "a_address", headerName: "주소", type: "string", minWidth:250, flex: 1},
        { field: "sDate", headerName: "입사일", type: "string", minWidth: 110, flex: 1,},
        { field: "createdAt", headerName: "생성일", type: "string", minWidth:150, flex: 1},
        { field: "updatedAt", headerName: "수정일", type: "string", minWidth:150, flex: 1},
        { 
            field: "actions", 
            headerName: "Actions", 
            width: 100, 
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div>
                        <EditButton hideText recordItemId={params.row.id} />
                    </div>
                )
            },
        },
    ],[]
    );

    return (
        <List breadcrumb={<></>}
            key={Date.now()}
        >
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-name"
                label="이름에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "name")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "name", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="center-search-loginId"
                label="로그인ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "loginId")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "loginId", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}

                id="center-search-phoneNumber"
                label="전화번호에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "phoneNumber")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "phoneNumber", "contains", e.target.value))}
                }
                />

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
            />
        </List>
    );
};
