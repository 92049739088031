import { List, useDataGrid, EditButton, ShowButton, RefreshButton } from "@refinedev/mui";
import { IResourceComponentsProps } from "@refinedev/core";
import { MuiListInferencer } from "@refinedev/inferencer/mui";
import TeacherAcceptTemplate from "./TeacherAcceptTemplate";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ApprovalList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();

  return (
    <List
      breadcrumb={<></>}
      headerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              navigate(0);
            }}
          >
            새로고침
          </Button>
        </>
      )}

    >

      <TeacherAcceptTemplate />
    </List>
  );
};
