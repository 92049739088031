import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";
import { useDeleteMany } from "@refinedev/core";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridToolbar
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { IExpense } from "./interfaces";
import { MenuItem, TextField, Button } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const ExpenseList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<IExpense>({
        initialCurrent: 1,
        initialPageSize: 20,
        sorters: {
            initial: [{ field: "id", order: "desc" }]
        }
    });

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const { mutate, isLoading: deleteManyIsLoading } = useDeleteMany<IExpense>();

    const deleteSelectedItems = () => {
        mutate(
            {
                resource: "expenses",
                ids: rowSelectionModel.map((id) => id.toString())
            },
            {
                onSuccess: () => {
                    setRowSelectionModel([]);
                }
            }   
        )
    }

    const columns = React.useMemo<GridColDef<IExpense>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 70,
            renderCell: (params: GridRenderCellParams) =>
            <Link to={`/expenses/show/${params.value}`}
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        { field: "agentName", headerName: "현장요원이름", minWidth: 100, flex: 1,
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/agents/show/${params.row.agentId}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        { field: "agentCode", headerName: "현장요원코드", type: "string", minWidth: 50, flex:1},
        { field: "hasCar", headerName: "자차여부", type: "string", minWidth: 50, flex:1},
        { field: "post", headerName: "우편료", type: "string", minWidth:70, flex: 1},
        { field: "gas", headerName: "연료비", type: "string", minWidth: 70, flex: 1,},
        { field: "parking", headerName: "주차비", type: "string", minWidth: 70, flex: 1,},
        { field: "toll", headerName: "톨비", type: "string", minWidth: 70, flex: 1,},
        { field: "etc", headerName: "기타", type: "string", minWidth: 70, flex: 1,},
        { field: "date", headerName: "방문일", type: "string", minWidth: 80, flex: 1,},
        { field: "comment", headerName: "비고", type: "string", minWidth: 150, flex: 1,},
        { field: "updatedAt", headerName: "수정일", type: "string", minWidth: 80, flex: 1,},
    ],[]
    );

    const hasSelected = rowSelectionModel.length > 0;

    return (
        <List 
            breadcrumb={<></>}
            headerButtons={({ defaultButtons }) => (
                <>
                {defaultButtons}
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={deleteSelectedItems}
                    disabled={!hasSelected}
                >
                    선택 삭제하기
                </Button>
                </>
            )}
        >
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-agentName"
                label="현장요원이름에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "agentName")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "agentName", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-agentCode"
                label="현장요원코드에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "agentCode")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "agentCode", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                type="date"
                id="alarm-search-date"
                label="방문일에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "date")?.value ?? ""}
                variant="outlined"
                size="small"
                onChange={(e: any) => {
                    console.log("e", e.target.value);
                    setFilters(updateFilters(filters, "date", "eq", e.target.value));
                }}
                InputLabelProps={{ shrink: true }}
                />

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
                checkboxSelection={true}
                onRowSelectionModelChange={(e: any) => { setRowSelectionModel(e) }}
                rowSelectionModel={rowSelectionModel}
            />
        </List>
    );
};
