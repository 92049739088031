import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { Link } from "react-router-dom";

export const CenterShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    Id
                </Typography>
                <NumberField value={record?.id ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    이름
                </Typography>
                <TextField value={record?.name} />
                <Typography variant="body1" fontWeight="bold">
                    주소
                </Typography>
                <TextField value={record?.address + " (" + record?.latitude + ", " + record?.longitude + ")"} />
                <Typography variant="body1" fontWeight="bold">
                    종류
                </Typography>
                <TextField value={record?.kindOf + " (" + record?.estType + ")"} />
                <Typography variant="body1" fontWeight="bold">
                    상태
                </Typography>
                <TextField value={record?.status} />
                <Typography variant="body1" fontWeight="bold">
                    현재인원
                </Typography>
                <TextField value={record?.status} />
                <Typography variant="body1" fontWeight="bold">
                    고유코드
                </Typography>
                <TextField value={record?.kindOf === 'ChildHouse' ? record?.childHouseCode : record?.kindOf === 'Kindergarten' ? record?.kindergartenCode : ''} />
                <Typography variant="body1" fontWeight="bold">
                    수정일
                </Typography>
                <DateField value={record?.updatedDate} />
                <Typography variant="body1" fontWeight="bold">
                    소속 교사 목록
                </Typography>
                <Link to={`/teachers?filters[0][field]=centerId&filters[0][operator]=eq&filters[0][value]=${record?.id}`}>바로가기</Link>
                <Typography variant="body1" fontWeight="bold">
                    등록 아이 목록
                </Typography>
                <Link to={`/children?filters[0][field]=centerId&filters[0][operator]=eq&filters[0][value]=${record?.id}`}>바로가기</Link>
                <Typography variant="body1" fontWeight="bold">
                    첨부이미지
                </Typography>
                <Link to={`/images?filters[0][field]=targetId&filters[0][operator]=eq&filters[0][value]=${record?.id}&filters[1][field]=category&filters[1][operator]=eq&filters[1][value]=CENTER`}>바로가기</Link>
            </Stack>
        </Show>
    );
};