import { DataProvider, HttpError } from "@refinedev/core";
import { axiosInstance, generateSort, generateFilter } from "functions";
import axios from "axios";
import { AxiosInstance } from "axios";
import { stringify } from "query-string";
import { ICenter } from "pages/centers/interfaces";

type MethodTypes = "get" | "delete" | "head" | "options";
type MethodTypesWithBody = "post" | "put" | "patch";

export const centerDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): Omit<
  Required<DataProvider>,
  "createMany" | "deleteMany"
> => ({
  getList: async ({ resource, pagination, filters, sorters, meta }) => {

    var url = `${apiUrl}/${resource}`;

    const { current = 1, pageSize = 10, mode = "server" } = pagination ?? {};

    const { headers: headersFromMeta, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";

    const query: {
      page?: number;
      size?: number;
    } = {};

    if (mode === "server") {
      query.page = current - 1;
      query.size = pageSize;
    }

    const combinedQuery = { sorters: sorters, filters: filters, ...query };

    const urlWithQuery = `${url}/q?params=${encodeURIComponent(JSON.stringify(combinedQuery))}`;
    // console.log("urlWithQuery", urlWithQuery);

    var { data, headers } = await httpClient[requestMethod](urlWithQuery, {
      headers: headersFromMeta,
    });

    const total = +data.totalElements;
    data = data.content;

    return {
      data,
      total: total || data.length,
    };
  },

  getMany: async ({ resource, ids, meta }) => {
    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";

    const { data } = await httpClient[requestMethod](
      `${apiUrl}/${resource}?${stringify({ id: ids })}`,
      { headers }
    );

    return {
      data,
    };
  },

  create: async ({ resource, variables, meta }) => {
    // console.log("create", resource, variables, meta);

    const url = `${apiUrl}/${resource}`;
    var centerData = variables as ICenter

    const filters = [
      {
        field: "name",
        operator: "eq",
        value: centerData.name,
      },
      {
        field: "sido",
        operator: "contains",
        value: centerData.sido,
      },
      {
        field: "sigungu",
        operator: "contains",
        value: centerData.sigungu,
      },
    ];

    const combinedQuery = { sorters: [], filters: filters, page: 0, size: 20 };

    const urlWithQuery = `${url}/q?params=${encodeURIComponent(JSON.stringify(combinedQuery))}`;

    var { data: qData, headers: qHeaders } = await httpClient["get"](urlWithQuery, {
    });

    const total = +qData.totalElements;

    if (total > 0) {
      const error: HttpError = {
        message: "이미 등록되어 있는 시설입니다.",
        statusCode: 400,
      };
      return Promise.reject(error);
    }

    const address = centerData.address + " " + centerData.addressDetail;

    const response: any = await axios.get("https://dapi.kakao.com/v2/local/search/address.json?query=" + address, {
      headers: {
        Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`,
      },
    });

    if (response.data.documents.length === 0) {
      const error: HttpError = {
        message: "주소로부터 위경도를 찾을 수 없습니다.",
        statusCode: 400,
      };
      return Promise.reject(error);
    }

    centerData.longitude = response.data.documents[0].x;
    centerData.latitude = response.data.documents[0].y;
    if (centerData.kindOf === "유치원") centerData.kindOf = "Kindergarten";
    else if (centerData.kindOf === "어린이집") centerData.kindOf = "ChildHouse";
    else if (centerData.kindOf === "기타") {
      centerData.kindOf = null as any;
      centerData.estType = null as any;
    }

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "post";

    const { data } = await httpClient[requestMethod](url, centerData, {
      headers,
    });

    return {
      data,
    };
  },

  update: async ({ resource, id, variables, meta }) => {
    // console.log("update", resource, id, variables, meta);
    const url = `${apiUrl}/${resource}`;

    var centerData = variables as ICenter

    const filters = [
      {
        field: "id",
        operator: "ne",
        value: id,
      },
      {
        field: "name",
        operator: "contains",
        value: centerData.name,
      },
      {
        field: "sido",
        operator: "contains",
        value: centerData.sido,
      },
      {
        field: "sigungu",
        operator: "contains",
        value: centerData.sigungu,
      },
    ];

    const combinedQuery = { sorters: [], filters: filters, page: 0, size: 20 };

    const urlWithQuery = `${url}/q?params=${encodeURIComponent(JSON.stringify(combinedQuery))}`;

    var { data: qData, headers: qHeaders } = await httpClient["get"](urlWithQuery, {
    });

    const total = +qData.totalElements;

    if (total > 0) {
      const error: HttpError = {
        message: "이미 등록되어 있는 시설입니다.",
        statusCode: 400,
      };
      return Promise.reject(error);
    }

    const address = centerData.address;

    const response: any = await axios.get("https://dapi.kakao.com/v2/local/search/address.json?query=" + address, {
      headers: {
        Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`,
      },
    });

    if (response.data.documents.length === 0) {
      const error: HttpError = {
        message: "주소로부터 위경도를 찾을 수 없습니다.",
        statusCode: 400,
      };
      return Promise.reject(error);
    }

    centerData.longitude = response.data.documents[0].x;
    centerData.latitude = response.data.documents[0].y;
    if (centerData.kindOf === "유치원") centerData.kindOf = "Kindergarten";
    else if (centerData.kindOf === "어린이집") centerData.kindOf = "ChildHouse";
    else if (centerData.kindOf === "기타") {
      centerData.kindOf = null as any;
      centerData.estType = null as any;
    }

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "patch";

    const { data } = await httpClient[requestMethod](url + `/${id}`, centerData, {
      headers,
    });

    return {
      data,
    };
  },

  updateMany: async ({ resource, ids, variables, meta }) => {
    // console.log("updateMany", resource, ids, variables, meta);
    const url = `${apiUrl}/${resource}`;

    var centerData = variables as ICenter

    if (centerData.kindOf === "유치원") centerData.kindOf = "Kindergarten";
    else if (centerData.kindOf === "어린이집") centerData.kindOf = "ChildHouse";
    else if (centerData.kindOf === "치매") centerData.kindOf = "Dementia";
    else if (centerData.kindOf === "지적장애") centerData.kindOf = "IDisabled";
    else if (centerData.kindOf === "기타") {
      centerData.kindOf = null as any;
      centerData.estType = null as any;
    }

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "patch";

    let data: any;

    try {
        const res = await Promise.all(ids.map(id => httpClient[requestMethod](url + `/` + id, centerData, {
          headers,
        })));
        data = res;
    } catch (err) {
      const error: HttpError = {
        message: "데이터 변경에 실패했습니다.",
        statusCode: 400,
      };
      return Promise.reject(error);
    }

    return {
      data,
    };
  },

  getOne: async ({ resource, id, meta }) => {
    // console.log("getOne", resource, id, meta);
    const url = `${apiUrl}/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";

    const { data } = await httpClient[requestMethod](url, { headers });

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables, meta }) => {
    // console.log("deleteOne", resource, id, variables, meta);
    const url = `${apiUrl}/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "delete";

    const { data } = await httpClient[requestMethod](url, {
      data: variables,
      headers,
    });

    return {
      data,
    };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({
    url,
    method,
    filters,
    sorters,
    payload,
    query,
    headers,
  }) => {
    let requestUrl = `${url}?`;

    if (sorters) {
      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(","),
          _order: _order.join(","),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload, {
          headers,
        });
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url, {
          data: payload,
          headers: headers,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl, {
          headers,
        });
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
