import { Authenticated, GitHubBanner, Refine, CanAccess, useCan } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  ErrorComponent,
  useNotificationProvider,
  RefineSnackbarProvider,
  ThemedLayoutV2,
  ThemedSiderV2,
  ThemedTitleV2,
} from "@refinedev/mui";

import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import { Register } from "pages/register";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./authProvider";
import { accessControlProvider } from "accessControlProvider";
import { Header } from "./components/header";
import { Footer } from "components/footer";
import { ColorModeContextProvider } from "./contexts/color-mode";

import { CenterList, CenterShow, CenterEdit, CenterCreate } from "pages/centers";
import { PublicCenterList, PublicCenterShow, PublicCenterCreate, PublicCenterEdit } from "pages/publicCenters";
import './i18n';
import { ComparisonCenterList, ComparisonCenterShow } from "pages/compareCenters";
import { UserList, UserShow } from "pages/users";
import { TeacherCreate, TeacherEdit, TeacherList, TeacherShow } from "pages/teachers";
import { ParentEdit, ParentList, ParentShow } from "pages/parents";
import { BlackUserCreate, BlackUserEdit, BlackUserList, BlackUserShow } from "pages/blackUsers";
import { BlockUserCreate, BlockUserEdit, BlockUserList, BlockUserShow } from "pages/blockUsers";
import { ChildCreate, ChildEdit, ChildList, ChildShow } from "pages/children";
import { FisUserCreate, FisUserEdit, FisUserList, FisUserShow } from "pages/fisUsers";
import { AgentCreate, AgentEdit, AgentList, AgentShow } from "pages/agents";
import { AlarmCreate, AlarmEdit, AlarmList, AlarmShow } from "pages/alarms";
import { ExpoTokenCreate, ExpoTokenEdit, ExpoTokenList, ExpoTokenShow } from "pages/expoTokens";
import { BookmarkCreate, BookmarkEdit, BookmarkList, BookmarkShow } from "pages/bookmarks";
import { PreferCreate, PreferEdit, PreferList, PreferShow } from "pages/prefer";
import { BoardCreate, BoardEdit, BoardList, BoardShow } from "pages/boards";
import { PostCreate, PostEdit, PostList, PostShow } from "pages/posts";
import { ImageCreate, ImageEdit, ImageList, ImageShow } from "pages/images";  
import { PresentationCreate, PresentationEdit, PresentationList, PresentationShow } from "pages/presentations";
import { PtDateCreate, PtDateEdit, PtDateList, PtDateShow } from "pages/ptDates";
import { ParticipationCreate, ParticipationEdit, ParticipationList, ParticipationShow } from "pages/participations";
import { ExpenseCreate, ExpenseEdit, ExpenseList, ExpenseShow, ExpenseFiles } from "pages/expenses";
import { ScheduleCreate, ScheduleEdit, ScheduleList, ScheduleShow, ScheduleFiles } from "pages/schedules";
import { CallCreate, CallEdit, CallList, CallShow } from "pages/calls";

import { ApprovalList } from "pages/approval";
import { Statistics } from "pages/statistics";
import { RecoilRoot } from "recoil";
import { ReportManageList } from "pages/reports";

import { centerDataProvider } from "pages/centers/data-provider";
import { publicCenterDataProvider } from "pages/publicCenters/data-provider";
import { userDataProvider } from "pages/users/data-provider";
import { parentDataProvider } from "pages/parents/data-provider";
import { teacherDataProvider } from "pages/teachers/data-provider";
import { childDataProvider } from "pages/children/data-provider";
import { blackUserDataProvider } from "pages/blackUsers/data-provider";
import { blockUserDataProvider } from "pages/blockUsers/data-provider";
import { fisUserDataProvider } from "pages/fisUsers/data-provider";
import { agentDataProvider } from "pages/agents/data-provider";
import { centerCompareDataProvider } from "pages/compareCenters/data-provider";
import { alarmDataProvider } from "pages/alarms/data-provider";
import { expoTokenDataProvider } from "pages/expoTokens/data-provider";
import { bookmarkDataProvider } from "pages/bookmarks/data-provider";
import { preferDataProvider } from "pages/prefer/data-provider";
import { boardDataProvider } from "pages/boards/data-provider";
import { postDataProvider } from "pages/posts/data-provider";
import { imageDataProvider } from "pages/images/data-provider";
import { presentationDataProvider } from "pages/presentations/data-provider";
import { ptDateDataProvider } from "pages/ptDates/data-provider";
import { participationDataProvider } from "pages/participations/data-provider";
import { expenseDataProvider } from "pages/expenses/data-provider";
import { scheduleDataProvider } from "pages/schedules/data-provider";
import { callDataProvider } from "pages/calls/data-provider";

import { ReactComponent as IluvitIcon } from "assets/images/iluvit_logo_24.svg";

import './App.css';

const customTitleHandler = () => {
  let title = "iLuvit 관리자 서비스";

  return title;
}

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <RecoilRoot>
      <BrowserRouter>
        <RefineKbarProvider>
          <ColorModeContextProvider>
            <CssBaseline />
            <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
            <RefineSnackbarProvider>
              <Refine
                dataProvider={{
                  default: dataProvider("https://api.fake-rest.refine.dev"),
                  centers: centerDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  publicCenters: publicCenterDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  compareCenter: centerCompareDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  parents: parentDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  users: userDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  teachers: teacherDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  blackUsers: blackUserDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  blockUsers: blockUserDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  children2: childDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`), // children을 사용하면 에러가 발생함.
                  fisUsers: fisUserDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  agents: agentDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  alarms: alarmDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  expoTokens: expoTokenDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  bookmarks: bookmarkDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  prefers: preferDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  boards: boardDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  posts: postDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  images: imageDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  presentations: presentationDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  ptDates: ptDateDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  participations: participationDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  expenses: expenseDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  schedules: scheduleDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                  calls: callDataProvider(`${process.env.REACT_APP_IP_ADDRESS}`),
                }}
                notificationProvider={useNotificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                accessControlProvider={accessControlProvider}
                resources={[
                  {
                    name: "statistics",
                    list: "/statistics",
                    meta: {
                      label: "통계",
                    },
                  },
                  {
                    name: "approval",
                    list: "/approval",
                    meta: {
                      label: "승인 목록",
                    },
                  },
                  {
                    name: "reports",
                    list: "/reports",
                    meta: {
                      label: "게시글 신고 목록",
                    },
                  },
                  {
                    name: "Centers", // name에 의해서 dataProvider resource가 결정됨.
                    meta: {
                      label: "시설 관련",
                    },
                  },
                  {
                    name: "centers",
                    list: "/centers",
                    create: "/centers/create",
                    edit: "/centers/edit/:id",
                    show: "/centers/show/:id",
                    meta: {
                      parent: "Centers",
                      label: "아이러빗 시설",
                      canDelete: true,
                      dataProviderName: "centers",
                    },
                  },
                  {
                    name: "publicCenters", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/publicCenters", // list에 할당된 경로로 페이지 이동.
                    create: "/publicCenters/create",
                    edit: "/publicCenters/edit/:id",
                    show: "/publicCenters/show/:id",
                    meta: {
                      parent: "Centers",
                      label: "공공시설 시설",
                      canDelete: true,
                      dataProviderName: "publicCenters",
                    },
                  },
                  {
                    name: "users",
                    show: "/users/show/:id",
                    meta: {
                      label: "아이러빗 사용자",
                      dataProviderName: "users",
                    },
                  },
                  {
                    name: "parents",
                    list: "/parents",
                    edit: "/parents/edit/:id",
                    show: "/parents/show/:id",
                    meta: {
                      parent: "users",
                      label: "학부모",
                      canDelete: false,
                      dataProviderName: "parents",
                    },
                  },
                  {
                    name: "teachers",
                    list: "/teachers",
                    // create: "/teachers/create",
                    edit: "/teachers/edit/:id",
                    show: "/teachers/show/:id",
                    meta: {
                      parent: "users",
                      label: "교사",
                      canDelete: false,
                      dataProviderName: "teachers",
                    },
                  },
                  {
                    name: "blackUsers",
                    list: "/blackUsers",
                    // create: "/blackUsers/create",
                    edit: "/blackUsers/edit/:id",
                    show: "/blackUsers/show/:id",
                    meta: {
                      parent: "users",
                      label: "중지된 사용자",
                      canDelete: false,
                      dataProviderName: "blackUsers",
                    },
                  },
                  {
                    name: "blockUsers",
                    list: "/blockUsers",
                    // create: "/blockUsers/create",
                    edit: "/blockUsers/edit/:id",
                    show: "/blockUsers/show/:id",
                    meta: {
                      parent: "users",
                      label: "차단된 사용자",
                      canDelete: false,
                      dataProviderName: "blockUsers",
                    },
                  },
                  {
                    name: "children",
                    list: "/children",
                    // create: "/children/create",
                    edit: "/children/edit/:id",
                    show: "/children/show/:id",
                    meta: {
                      label: "아동 목록",
                      canDelete: false,
                      dataProviderName: "children2",
                    },
                  },
                  {
                    name: "All_Boards",
                    meta: {
                      label: "게시판 관련",
                    },
                  },
                  {
                    name: "boards", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/boards", // list에 할당된 경로로 페이지 이동.
                    // create: "/boards/create",
                    // edit: "/boards/edit/:id",
                    show: "/boards/show/:id",
                    meta: {
                      parent: "All_Boards",
                      label: "게시판",
                      dataProviderName: "boards",
                    },
                  },
                  {
                    name: "posts", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/posts", // list에 할당된 경로로 페이지 이동.
                    // create: "/posts/create",
                    // edit: "/posts/edit/:id",
                    show: "/posts/show/:id",
                    meta: {
                      parent: "All_Boards",
                      label: "게시글",
                      dataProviderName: "posts",
                    },
                  },
                  {
                    name: "All_Bookmarks",
                    meta: {
                      label: "북마크 관련",
                    },
                  },
                  {
                    name: "bookmarks", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/bookmarks", // list에 할당된 경로로 페이지 이동.
                    // create: "/bookmarks/create",
                    // edit: "/bookmarks/edit/:id",
                    show: "/bookmarks/show/:id",
                    meta: {
                      parent: "All_Bookmarks",
                      label: "이야기 북마크",
                      dataProviderName: "bookmarks",
                    },
                  },
                  {
                    name: "prefers", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/prefers", // list에 할당된 경로로 페이지 이동.
                    // create: "/prefers/create",
                    // edit: "/prefers/edit/:id",
                    show: "/prefers/show/:id",
                    meta: {
                      parent: "All_Bookmarks",
                      label: "시설 북마크",
                      dataProviderName: "prefers",
                    },
                  },
                  {
                    name: "Notifications",
                    meta: {
                      label: "알림 관련",
                    },
                  },
                  {
                    name: "alarms", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/alarms", // list에 할당된 경로로 페이지 이동.
                    create: "/alarms/create",
                    // edit: "/alarms/edit/:id",
                    show: "/alarms/show/:id",
                    meta: {
                      parent: "Notifications",
                      label: "푸시알림",
                      dataProviderName: "alarms",
                    },
                  },
                  {
                    name: "expoTokens", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/expoTokens", // list에 할당된 경로로 페이지 이동.
                    // create: "/expoTokens/create",
                    // edit: "/expoTokens/edit/:id",
                    show: "/expoTokens/show/:id",
                    meta: {
                      parent: "Notifications",
                      label: "ExpoToken",
                      dataProviderName: "expoTokens",
                    },
                  },
                  {
                    name: "Presentations",
                    meta: {
                      label: "설명회 관련",
                    },
                  },
                  {
                    name: "presentations", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/presentations", // list에 할당된 경로로 페이지 이동.
                    // create: "/presentations/create",
                    // edit: "/presentations/edit/:id",
                    show: "/presentations/show/:id",
                    meta: {
                      parent: "Presentations",
                      label: "설명회 공고",
                      dataProviderName: "presentations",
                    },
                  },
                  {
                    name: "ptDates", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/ptDates", // list에 할당된 경로로 페이지 이동.
                    // create: "/ptDates/create",
                    // edit: "/ptDates/edit/:id",
                    show: "/ptDates/show/:id",
                    meta: {
                      parent: "Presentations",
                      label: "설명회 날짜",
                      dataProviderName: "ptDates",
                    },
                  },
                  {
                    name: "participations", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/participations", // list에 할당된 경로로 페이지 이동.
                    // create: "/participations/create",
                    // edit: "/participations/edit/:id",
                    show: "/participations/show/:id",
                    meta: {
                      parent: "Presentations",
                      label: "설명회 신청",
                      dataProviderName: "participations",
                    },
                  },
                  {
                    name: "FIS_Users",
                    meta: {
                      label: "FIS 유저",
                    },
                  },
                  {
                    name: "fisUsers",
                    list: "/fisUsers",
                    create: "/fisUsers/create",
                    edit: "/fisUsers/edit/:id",
                    show: "/fisUsers/show/:id",
                    meta: {
                      parent: "FIS_Users",
                      label: "모든 직원",
                      canDelete: false,
                      dataProviderName: "fisUsers",
                    },
                  },
                  {
                    name: "agents",
                    list: "/agents",
                    create: "/agents/create",
                    edit: "/agents/edit/:id",
                    show: "/agents/show/:id",
                    meta: {
                      parent: "FIS_Users",
                      label: "현장요원",
                      canDelete: false,
                      dataProviderName: "agents",
                    },
                  },
                  {
                    name: "calls", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/calls", // list에 할당된 경로로 페이지 이동.
                    // create: "/schedules/create",
                    edit: "/calls/edit/:id",
                    show: "/calls/show/:id",
                    meta: {
                      label: "시설연락",
                      canDelete: true,
                      dataProviderName: "calls",
                    },
                  },
                  {
                    name: "schedules", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/schedules", // list에 할당된 경로로 페이지 이동.
                    // create: "/schedules/create",
                    edit: "/schedules/edit/:id",
                    show: "/schedules/show/:id",
                    meta: {
                      label: "현장요원방문일정",
                      canDelete: true,
                      dataProviderName: "schedules",
                    },
                  },
                  {
                    name: "expenses", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/expenses", // list에 할당된 경로로 페이지 이동.
                    // create: "/expenses/create",
                    edit: "/expenses/edit/:id",
                    show: "/expenses/show/:id",
                    meta: {
                      label: "현장요원경비",
                      canDelete: true,
                      dataProviderName: "expenses",
                    },
                  },
                  {
                    name: "images", // name에 의해서 dataProvider resource가 결정됨.
                    list: "/images", // list에 할당된 경로로 페이지 이동.
                    // create: "/images/create",
                    // edit: "/boards/edit/:id",
                    show: "/images/show/:id",
                    meta: {
                      label: "이미지 파일",
                      dataProviderName: "images",
                    },
                  },
                  {
                    name: "Compare_Centers", // name에 의해서 dataProvider resource가 결정됨.
                    meta: {
                      label: "시설 비교 정보",
                    },
                  },
                  {
                    name: "center/compare?mode=1",
                    list: "/center/compare1",
                    // create: "/center/compare/create",
                    // edit: "/center/compare/edit/:id",
                    // show: "/center/compare/show/:id",
                    meta: {
                      parent: "Compare_Centers",
                      label: "함께 있으나 정보 다름",
                      canDelete: false,
                      dataProviderName: "compareCenter",
                    },
                  },
                  {
                    name: "center/compare?mode=2",
                    list: "center/compare2",
                    // create: "/center/compare/create",
                    // edit: "/center/compare/edit/:id",
                    // show: "/center/compare/show/:id",
                    meta: {
                      parent: "Compare_Centers",
                      label: "아이러빗에만",
                      canDelete: false,
                      dataProviderName: "compareCenter",
                    },
                  },
                  {
                    name: "center/compare?mode=3",
                    list: "/center/compare3",
                    // create: "/center/compare/create",
                    // edit: "/center/compare/edit/:id",
                    // show: "/center/compare/show/:id",
                    meta: {
                      parent: "Compare_Centers",
                      label: "공공DB에만",
                      canDelete: false,
                      dataProviderName: "compareCenter",
                    },
                  },
                  {
                    name: "login",
                    meta: {
                      label: "로그인",
                    },
                  },
                  {
                    name: "forgotPassword",
                    meta: {
                      label: "비밀번호 찾기",
                    },
                  }
                ]}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  useNewQueryKeys: true,
                  projectId: "P6tp0a-JhmVwc-psUTKK",
                }}
              >
                <Routes>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-inner"
                        fallback={<CatchAllNavigate to="/login" />}
                      >
                        <ThemedLayoutV2
                          Header={() => <Header sticky={true} />}
                          Sider={() => (
                            <ThemedSiderV2
                              Title={({ collapsed }) => (
                                <ThemedTitleV2
                                  // collapsed is a boolean value that indicates whether the <Sidebar> is collapsed or not
                                  collapsed={collapsed}
                                  text="아이러빗 관리자 서비스"
                                  icon={<IluvitIcon />}
                                />
                              )}
                              render={({ items, logout, collapsed }) => {
                                return (
                                  <>
                                    {items}
                                    <div style={{ marginTop: 30 }}>
                                    {logout}
                                    </div>
                                  </>
                                );
                              }}
                            />
                          )}
                          OffLayoutArea={() => <Footer />}
                        >
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                  >
                    <Route
                      index
                      element={<Statistics />}
                    />
                    <Route path="/statistics">
                      <Route index element={<Statistics />} />
                    </Route>
                    <Route path="/centers">
                      <Route index element={<CenterList />} />
                      <Route path="create" element={<CenterCreate />} />
                      <Route path="edit/:id" element={<CenterEdit />} />
                      <Route path="show/:id" element={<CenterShow />} />
                    </Route>
                    <Route path="/publicCenters">
                      <Route index element={<PublicCenterList />} />
                      <Route path="create" element={<PublicCenterCreate />} />
                      <Route path="edit/:id" element={<PublicCenterEdit />} />
                      <Route path="show/:id" element={<PublicCenterShow />} />
                    </Route>
                    <Route path="/center/compare1">
                      <Route index element={<ComparisonCenterList />} />
                      {/* <Route path="show/:id" element={<ComparisonCenterShow />} /> */}
                    </Route>
                    <Route path="/center/compare2">
                      <Route index element={<ComparisonCenterList />} />
                      {/* <Route path="show/:id" element={<ComparisonCenterShow />} /> */}
                    </Route>
                    <Route path="/center/compare3">
                      <Route index element={<ComparisonCenterList />} />
                      {/* <Route path="show/:id" element={<ComparisonCenterShow />} /> */}
                    </Route>
                    <Route path="/users">
                      <Route index element={<UserList />} />
                      <Route path="show/:id" element={<UserShow />} />
                    </Route>
                    <Route path="/parents">
                      <Route index element={<ParentList />} />
                      {/* <Route path="create" element={<ParentCreate />} /> */}
                      <Route path="edit/:id" element={<ParentEdit />} />
                      <Route path="show/:id" element={<ParentShow />} />
                    </Route>
                    <Route path="/teachers">
                      <Route index element={<TeacherList />} />
                      {/* <Route path="create" element={<TeacherCreate />} /> */}
                      <Route path="edit/:id" element={<TeacherEdit />} />
                      <Route path="show/:id" element={<TeacherShow />} />
                    </Route>
                    <Route path="/blackUsers">
                      <Route index element={<BlackUserList />} />
                      {/* <Route path="create" element={<BlackUserCreate />} /> */}
                      {/* <Route path="edit/:id" element={<BlackUserEdit />} /> */}
                      <Route path="show/:id" element={<BlackUserShow />} />
                    </Route>
                    <Route path="/blockUsers">
                      <Route index element={<BlockUserList />} />
                      {/* <Route path="create" element={<BlockUserCreate />} /> */}
                      {/* <Route path="edit/:id" element={<BlockUserEdit />} /> */}
                      <Route path="show/:id" element={<BlockUserShow />} />
                    </Route>
                    <Route path="/children">
                      <Route index element={<ChildList />} />
                      {/* <Route path="create" element={<ChildCreate />} /> */}
                      <Route path="edit/:id" element={<ChildEdit />} />
                      <Route path="show/:id" element={<ChildShow />} />
                    </Route>
                    <Route path="/fisUsers">
                      <Route index element={<FisUserList />} />
                      <Route path="create" element={<FisUserCreate />} />
                      <Route path="edit/:id" element={<FisUserEdit />} />
                      <Route path="show/:id" element={<FisUserShow />} />
                    </Route>
                    <Route path="/agents">
                      <Route index element={<AgentList />} />
                      <Route path="create" element={<AgentCreate />} />
                      <Route path="edit/:id" element={<AgentEdit />} />
                      <Route path="show/:id" element={<AgentShow />} />
                    </Route>
                    <Route path="/boards">
                      <Route index element={<BoardList />} />
                      {/* <Route path="create" element={<BoardCreate />} /> */}
                      {/* <Route path="edit/:id" element={<BoardEdit />} /> */}
                      <Route path="show/:id" element={<BoardShow />} />
                    </Route>
                    <Route path="/posts">
                      <Route index element={<PostList />} />
                      {/* <Route path="create" element={<PostCreate />} /> */}
                      {/* <Route path="edit/:id" element={<PostEdit />} /> */}
                      <Route path="show/:id" element={<PostShow />} />
                    </Route>
                    <Route path="/bookmarks">
                      <Route index element={<BookmarkList />} />
                      {/* <Route path="create" element={<BookmarkCreate />} /> */}
                      {/* <Route path="edit/:id" element={<BookmarkEdit />} /> */}
                      <Route path="show/:id" element={<BookmarkShow />} />
                    </Route>
                    <Route path="/prefers">
                      <Route index element={<PreferList />} />
                      {/* <Route path="create" element={<PreferCreate />} /> */}
                      {/* <Route path="edit/:id" element={<PreferEdit />} /> */}
                      <Route path="show/:id" element={<PreferShow />} />
                    </Route>
                    <Route path="/alarms">
                      <Route index element={<AlarmList />} />
                      <Route path="create" element={<AlarmCreate />} />
                      {/* <Route path="edit/:id" element={<AlarmEdit />} /> */}
                      <Route path="show/:id" element={<AlarmShow />} />
                    </Route>
                    <Route path="/expoTokens">
                      <Route index element={<ExpoTokenList />} />
                      {/* <Route path="create" element={<ExpoTokenCreate />} /> */}
                      {/* <Route path="edit/:id" element={<ExpoTokenEdit />} /> */}
                      <Route path="show/:id" element={<ExpoTokenShow />} />
                    </Route>
                    <Route path="/presentations">
                      <Route index element={<PresentationList />} />
                      {/* <Route path="create" element={<PresentationCreate />} /> */}
                      {/* <Route path="edit/:id" element={<PresentationEdit />} /> */}
                      <Route path="show/:id" element={<PresentationShow />} />
                    </Route>
                    <Route path="/ptDates">
                      <Route index element={<PtDateList />} />
                      {/* <Route path="create" element={<PtDateCreate />} /> */}
                      {/* <Route path="edit/:id" element={<PtDateEdit />} /> */}
                      <Route path="show/:id" element={<PtDateShow />} />
                    </Route>
                    <Route path="/participations">
                      <Route index element={<ParticipationList />} />
                      {/* <Route path="create" element={<ParticipationCreate />} /> */}
                      {/* <Route path="edit/:id" element={<ParticipationEdit />} /> */}
                      <Route path="show/:id" element={<ParticipationShow />} />
                    </Route>
                    <Route path="/schedules">
                      <Route index element={<ScheduleList />} />
                      {/* <Route path="create" element={<ScheduleCreate />} /> */}
                      <Route path="edit/:id" element={<ScheduleEdit />} />
                      <Route path="show/:id" element={<ScheduleShow />} />
                      <Route path="files/:id" element={<ScheduleFiles />} />
                    </Route>
                    <Route path="/calls">
                      <Route index element={<CallList />} />
                      {/* <Route path="create" element={<CallCreate />} /> */}
                      <Route path="edit/:id" element={<CallEdit />} />
                      <Route path="show/:id" element={<CallShow />} />
                    </Route>
                    <Route path="/expenses">
                      <Route index element={<ExpenseList />} />
                      {/* <Route path="create" element={<ExpenseCreate />} /> */}
                      <Route path="edit/:id" element={<ExpenseEdit />} />
                      <Route path="show/:id" element={<ExpenseShow />} />
                      <Route path="files/:id" element={<ExpenseFiles />} />
                    </Route>
                    <Route path="/images">
                      <Route index element={<ImageList />} />
                      {/* <Route path="create" element={<ImageCreate />} /> */}
                      {/* <Route path="edit/:id" element={<ImageEdit />} /> */}
                      <Route path="show/:id" element={<ImageShow />} />
                    </Route>
                    <Route path="/approval">
                      <Route index element={<ApprovalList/>} />
                    </Route>
                    <Route path="/reports">
                      <Route index element={<ReportManageList />} />
                    </Route>
                    <Route path="*" element={<ErrorComponent />} />
                  </Route>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-outer"
                        fallback={
                        <div><Outlet /><Footer /></div>
                        }
                      >
                        <NavigateToResource />
                      </Authenticated>
                    }
                  >
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                  </Route>
                </Routes>

                <RefineKbar />
                <UnsavedChangesNotifier />
                <DocumentTitleHandler handler={customTitleHandler} />
              </Refine>
            </RefineSnackbarProvider>
          </ColorModeContextProvider>
        </RefineKbarProvider>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
