import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridToolbar
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { IBoard } from "./interfaces";
import { MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const BoardList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<IBoard>({
        initialCurrent: 1,
        initialPageSize: 20,
        sorters: {
            initial: [{ field: "id", order: "desc" }]
        }
    });

    const columns = React.useMemo<GridColDef<IBoard>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 70 },
        { field: "name", headerName: "게시판이름", minWidth: 400, flex: 1,
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/boards/show/${params.row.id}`} 
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        { field: "boardKind", headerName: "유형", type: "string", minWidth: 30, flex:1 },
        { field: "centerId", headerName: "시설ID", type: "string", minWidth: 30, flex:1,
            renderCell: (params: GridRenderCellParams) =>
            <Link to={`/centers/show/${params.row.centerId}`}
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}  
            </Link>
        },
        { field: "updatedDate", headerName: "수정일", minWidth: 110, flex: 1 },
        { 
            field: "actions", 
            headerName: "Actions", 
            width: 70, 
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div>
                        <EditButton hideText recordItemId={params.row.id} />
                    </div>
                )
            },
        }
    ],[]
    );

    return (
        <List breadcrumb={<></>}
            key={Date.now()}
        >
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value))}
                }
                />

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-name"
                label="이름에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "name")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "name", "contains", e.target.value))}
                }
                />

            <TextField sx={{ width: '120px', marginRight: '30px', marginBottom: '20px'}}
                id="center-select-boardKind"
                label="유형"
                key={filters.find((filter: any) => filter.field === "boardKind")?.value ?? "boardKind"}  // unconditional select에서 defaultValue를 사용하면 발생하는 warning을 없애기 위함.
                defaultValue={filters.find((filter: any) => filter.field === "boardKind")?.value ?? ""}
                select
                variant="outlined"
                size="small"
                onChange={(e: any) => setFilters(updateFilters(filters, "boardKind", "eq", e.target.value))}
            >
                    <MenuItem value={""}>전체</MenuItem>
                    <MenuItem value={"NORMAL"}>이야기</MenuItem>
                    <MenuItem value={"ALBUM"}>포토앨범</MenuItem>
                    <MenuItem value={"AGENDA"}>알림장</MenuItem>
                    <MenuItem value={"MEAL"}>식단표</MenuItem>
            </TextField>

            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-centerId"
                label="센터ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "centerId")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "centerId", "contains", e.target.value))}
                }
                />

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
            />
        </List>
    );
};
