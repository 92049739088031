import { AuthBindings } from "@refinedev/core";
import axios from "axios";

export const authProvider: AuthBindings = {
  login: async ({ loginId, email, password }) => {
    const info = {
      loginId: loginId,
      password: password,
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/login`, info, { withCredentials: true });
      //   if (res.data.auth === 'ADMIN') {
      //     setIsAdmin(true);
      //     setIsLogin(true);
      // } else if (res.data.auth === 'USER') {
      //     setIsAdmin(false);
      //     setIsLogin(true);
      // } else if (res.data.auth === 'FIRED') {
      //     setIsAdmin(false);
      //     setIsLogin(false);
      //     alert("사용할 수 없는 계정입니다.");
      // }

      // const storedCheckedItems = JSON.parse(localStorage.getItem("checkedItems"));
      // localStorage.setItem("checkedItems", JSON.stringify(storedCheckedItems));
      sessionStorage.setItem("accessToken", res.data.accessToken);
      sessionStorage.setItem("nickname", info.loginId);
      sessionStorage.setItem("auth", res.data.auth);

      return {
        success: true,
        redirectTo: "/",
      };

    } catch (err) { console.log(err)
      // setIsAdmin(false);
      // setIsLogin(false);
      alert('로그인 실패: 아이디 또는 비밀번호를 확인해주세요.');

      return {
        success: false,
        error: {
          name: "LoginError",
          message: "Invalid username or password",
        },
      };

    }


  },
  logout: async () => {
    sessionStorage.removeItem("accessToken");
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = sessionStorage.getItem("accessToken");
    const loginId = sessionStorage.getItem("nickname");

    if (token) {
      return {
        name: loginId,
      };
    }
    return null;
  },
  register: async (info) => {
    return {
      success: false,
      error: {
        name: "Register Error",
        message: "임시로 사용을 금지합니다.",
      },
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_IP_ADDRESS}/signup`, info, { withCredentials: true });

      alert('회원가입 성공');

      return {
        success: true,
        redirectTo: "/",
      };

    } catch (err) { console.log(err)
      alert('회원가입 실패: 아이디 또는 비밀번호를 확인해주세요.');

      return {
        success: false,
        error: {
          name: "SignupError",
          message: "Invalid username or password",
        },
      };

    }

  },
  forgotPassword: async ({ email }) => {
    return {
      success: false,
      error: {
        name: "Forgot Password Error",
        message: "아직 구현되지 않았습니다.",
      },
    };
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
