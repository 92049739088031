import { HttpError, useShow } from "@refinedev/core";
import { Edit, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, MenuItem } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";

import { Controller } from "react-hook-form";

import { IChild, Nullable } from "./interfaces";
import React from "react";

export const ChildEdit: React.FC = () => {
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
        setValue,
        getValues
    } = useForm<IChild, HttpError, Nullable<IChild>>();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    id="name"
                    {...register("name", {
                        required: "This field is required",
                    })}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    margin="normal"
                    fullWidth
                    label="이름"
                    name="name"
                    autoFocus
                    required
                    InputLabelProps={{ shrink: true }}
                />

                <TextField
                    type="date"
                    id="birthDate"
                    {...register("birthDate", {
                        required: false,
                    })}
                    error={!!errors.birthDate}
                    helperText={errors.birthDate?.message}
                    margin="normal"
                    fullWidth
                    label="생일"
                    name="birthDate"
                    InputLabelProps={{ shrink: true }}
                />

                <TextField
                    id="parentId"
                    type="number"
                    {...register("parentId", {
                        required: "This field is required",
                    })}
                    error={!!errors.parentId}
                    helperText={errors.parentId?.message}
                    margin="normal"
                    fullWidth
                    label="부모 ID"
                    name="parentId"
                    autoFocus
                    required
                    InputLabelProps={{ shrink: true }}
                />

                <TextField sx={{ width: '50%' }}
                    id="status"
                    {...register("approval", {
                        required: "This field is required",
                        onChange: (e) => { setValue("approval", e.target.value, { shouldValidate: true }) }
                    })}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    label="상태"
                    name="approval"
                    select
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                    value={getValues("approval") || ""}
                >
                    <MenuItem value={"WAITING"}>승인대기중(WAITING)</MenuItem>
                    <MenuItem value={"ACCEPT"}>승인완료(ACCEPT)</MenuItem>
                    <MenuItem value={"REJECT"}>승인거절(REJECT)</MenuItem>
                </TextField>

            </Box>
        </Edit>
    );
};