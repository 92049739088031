import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export const ImageShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const categoryP = record?.category === "CENTER" ? "centers" : 
                    record?.category === "USER" ? "users" : 
                    record?.category === "CHILD" ? "childs" : 
                    record?.category === "BOARD" ? "boards" : 
                    record?.category === "POST" ? "posts" : 
                    record?.category === "PRESENTATION" ? "presentations" : 
                    "images";

    const extensionL = record?.extension ? record.extension.toLowerCase() : "";

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    파일 이름
                </Typography>
                <TextField value={record?.name + "." + record?.extension ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    위치
                </Typography>
                <TextField value={record?.prefix} />
                <Typography variant="body1" fontWeight="bold">
                    연관 Entity
                </Typography>
                <TextField value={record?.category + "-" + record?.targetId} />
                <Typography variant="body1" fontWeight="bold">
                    생성일
                </Typography>
                <TextField value={record?.createdDate} />
                <img src={ record?.prefix + categoryP + "/" + record?.targetId + "/" + record?.name + "." + extensionL } width={500} alt="image" />
            </Stack>
        </Show>
    );
};