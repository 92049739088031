import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

export const PostShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const isJson = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    제목
                </Typography>
                <TextField value={record?.title ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    내용
                </Typography>
                { record && isJson(record?.content) ?  
                <pre style={{ fontSize: '12px', maxWidth: '500px', wordBreak: 'break-word'}}>{JSON.stringify(JSON.parse(record?.content ?? ""), null, 2)}</pre>
                :
                <TextField value={record?.content} />
                }
                
                <Typography variant="body1" fontWeight="bold">
                    수정일
                </Typography>
                <TextField value={record?.postUpdateDate} />
                <Typography variant="body1" fontWeight="bold">
                    첨부이미지
                </Typography>
                <Link to={`/images?filters[0][field]=targetId&filters[0][operator]=eq&filters[0][value]=${record?.id}&filters[1][field]=category&filters[1][operator]=eq&filters[1][value]=POST`}>바로가기</Link>
            </Stack>
        </Show>
    );
};