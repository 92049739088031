import { HttpError, useShow } from "@refinedev/core";
import { Edit, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, MenuItem } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";

import { Controller } from "react-hook-form";

import { ISchedule, Nullable } from "./interfaces";
import React from "react";

export const ScheduleEdit: React.FC = () => {
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
        getValues,
        setValue,
    } = useForm<ISchedule, HttpError, Nullable<ISchedule>>();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                    <TextField sx={{ width: '30%' }}
                        id="complete"
                        {...register("complete", {
                            required: "This field is required",
                            onChange: (e) => { console.log(e.target.value); setValue("complete", e.target.value, { shouldValidate: true }) }
                        })}
                        error={!!errors.complete}
                        helperText={errors.complete?.message}
                        label="상태"
                        name="complete"
                        select
                        variant="outlined"
                        required
                        InputLabelProps={{ shrink: true }}
                        value={getValues("complete") || ""}
                    >
                        <MenuItem value={"incomplete"}>배정</MenuItem>
                        <MenuItem value={"confirm"}>시설 수락</MenuItem>
                        <MenuItem value={"cancel"}>취소</MenuItem>
                        <MenuItem value={"complete"}>완료</MenuItem>
                    </TextField>

                <TextField
                    type="date"
                    id="visitDateFinal"
                    {...register("visitDateFinal", {
                        required: false,
                    })}
                    error={!!errors.visitDateFinal}
                    helperText={errors.visitDateFinal?.message}
                    margin="normal"
                    fullWidth
                    label="실제방문날짜"
                    name="visitDateFinal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    type="time"
                    id="visitTimeFinal"
                    {...register("visitTimeFinal", {
                        required: false,
                    })}
                    error={!!errors.visitTimeFinal}
                    helperText={errors.visitTimeFinal?.message}
                    margin="normal"
                    fullWidth
                    label="실제방문시간"
                    name="visitTimeFinal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    id="newChildrenFinal"
                    {...register("newChildrenFinal", {
                        required: false,
                        onChange: (e) => { console.log("e.target.value", e.target.value);
                            const filteredValue = e.target.value.replace(/[^0-9]/g, '');
                            e.target.value = filteredValue;
                        }
                    })}
                    error={!!errors.newChildrenFinal}
                    helperText={errors.newChildrenFinal?.message}
                    margin="normal"
                    fullWidth
                    label="신규아동 최종"
                    name="newChildrenFinal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    id="modChildrenFinal"
                    {...register("modChildrenFinal", {
                        required: false,
                        onChange: (e) => { console.log("e.target.value", e.target.value);
                            const filteredValue = e.target.value.replace(/[^0-9]/g, '');
                            e.target.value = filteredValue;
                        }
                    })}
                    error={!!errors.modChildrenFinal}
                    helperText={errors.modChildrenFinal?.message}
                    margin="normal"
                    fullWidth
                    label="변경아동 최종"
                    name="modChildrenFinal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    id="dementiaFinal"
                    {...register("dementiaFinal", {
                        required: false,
                        onChange: (e) => { console.log("e.target.value", e.target.value);
                            const filteredValue = e.target.value.replace(/[^0-9]/g, '');
                            e.target.value = filteredValue;
                        }
                    })}
                    error={!!errors.dementiaFinal}
                    helperText={errors.dementiaFinal?.message}
                    margin="normal"
                    fullWidth
                    label="치매 최종"
                    name="dementiaFinal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    id="idisabledFinal"
                    {...register("idisabledFinal", {
                        required: false,
                        onChange: (e) => { console.log("e.target.value", e.target.value);
                            const filteredValue = e.target.value.replace(/[^0-9]/g, '');
                            e.target.value = filteredValue;
                        }
                    })}
                    error={!!errors.idisabledFinal}
                    helperText={errors.idisabledFinal?.message}
                    margin="normal"
                    fullWidth
                    label="지적장애 최종"
                    name="idisabledFinal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    id="absenceFinal"
                    {...register("absenceFinal", {
                        required: false,
                        onChange: (e) => { console.log("e.target.value", e.target.value);
                            const filteredValue = e.target.value.replace(/[^0-9]/g, '');
                            e.target.value = filteredValue;
                        }
                    })}
                    error={!!errors.absenceFinal}
                    helperText={errors.absenceFinal?.message}
                    margin="normal"
                    fullWidth
                    label="결석 최종"
                    name="absenceFinal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    type="text"
                    id="memo"
                    {...register("memo", {
                        required: false,
                    })}
                    error={!!errors.memo}
                    helperText={errors.memo?.message}
                    margin="normal"
                    fullWidth
                    label="관리자 메모"
                    name="memo"
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
        </Edit>
    );
};