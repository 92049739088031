import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export const PublicCenterShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    Id
                </Typography>
                <NumberField value={record?.id ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    이름
                </Typography>
                <TextField value={record?.name} />
                <Typography variant="body1" fontWeight="bold">
                    주소
                </Typography>
                <TextField value={record?.address} />
                <Typography variant="body1" fontWeight="bold">
                    종류
                </Typography>
                <TextField value={record?.kindOf} />
                <Typography variant="body1" fontWeight="bold">
                    상태
                </Typography>
                <TextField value={record?.status} />
                <Typography variant="body1" fontWeight="bold">
                    수정일
                </Typography>
                <DateField value={record?.updatedDate} />
            </Stack>
        </Show>
    );
};