import { axiosInstance } from "functions";

/*
		날짜: 2024/05/18 11:32 AM
		작성자: solitair
		http method: PATCH
		URI: /expenses
*/
export const expensePatchApi = async (id, data, options = {}) => {
    try {
        const response = await axiosInstance.patch(`${process.env.REACT_APP_IP_ADDRESS}/expenses/${id}`, data, options);
        console.log("expensePatchApi response: ", response.data);
        return response.data;
    } catch (error) {
        alert('Patch expense error: '+ error.response?.data?.message);
        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        }
        throw error;
    }
};

