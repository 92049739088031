import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridToolbar
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { IPtDate } from "./interfaces";
import { MenuItem, TextField } from "@mui/material";
import { updateFilters } from "functions/generateFilter";

export const PtDateList: React.FC = () => {

    const { dataGridProps, setFilters, filters } = useDataGrid<IPtDate>({
        initialCurrent: 1,
        initialPageSize: 20,
        sorters: {
            initial: [{ field: "id", order: "desc" }]
        }
    });

    const columns = React.useMemo<GridColDef<IPtDate>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 90 },
        { field: "presentationId", headerName: "해당 설명회 공지", minWidth: 100, flex: 1,
            renderCell: (params: GridRenderCellParams) =>
            <Link to={`/presentations/show/${params.row.presentationId}`}
                  style={{ textDecoration: 'underline', color: 'gray', fontWeight:'bold' }}>
                    {params.value}  
            </Link>
        },
        { field: "date", headerName: "날짜", type: "string", minWidth: 100, flex: 1 },
        { field: "time", headerName: "시간", type: "string", minWidth: 30, flex:1 },
        { field: "ablePersonNum", headerName: "허용인원", type: "number", minWidth:80, flex: 1},
        { field: "participantCnt", headerName: "신청인원", type: "number", minWidth: 80, flex: 1,},
        { field: "waitingCnt", headerName: "대기인원", type: "number", minWidth: 80, flex: 1,},
        { field: "version", headerName: "버전", type: "number", minWidth: 80, flex: 1,},
        { field: "updatedDate", headerName: "수정일", type: "string", minWidth: 120, flex: 1,},
    ],[]
    );

    return (
        <List breadcrumb={<></>}>
            <TextField sx={{ marginRight: '30px', marginBottom: '20px'}}
                id="alarm-search-id"
                label="ID에서 검색"
                defaultValue={filters.find((filter: any) => filter.field === "id")?.value ?? ""}
                type="search"
                variant="outlined"
                size="small"
                onKeyDown={(e: any) => {
                    if (e.key === "Enter") setFilters(updateFilters(filters, "id", "contains", e.target.value))}
                }
                />

            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                disableColumnFilter
                filterModel={undefined}
            />
        </List>
    );
};
