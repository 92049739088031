import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import { Typography, Stack, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { blockUserApi } from "functions/axios";

export const TeacherShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show 
            isLoading={isLoading}
            headerButtons={({ defaultButtons }) => (
                <>
                {defaultButtons}
                <Button 
                    variant="contained" 
                    color="secondary"
                    onClick={() => {
                        blockUserApi(record?.id, { status: "WITHDRAWN" });
                    }}
                >
                    탈퇴
                </Button>
                <Button 
                    variant="contained" 
                    color="warning"
                    onClick={() => {
                        blockUserApi(record?.id, { status: "RESTRICTED_SEVEN_DAYS" });
                    }}
                >
                    7일 정지
                </Button>
                <Button 
                    variant="contained" 
                    color="error"
                    onClick={() => {
                        blockUserApi(record?.id, { status: "BAN" });
                    }}
                >
                    영구정지
                </Button>
                </>
            )}
        >
            <Stack gap={1}>
            <Typography variant="body1" fontWeight="bold">
                    로그인 Id
                </Typography>
                <TextField value={record?.loginId ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    이름
                </Typography>
                <TextField value={record?.name} />
                <Typography variant="body1" fontWeight="bold">
                    별명(닉네임)
                </Typography>
                <TextField value={record?.nickName} />
                <Typography variant="body1" fontWeight="bold">
                    권한
                </Typography>
                <TextField value={record?.auth} />
                <Typography variant="body1" fontWeight="bold">
                    주소
                </Typography>
                <TextField value={record?.address+"  "+record?.detailAddress} />
                <Typography variant="body1" fontWeight="bold">
                    전화번호
                </Typography>
                <TextField value={record?.phoneNumber} />
                <Typography variant="body1" fontWeight="bold">
                    이메일주소
                </Typography>
                <TextField value={record?.emailAddress} />
                <Typography variant="body1" fontWeight="bold">
                    알림 확인 여부
                </Typography>
                <TextField value={record?.readAlarm === true ? "예" : "아니오"} />
                <Typography variant="body1" fontWeight="bold">
                    승인 상태
                </Typography>
                <TextField value={record?.approval === "ACCEPT" ? "완료" : record?.approval === "WAITING" ? "대기중" : "거절"} />
                <Typography variant="body1" fontWeight="bold">
                    첨부이미지
                </Typography>
                <Link to={`/images?filters[0][field]=targetId&filters[0][operator]=eq&filters[0][value]=${record?.id}&filters[1][field]=category&filters[1][operator]=eq&filters[1][value]=USER`}>바로가기</Link>
                <Typography variant="body1" fontWeight="bold">
                    생성일
                </Typography>
                <TextField value={record?.createdDate} />
                <Typography variant="body1" fontWeight="bold">
                    수정일
                </Typography>
                <TextField value={record?.updatedDate} />
            </Stack>
        </Show>
    );
};