import { useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    MarkdownField,
    DateField,
} from "@refinedev/mui";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

export const PresentationShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    장소
                </Typography>
                <TextField value={record?.place ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    내용
                </Typography>
                <TextField value={record?.content} />
                <Typography variant="body1" fontWeight="bold">
                    시작일
                </Typography>
                <TextField value={record?.startDate} />
                <Typography variant="body1" fontWeight="bold">
                    종료일
                </Typography>
                <TextField value={record?.endDate} />
                <Typography variant="body1" fontWeight="bold">
                     목록
                </Typography>
                <Link to={`/ptDates?filters[0][field]=presentationId&filters[0][operator]=eq&filters[0][value]=${record?.id}`}>바로가기</Link>
                <Typography variant="body1" fontWeight="bold">
                    수정일
                </Typography>
                <TextField value={record?.updatedDate} />
            </Stack>
        </Show>
    );
};