import { IResourceComponentsProps } from "@refinedev/core";

import { Option, useSelect } from "@refinedev/core";
import { List, useDataGrid, EditButton, ShowButton } from "@refinedev/mui";
import React from "react";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
} from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { IComparisonCenter } from "./interfaces";

export const ComparisonCenterList: React.FC = () => {

    const { dataGridProps } = useDataGrid<IComparisonCenter>({
        initialCurrent: 1,
        initialPageSize: 20,
        filters: {
            mode: "server", // client에서 필터링을 처리함.
        },
        // queryOptions: {
        //     select(data) {
        //         return { ...data, mode: modes }; // 동적으로 mode 값을 할당합니다.
        //     },
        // }
    });

    const columns = React.useMemo<GridColDef<IComparisonCenter>[]> (
        () => [
        { field: "id", headerName: "ID", type: "number", width: 80 },
        { field: "name", headerName: "이름", minWidth: 200, flex: 1, 
            renderCell: (params: GridRenderCellParams) => 
            <Link to={`/center/compare/show/${params.row.id}`} 
                  style={{ textDecoration: 'none', color: 'gray', fontWeight:'bold' }}>
                    {params.value}
            </Link>
        },
        { field: "kindOf", headerName: "종류", minWidth: 100, flex: 1, 
            renderCell: (params: GridRenderCellParams) => {
                let kindText = "";
                if (params.value === "Kindergarten") {
                    kindText = "유치원";
                } else if (params.value === "ChildHouse") {
                    kindText = "어린이집";
                } else {
                    kindText = "기타";
                }
                return <span>{kindText}</span>;
        }},
        { field: "address", headerName: "주소", minWidth: 400, flex: 1 },
        { field: "sido", headerName: "시도", minWidth: 100, flex: 1 },
        { field: "sigungu", headerName: "시군구", minWidth: 100, flex: 1 },
        { field: "estType", headerName: "유형", minWidth: 50, flex: 1 },
        { field: "status", headerName: "상태", minWidth: 50, flex: 1 },
        // { field: "updatedDate", headerName: "수정일", minWidth: 110, flex: 1, 
        //     renderCell: (params: GridRenderCellParams) => {
        //         const date = new Date(params.value);
        //         const hours = date.getHours();
        //         const minutes = date.getMinutes();
        //         const seconds = date.getSeconds();

        //         const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        //         const amPm = hours < 12 ? '오전' : '오후';
        //         const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        //         const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

        //         const formattedDate = `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일 ${amPm} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        //         return <span>{formattedDate}</span>;
        // }},
        { 
            field: "actions", 
            headerName: "Actions", 
            width: 100, 
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div>
                        <EditButton hideText recordItemId={params.row.id} />
                    </div>
                )
            },
        }
    ],[]
    );

    return (
        <List>
            <DataGrid
                columns={columns}
                {...dataGridProps}
                autoHeight
                pageSizeOptions={[10, 20, 30, 50, 100]}
            />
        </List>
    );
};
