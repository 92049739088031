import { AccessControlProvider } from "@refinedev/core";

const role = "editor";
// Uncomment this line and refresh to see difference.
// const role = "admin";

export const accessControlProvider: AccessControlProvider = {
  can: async ({ action, resource, params }) => {
//console.log("Access Control Provider", action, resource, params);
    const auth = sessionStorage.getItem("auth");
    const nickname = sessionStorage.getItem("nickname");

    if (auth === "ADMIN") {
      return {
        can: true,
        reason: "Authorized",
      };
    }

    if (auth === "MANAGER") {
      if (resource === "schedules" || resource === "expenses" || resource === "fis_users" || resource === "agents" || resource === "statistics" || resource === "approval" || resource === "calls") {
        return {
          can: true,
          reason: "Authorized",
        };
      }
    }

    if (nickname === "fis1234" || nickname === "김영수") {
      if (resource === "statistics" || resource === "approval" || resource === "users" || resource === "parents" || resource === "teachers" || resource === "blackusers" || resource === "blockusers"
        || resource === "notifications" || resource === "alarms"
      ) {
        return {
          can: true,
          reason: "Authorized",
        };
      }
    }

    return {
      can: false,
      reason: "Unauthorized",
    };
  },
};