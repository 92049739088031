import React from "react";
import styled from "styled-components";
import Lists from "./Lists";

const ListBox = (props) => {
    return (
         props.contents === "" && props.contents.data.length <= 0 ? "":
            <Change style={{
                marginTop: '5px',
                width: props.width,
                height: props.height,
                borderRadius: props.borderRadius,
                overflowX: "hidden",
                position:"relative"
            }}>
                {props.contents.data.map((content, index) => {
                    return (<Lists key={content.reportId} contents={content}
                                   gridRatio={props.gridRatio} height="40px"
                                   margin="10px"
                                   buttonContent={props.buttonContent} onClickFunction={props.onClickFunction}
                                   keyValue={index}/>);
                })}
            </Change>
    );
}

const Change = styled.div`
 @media screen and (max-width: 2000px) {
        width: 1000px;
    }
`;

export default ListBox;

